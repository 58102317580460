import { PATH_BUTTON_FILTER_DIVISION_UNSELECTED_ICON } from '../../../../constants/Common/path';

const toggleFilterSelection = (groups, groupValue, itemValue) => {
  return groups.map(group => {
    if (group.title.value === groupValue) {
      return {
        ...group,
        items: group.items.map(item => {
          if (item.value === itemValue) {
            return { ...item, isSelected: !item.isSelected };
          }
          return item;
        }),
      };
    }
    return group;
  });
};

const generateInitFilter = ({ setFilterState, divList }) => {
  const userID = localStorage?.getItem('userId');
  const defaultDiv = localStorage?.getItem('defaultDiv');

  const existFilter = localStorage?.getItem('statisticsFilter');
  const userFilter = existFilter ? JSON.parse(existFilter)?.[userID] : null;

  const initFilter = [
    {
      title: {
        icon: PATH_BUTTON_FILTER_DIVISION_UNSELECTED_ICON,
        label: 'Division',
        value: 'div',
      },
      items: divList?.map(div => ({
        label: div,
        value: div,
        isSelected:
          userFilter?.div?.[div] !== undefined
            ? userFilter?.div?.[div]
            : div === defaultDiv,
        style: {
          width: '42px',
        },
        onClick: () => {
          setFilterState(prev => toggleFilterSelection(prev, 'div', div));
        },
      })),
    },
  ];

  return initFilter;
};

export default generateInitFilter;
