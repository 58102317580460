import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  REGEX_NUMBER_ONLY_POSITIVE_DECIMAL_POINT_TWO,
  REGEX_NUMBER_REGEX,
} from '../../../../../constants/Common/regex';
import { COMMON_BANK_TYPE_SELECT_OPTION } from '../../../../../constants/Common/selectOption';
import { MASTER_COMPANY_W9_OPTION } from '../../../../../constants/Master/Company/masterCompanyOption';
import { MASTER_COMPANY_TYPE } from '../../../../../constants/Master/Company/masterCompanyStandardCodes';
import { splitPhoneNumber } from '../../../../../utils/Common/Handler/phoneNumberHandler';
import sliceNumber from '../../../../../utils/Master/Common/Generator/sliceNumber';
import {
  handleAutoCompleteChange,
  handleCheckChange,
  handleInputChange,
} from '../../../../../utils/Master/Common/Handler/changeInputHandler';
import CommonAutoComplete from '../../../../Inputs/CommonAutoComplete/CommonAutoComplete';
import CommonCheckbox from '../../../../Inputs/CommonCheckbox/CommonCheckbox';
import CommonFile from '../../../../Inputs/CommonFile/CommonFile';
import CommonMultipleInput from '../../../../Inputs/CommonMultipleInput/CommonMultipleInput';
import CommonPhoneNumber from '../../../../Inputs/CommonPhoneNumber';
import CommonSelect from '../../../../Inputs/CommonSelect/CommonSelect';
import CommonTextInput from '../../../../Inputs/CommonTextInput/CommonTextInput';
import CommonToggle from '../../../../Inputs/CommonToggle/CommonToggle';

const CompanyDetail = ({
  companyData,
  termsOption,
  companyType,
  editMode,
  isEditable,
  companyIDOption,
}) => {
  const nowDiv = useSelector(state => state.nowDiv);

  const { getValues } = useFormContext();

  const sliceSSN = sliceNumber(companyData?.ssn, [3, 2, 4]);

  const renderDivideLine = () => {
    return (
      <div className="mb-3">
        <hr className="border-[#878D9B]" />
      </div>
    );
  };

  return (
    <div>
      {renderDivideLine()}
      {/* Common Fields */}
      <div>
        <div className="flex justify-between">
          <div className="w-[173px]">
            <CommonAutoComplete
              inputName="billto"
              label="BILL TO"
              defaultValue={companyData?.billto}
              width="w-full"
              option={companyIDOption}
              onChangeHandler={handleAutoCompleteChange}
              disabled={!isEditable}
            />
          </div>
          <div className="w-[173px]">
            <CommonTextInput
              inputName="acct_name"
              label="ACCT NAME"
              defaultValue={companyData?.acct_name}
              onChangeHandler={handleInputChange}
              maxLength={30}
              disabled={!isEditable}
            />
          </div>
          <div className="w-[239px]">
            <CommonPhoneNumber
              inputName={['acct_cell_no_country', 'acct_cell_no']}
              label="ACCT CELL #"
              defaultValue={() =>
                splitPhoneNumber({ phone: companyData?.acct_cell_no })
              }
              disabled={!isEditable}
            />
          </div>
        </div>
        <div className="flex justify-between">
          <div className="w-[239px]">
            <CommonPhoneNumber
              inputName={['acct_office_no_country', 'acct_office_no']}
              label="ACCT OFFICE #"
              defaultValue={() =>
                splitPhoneNumber({ phone: companyData?.acct_office_no })
              }
              disabled={!isEditable}
            />
          </div>
          <div className="w-[107px]">
            <CommonTextInput
              inputName="acct_ext_no"
              label="ACCT EXT #"
              defaultValue={companyData?.acct_ext_no || ''}
              onChangeHandler={handleInputChange}
              regex={REGEX_NUMBER_REGEX}
              maxLength={5}
              disabled={!isEditable}
            />
          </div>
          <div className="w-[239px]">
            <CommonPhoneNumber
              inputName={['acct_fax_country', 'acct_fax']}
              label="ACCT FAX"
              defaultValue={() =>
                splitPhoneNumber({ phone: companyData?.acct_fax })
              }
              disabled={!isEditable}
            />
          </div>
        </div>
      </div>
      {/* Company Type Customer */}
      {MASTER_COMPANY_TYPE?.[companyType] === 'Customer' && (
        <div>
          <div className="flex gap-x-5">
            <div className="w-[302px]">
              <CommonTextInput
                inputName="acct_email"
                label="ACCT EMAIL"
                defaultValue={
                  companyData?.acct_email && companyData?.acct_email?.length > 0
                    ? companyData?.acct_email?.join(', ')
                    : ''
                }
                onChangeHandler={handleInputChange}
                required
                maxLength={128}
                disabled={!isEditable}
              />
            </div>
            <div>
              <CommonToggle
                inputName="credit_hold"
                label="CREDIT HOLD"
                onChangeHandler={handleCheckChange}
                options={[
                  {
                    label: 'CREDIT HOLD',
                    defaultValue: editMode ? companyData?.credit_hold : false,
                    inputName: 'credit_hold',
                  },
                ]}
                defaultValue={companyData?.credit_hold || ''}
                disabled={!isEditable}
              />
            </div>
          </div>
          <div className="flex justify-between">
            <div className="w-[238px]">
              <CommonSelect
                inputName="terms"
                label="TERMS"
                required
                option={termsOption}
                defaultValue={companyData?.terms}
                onChangeHandler={handleInputChange}
                disabled={!isEditable}
              />
            </div>
            <div className="w-[238px]">
              <CommonTextInput
                inputName="credit_limit"
                label="CREDIT LIMIT"
                defaultValue={companyData?.credit_limit}
                MaxLength={10}
                regex={REGEX_NUMBER_ONLY_POSITIVE_DECIMAL_POINT_TWO}
                onChangeHandler={handleInputChange}
                adornment={{
                  position: 'start',
                  content: '$',
                  styles: {
                    color: '#264B9F',
                  },
                }}
                disabled={!isEditable}
              />
            </div>
            <div className="w-[109px]">
              <CommonCheckbox
                inputName="factoring"
                label="FACTORING"
                borderVisible
                options={[
                  {
                    label: 'FACTORING',
                    defaultValue: editMode
                      ? companyData?.factoring
                      : !!(nowDiv === 'PHX' || nowDiv === 'LA'),
                    inputName: 'factoring',
                  },
                ]}
                onChangeHandler={handleCheckChange}
                disabled={!isEditable}
              />
            </div>
          </div>
        </div>
      )}
      {/* Common Field */}
      <div>
        <div className="flex">
          <div className="w-[248px]">
            <CommonFile
              label="CREDIT APPLICATION FIELD"
              defaultValue={
                editMode
                  ? [companyData?.credit_app_file?.split('/').pop(), null]
                  : ['', null]
              }
              fileUrl={companyData?.credit_app_file}
              inputName="credit_app_file"
              disabled={!isEditable}
            />
          </div>
        </div>
      </div>
      {/* Company Type Vendor */}
      {MASTER_COMPANY_TYPE?.[companyType] === 'Vendor' && (
        <div>
          <div className="items-center flex justify-start space-x-[10px]">
            <div className="w-[109px]">
              <CommonSelect
                label="W-9"
                inputName="w9"
                defaultValue={companyData?.w9}
                onChangeHandler={handleInputChange}
                option={MASTER_COMPANY_W9_OPTION}
                disabled={!isEditable}
              />
            </div>
            <div className="w-[182px]">
              <CommonFile
                inputName="w9_file"
                defaultValue={
                  editMode
                    ? [companyData?.w9_file?.split('/').pop(), null]
                    : ['', null]
                }
                fileUrl={companyData?.w9_file}
                disabled={!isEditable}
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="w-[141px]">
              <CommonCheckbox
                label="1099 VENDOR"
                onChangeHandler={handleCheckChange}
                borderVisible
                options={[
                  {
                    label: '1099 VENDOR',
                    defaultValue: editMode ? companyData?.vendor_1099 : false,
                    inputName: 'vendor_1099',
                  },
                ]}
                validate={{
                  custom: {
                    checkInputs: v => {
                      if (v) {
                        const ssn = [
                          getValues('ssn_1'),
                          getValues('ssn_2'),
                          getValues('ssn_3'),
                        ].join('');
                        const ein = getValues('ein_no');
                        if (!(ssn || ein)) {
                          return 'Enter SSN or EIN';
                        }
                      }
                      return true;
                    },
                  },
                }}
                disabled={!isEditable}
              />
            </div>
            <div className="w-[141px]">
              <CommonTextInput
                label="EIN #"
                inputName="ein_no"
                onChangeHandler={handleInputChange}
                regex={REGEX_NUMBER_REGEX}
                maxLength={9}
                defaultValue={companyData?.ein_no?.trim()}
                validate={{
                  default: {
                    minLength: {
                      value: 9,
                      message: 'Must be 9 characters.',
                    },
                  },
                }}
                disabled={!isEditable}
              />
            </div>
            <div className="w-[150px]">
              <CommonMultipleInput
                label="SSN"
                inputName={['ssn_1', 'ssn_2', 'ssn_3']}
                onChangeHandler={[
                  handleInputChange,
                  handleInputChange,
                  handleInputChange,
                ]}
                regex={REGEX_NUMBER_REGEX}
                maxLength={[3, 2, 4]}
                defaultValue={sliceSSN}
                disabled={[!isEditable, !isEditable, !isEditable]}
              />
            </div>
            <div className="w-[141px]">
              <CommonFile
                inputName="ssn_file"
                defaultValue={
                  editMode
                    ? [companyData?.ssn_file?.split('/').pop(), null]
                    : ['', null]
                }
                fileUrl={companyData?.ssn_file}
                disabled={!isEditable}
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="w-[141px]">
              <CommonTextInput
                label="BANK"
                inputName="bank"
                defaultValue={companyData?.bank}
                onChangeHandler={handleInputChange}
                maxLength={100}
                disabled={!isEditable}
              />
            </div>
            <div className="w-[141px]">
              <CommonSelect
                label="BANK TYPE"
                inputName="bank_type"
                defaultValue={companyData?.bank_type}
                onChangeHandler={handleInputChange}
                option={COMMON_BANK_TYPE_SELECT_OPTION}
                disabled={!isEditable}
              />
            </div>
            <div className="w-[141px]">
              <CommonTextInput
                label="ROUTING #"
                inputName="routing_no"
                defaultValue={companyData?.routing_no}
                onChangeHandler={handleInputChange}
                maxLength={20}
                disabled={!isEditable}
              />
            </div>
            <div className="w-[141px]">
              <CommonTextInput
                label="ACCOUNT #"
                inputName="acct_no"
                defaultValue={companyData?.acct_no}
                onChangeHandler={handleInputChange}
                maxLength={30}
                disabled={!isEditable}
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="w-[302px]">
              <CommonFile
                label="BANK"
                inputName="bank_file"
                defaultValue={
                  editMode
                    ? [companyData?.bank_file?.split('/').pop(), null]
                    : ['', null]
                }
                fileUrl={companyData?.bank_file}
                disabled={!isEditable}
              />
            </div>
            <div className="w-[302px]">
              <CommonFile
                defaultValue={
                  editMode
                    ? [companyData?.void_check?.split('/').pop(), null]
                    : ['', null]
                }
                fileUrl={companyData?.void_check}
                label="VOID CHECK"
                inputName="void_check"
                disabled={!isEditable}
              />
            </div>
          </div>
        </div>
      )}
      {renderDivideLine()}
      <div className="flex justify-center ">
        <div className="relative top-2.5">
          <CommonToggle
            borderVisible
            options={[
              {
                defaultValue: editMode ? companyData?.auto_tracking : true,
                inputName: 'auto_tracking',
                optionLabel: 'AUTOMATICALLY SEND TRACKING ID',
              },
            ]}
            onChangeHandler={handleCheckChange}
            disabled={!isEditable}
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyDetail;
