import React, { useEffect } from 'react';
import { Route, Routes, useParams, Outlet } from 'react-router-dom';
import BCDetailHeader from '../../components/BCDetail/BCDetailHeader';
import setBCDetailTab from '../../utils/Common/Handler/setBCDetailTitle';

const BCDetail = () => {
  useEffect(() => {
    setBCDetailTab();
  }, []);

  return (
    <div className="w-full max-w-[1920px] bg-[#f9f9f9]">
      <BCDetailHeader />
      <div className="pt-[42px]">
        <Outlet />
      </div>
    </div>
  );
};

export default BCDetail;
