import React, { useState } from 'react';
import { REGEX_NUMBER_ONLY_POSITIVE_DECIMAL_POINT_TWO } from '../../../../../constants/Common/regex';
import { COMMON_BANK_TYPE_SELECT_OPTION } from '../../../../../constants/Common/selectOption';
import { handleInputChange } from '../../../../../utils/Master/Common/Handler/changeInputHandler';
import CommonCheckbox from '../../../../Inputs/CommonCheckbox/CommonCheckbox';
import CommonFile from '../../../../Inputs/CommonFile/CommonFile';
import CommonSelect from '../../../../Inputs/CommonSelect/CommonSelect';
import CommonTextInput from '../../../../Inputs/CommonTextInput/CommonTextInput';

const DriverBank = ({
  isEditable,
  data,
  InputController,
  isCompany,
  ...props
}) => {
  const [isPerHourChecked, setIsPerHourChecked] = useState(data?.per_hour);

  return (
    <div>
      {isCompany && (
        <div className="flex gap-x-[20px]">
          <div className="w-[330px]">
            <CommonCheckbox
              label="PAY"
              borderVisible
              onChangeHandler={({ e, onChange }) => {
                if (e.target.name === 'per_hour') {
                  setIsPerHourChecked(e.target.checked);
                }
                onChange(e?.target?.checked);
              }}
              options={[
                {
                  inputName: 'per_hour',
                  defaultValue: data?.per_hour,
                  optionLabel: 'per hour',
                },
                {
                  inputName: 'per_load',
                  defaultValue: data?.per_load,
                  optionLabel: 'per load',
                },
              ]}
              disabled={!isEditable}
            />
          </div>
          <div className="w-[155px]">
            <CommonTextInput
              maxLength={15}
              label="RATE"
              defaultValue={data?.rate}
              required={isPerHourChecked}
              inputName="rate"
              adornment={{
                position: 'start',
                content: '$',
                styles: {
                  color: '#264B9F',
                },
              }}
              onChangeHandler={handleInputChange}
              regex={REGEX_NUMBER_ONLY_POSITIVE_DECIMAL_POINT_TWO}
              disabled={!isEditable}
            />
          </div>
        </div>
      )}
      <div className="flex justify-between">
        <div className="w-[155px]">
          <CommonTextInput
            maxLength={100}
            label="BANK"
            defaultValue={data?.bank}
            inputName="bank"
            onChangeHandler={handleInputChange}
            disabled={!isEditable}
          />
        </div>
        <div className="w-[155px]">
          <CommonSelect
            inputName="bank_type"
            defaultValue={data?.bank_type}
            label="BANK TYPE"
            option={COMMON_BANK_TYPE_SELECT_OPTION}
            onChangeHandler={handleInputChange}
            disabled={!isEditable}
          />
        </div>
        <div className="w-[155px]">
          <CommonTextInput
            maxLength={20}
            label="ROUTING #"
            defaultValue={data?.routing_no}
            inputName="routing_no"
            onChangeHandler={handleInputChange}
            disabled={!isEditable}
          />
        </div>
        <div className="w-[155px]">
          <CommonTextInput
            maxLength={30}
            label="ACCOUNT #"
            defaultValue={data?.acct_no}
            inputName="acct_no"
            onChangeHandler={handleInputChange}
            disabled={!isEditable}
          />
        </div>
      </div>
      <div className="flex justify-between">
        <div className="w-[330px]">
          <CommonFile
            label="BANK"
            inputName="bank_file"
            defaultValue={
              data?.bank_file
                ? [data?.bank_file?.split('/').pop(), null]
                : ['', null]
            }
            fileUrl={data?.bank_file}
            disabled={!isEditable}
          />
        </div>
        <div className="w-[330px]">
          <CommonFile
            label="VOID CHECK"
            inputName="void_check"
            defaultValue={
              data?.void_check
                ? [data?.void_check?.split('/').pop(), null]
                : ['', null]
            }
            fileUrl={data?.void_check}
            disabled={!isEditable}
          />
        </div>
      </div>
      {props.renderDivideLine()}
    </div>
  );
};

export default DriverBank;
