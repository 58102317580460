import React from 'react';
import {
  DOCUMENT_COLUMN_STYLES,
  DOCUMENT_HEADER_LIST,
} from '../../../../../constants/BCDetail/Document/documentTableElements';
import StyledDocumentHeader from '../../../../../styles/BCDetail/Document/StyledDocumentHeader';

const DocumentHeader = () => {
  return (
    <div className="flex h-[30px] items-center gap-x-[2px]">
      {DOCUMENT_HEADER_LIST?.map(header => (
        <StyledDocumentHeader
          key={header}
          {...DOCUMENT_COLUMN_STYLES?.[header]}
        >
          {header}
        </StyledDocumentHeader>
      ))}
    </div>
  );
};

export default DocumentHeader;
