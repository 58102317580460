import styled from 'styled-components';

const StyledDocumentHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  color: #222;
  font-size: 11px;
  font-weight: 500;

  width: ${({ width }) => `${width}px` || '100%'};
  height: 100%;

  @media (max-width: 960px) {
    ${({ shortWidth }) => shortWidth && `width: ${shortWidth}px;`}
    ${({ isHide }) => isHide && 'display: none;'}
  }
`;

export default StyledDocumentHeader;
