import styled from 'styled-components';

const StyledBCDetailCommonTopBarFieldItemValue = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f4f8;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  color: #6b7280;
  font-weight: 500;

  width: ${props => `${props?.$styles?.valueWidth || 168}px`};
`;

export default StyledBCDetailCommonTopBarFieldItemValue;
