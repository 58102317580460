import React from 'react';
import StyledCommonInputRequiredLabel from '../../../../../styles/Common/CommonInput/StyledCommonInputRequiredLabel';
import TableSelect from './TableSelect';
import TableText from './TableText';
import { REGEX_NUMBER_ONLY_POSITIVE_DECIMAL } from '../../../../../constants/Common/regex';
import CommonButton from '../../../../CommonButton/CommonButton';

const DispatchBookingTable = ({
  colHeaderColor,
  rowHeaderColor,
  width,
  height,
  ...props
}) => {
  const basicCellStyle = 'border-[0.5px] border-[#eeeeee] h-[30px]';
  const headerCellStyle = 'text-[12px] text-[#222222] font-[500] bg-[#f9f9f9]';
  const cellStyle = 'text-[12px] text-[#222222] font-[400] bg-white';
  return (
    <div
      className={`flex flex-col border-[#eeeeee]
      ${width || 'w-full'} ${height || 'h-fit'} `}
    >
      {props?.data?.map((col, index) => {
        return (
          <div className="flex flex-row" key={String(index + 1)}>
            {col?.map((row, idx) => {
              if (row?.component === 'select') {
                return (
                  <div className={`${row?.width || 'w-full'}`}>
                    <TableSelect
                      row={row}
                      basicCellStyle={basicCellStyle}
                      key={String(idx + 1)}
                    />
                  </div>
                );
              }
              if (row?.component === 'text') {
                return (
                  <div className={`${row?.width || 'w-full'}`}>
                    <TableText
                      row={row}
                      basicCellStyle={basicCellStyle}
                      key={String(idx + 1)}
                      regex={REGEX_NUMBER_ONLY_POSITIVE_DECIMAL}
                      onChangeHandler={({ e, onChange, regex }) => {
                        if (e?.target?.value?.match(regex)) {
                          onChange?.(e?.target?.value);
                          if (row?.handleBalance) {
                            row.handleBalance({ e, onChange });
                          }
                        }
                      }}
                    />
                  </div>
                );
              }
              if (row?.component === 'evidence') {
                return (
                  <div
                    className={`flex justify-center items-center 
                    ${row?.width || 'w-full'} ${basicCellStyle}`}
                    key={String(idx + 1)}
                  >
                    <CommonButton
                      category="pdf"
                      size="small"
                      onClick={() => {
                        row?.data ? row?.handleClick() : null;
                      }}
                      disabled={!row?.data}
                    >
                      PDF
                    </CommonButton>
                  </div>
                );
              }
              if (row?.component === 'upload') {
                return (
                  <div
                    className={`flex justify-center items-center 
                    ${row?.width || 'w-full'} ${basicCellStyle}`}
                    key={String(idx + 1)}
                  >
                    <input
                      type="file"
                      id={`evidence${index}`}
                      style={{ display: 'none' }}
                    />
                    <CommonButton
                      category="upload"
                      size="small"
                      preventDupClick
                      onClick={({ buttonRef }) => {
                        const fileSelector = document.getElementById(
                          `evidence${index}`,
                        );
                        fileSelector.addEventListener('change', e =>
                          row?.handleChange({ e, buttonRef }),
                        );
                        fileSelector.click();
                      }}
                    >
                      Upload
                    </CommonButton>
                  </div>
                );
              }
              return (
                <div
                  className={`${basicCellStyle} flex justify-center items-center
                  ${row?.width || 'w-full'} 
                  ${row?.header ? headerCellStyle : cellStyle}
                  ${row?.fontColor || 'text-[#222222]'} 
                  `}
                  key={String(idx + 1)}
                  onClick={() => row?.handleClick?.()}
                >
                  <div>
                    {!!row?.required && (
                      <StyledCommonInputRequiredLabel>
                        *
                      </StyledCommonInputRequiredLabel>
                    )}
                  </div>
                  {row?.data}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default DispatchBookingTable;
