import styled from 'styled-components';

const StyledDocumentContentWrapper = styled.div`
  width: 100%;
  min-height: 840px;
  height: 900px;
  display: flex;
  justify-content: space-between;
  column-gap: 35px;
`;

export default StyledDocumentContentWrapper;
