/**
 * @description
 * Generate maniuplated ap data
 * @param {list} apData
 * Target ap data
 * @returns
 * Manipulated ap data
 */
const generateAPData = ({ apData, productCodes }) => {
  const result = [];

  apData.forEach(ap => {
    if (ap?.productCode && ap?.amount !== null) {
      result.push({
        product_code: productCodes?.find(
          element => element?.product_code === ap.productCode,
        )?.seq,
        amt: parseFloat(ap.amount) || 0,
      });
    }
  });

  return result;
};

export default generateAPData;
