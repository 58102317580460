import {
  CELL_TYPE_SWITCH,
  CELL_TYPE_TEXT,
} from '../../../../constants/Common/DataGrid/cellType';
import cellEditorGenerator from '../../../Common/Datagrid/Generator/cellEditorGenerator';
import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';
import handleActiveSwitchClick from '../Handler/handleActiveSwitchClick';

const createSSLColumnDefs = ({
  patchActivateMutator,
  title,
  active,
  disabledRef,
}) => {
  return [
    {
      headerName: 'SSL',
      field: 'name',
      width: 250,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          maxLength: 36,
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          maxLength: 36,
        }),
    },
    {
      headerName: 'ABBR',
      field: 'ssl',
      width: 120,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          maxLength: 5,
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          maxLength: 5,
        }),
    },
    {
      headerName: '',
      field: 'active',
      width: 70,
      sortable: false,
      resizable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_SWITCH,
          onChangeHandler: ({ ...props }) => {
            handleActiveSwitchClick({
              title,
              gridParam,
              mutator: patchActivateMutator,
              active,
              disabledRef,
              ...props,
            });
          },
          value: gridParam?.node?.rowPinned ? true : gridParam?.data?.active,
        }),
      editable: false,
      cellStyle: {
        justifyContent: 'center',
      },
    },
  ];
};

export default createSSLColumnDefs;
