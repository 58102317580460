import { PREVIEW_STATUS } from '../../../../constants/BCDetail/Document';

const handleDocumentChange = ({
  documentList,
  selectedDocType,
  watchFileList,
  setFileURL,
  fileVersions,
}) => {
  /** Check selected Document Type */
  const isNotSelectedDocType = !selectedDocType;

  if (isNotSelectedDocType) {
    return PREVIEW_STATUS?.NO_SELECTED_DOC;
  }

  /** Check Uploaded Document */
  const uploadedDocURL = documentList?.find(
    doc => doc?.type === selectedDocType,
  )?.file_url;

  const watchSelectedDoc = watchFileList?.find(file =>
    Object?.keys?.(file)?.includes(selectedDocType),
  )?.[selectedDocType];

  const isNotUploadedSelectedDoc = !uploadedDocURL;
  const isRemoveWatchSelectedDoc = watchSelectedDoc === null;

  if (isNotUploadedSelectedDoc && !watchSelectedDoc) {
    return PREVIEW_STATUS?.NO_UPLOAD_DOC;
  }

  if (!isNotUploadedSelectedDoc && isRemoveWatchSelectedDoc) {
    return PREVIEW_STATUS?.NO_UPLOAD_DOC;
  }

  /** Set Upload Document URL */
  if (watchSelectedDoc !== null && watchSelectedDoc instanceof File) {
    const isNotPDFDocument = !watchSelectedDoc?.name
      ?.toLowerCase()
      ?.endsWith('.pdf');

    setFileURL(URL.createObjectURL(watchSelectedDoc));

    if (isNotPDFDocument) {
      return PREVIEW_STATUS?.NO_PDF_FILE;
    }
    return PREVIEW_STATUS?.AVAILABLE_PREVIEW;
  }

  const versionDocURL = `${uploadedDocURL}?v=${
    fileVersions?.[selectedDocType] || 0
  }`;

  setFileURL(versionDocURL);

  const isNotPDFDocument = !uploadedDocURL?.toLowerCase()?.endsWith('.pdf');

  if (isNotPDFDocument) {
    return PREVIEW_STATUS?.NO_PDF_FILE;
  }

  return PREVIEW_STATUS?.AVAILABLE_PREVIEW;
};

export default handleDocumentChange;
