export const DOCUMENT_HEADER_LIST = [
  'DATE',
  'DOCUMENT',
  'UPDATED BY',
  'VERIF',
  'CK',
  'BROWSE',
  'SAVE',
];

export const DOCUMENT_COLUMN_WIDTH = {
  DATE: 127,
  TIME: 80,
  DOCUMENT: 329,
  'UPDATED BY': 155,
  VERIF: 51,
  CK: 65,
  BROWSE: 202,
  SAVE: 108,
};

export const DOCUMENT_COLUMN_STYLES = {
  DATE: { width: 150, isHide: true, isCursor: true },
  TIME: { width: 80, isHide: true, isCursor: true },
  DOCUMENT: { width: 250, isHide: false, shortWidth: 135, isCursor: true },
  'UPDATED BY': { width: 120, isHide: true, isCursor: true },
  VERIF: { width: 51, isHide: false, shortWidth: 45, isCursor: true },
  CK: {
    width: 65,
    isHide: false,
    shortWidth: 45,
    isCursor: false,
    isShadow: true,
  },
  BROWSE: { width: 202, isHide: false, shortWidth: 127, isCursor: false },
  SAVE: {
    width: 108,
    isHide: false,
    shortWidth: 38,
    isCursor: false,
  },
};
