import {
  PATH_BUTTON_FILTER_CATEGORY_UNSELECTED_ICON,
  PATH_BUTTON_FILTER_DIVISION_UNSELECTED_ICON,
  PATH_BUTTON_FILTER_SCHEDULE_TYPE_UNSELECTED_ICON,
  PATH_BUTTON_FILTER_STATUS_UNSELECTED_ICON,
  PATH_BUTTON_FILTER_YARD_UNSELECTED_ICON,
} from '../../../../../constants/Common/path';

const toggleFilterSelection = (groups, groupValue, itemValue) => {
  return groups.map(group => {
    if (group.title.value === groupValue) {
      return {
        ...group,
        items: group.items.map(item => {
          if (item.value === itemValue) {
            return { ...item, isSelected: !item.isSelected };
          }
          return item;
        }),
      };
    }
    return group;
  });
};

const createFilter = ({ filters, setFilters, yardList, divList }) => {
  // Get userID from local storage
  const userID = localStorage?.getItem('userId');
  const defaultDiv = localStorage?.getItem('defaultDiv');
  // Get filter from local stoarge
  const existFilter = localStorage.getItem('returnYardFilter');
  // If there is a filter in local storage, return it
  const localStorageFilter = existFilter
    ? JSON.parse(existFilter)?.[userID]
    : null;

  return [
    {
      title: {
        icon: PATH_BUTTON_FILTER_SCHEDULE_TYPE_UNSELECTED_ICON,
        label: 'Schedule Type',
        value: 'sche-type',
      },
      items: [
        {
          label: 'LIVE',
          value: 'LIVE',
          isSelected:
            localStorageFilter?.['sche-type']?.LIVE !== undefined
              ? localStorageFilter?.['sche-type']?.LIVE
              : true,
          style: {
            width: '42px',
          },
          onClick: () => {
            setFilters(prev =>
              toggleFilterSelection(prev, 'sche-type', 'LIVE'),
            );
          },
        },
        {
          label: 'DROP',
          value: 'DROP',
          isSelected:
            localStorageFilter?.['sche-type']?.DROP !== undefined
              ? localStorageFilter?.['sche-type']?.DROP
              : true,
          style: {
            width: '42px',
          },
          onClick: () => {
            setFilters(prev =>
              toggleFilterSelection(prev, 'sche-type', 'DROP'),
            );
          },
        },
      ],
    },
    {
      title: {
        icon: PATH_BUTTON_FILTER_DIVISION_UNSELECTED_ICON,
        label: 'Division',
        value: 'div',
      },
      items: divList?.map(div => ({
        label: div,
        value: div,
        isSelected:
          localStorageFilter?.div?.[div] !== undefined
            ? localStorageFilter?.div?.[div]
            : div === defaultDiv,
        style: {
          width: '42px',
        },
        onClick: () => {
          setFilters(prev => toggleFilterSelection(prev, 'div', div));
        },
      })),
    },
    {
      title: {
        icon: PATH_BUTTON_FILTER_CATEGORY_UNSELECTED_ICON,
        label: 'Category',
        value: 'category',
      },
      items: [
        {
          label: 'IMPORT',
          value: 'WC0001',
          isSelected:
            localStorageFilter?.category?.WC0001 !== undefined
              ? localStorageFilter?.category?.WC0001
              : true,
          onClick: () => {
            setFilters(prev =>
              toggleFilterSelection(prev, 'category', 'WC0001'),
            );
          },
        },
        {
          label: 'EXPORT',
          value: 'WC0003',
          isSelected:
            localStorageFilter?.category?.WC0003 !== undefined
              ? localStorageFilter?.category?.WC0003
              : true,
          onClick: () => {
            setFilters(prev =>
              toggleFilterSelection(prev, 'category', 'WC0003'),
            );
          },
        },
      ],
    },
    {
      title: {
        icon: PATH_BUTTON_FILTER_STATUS_UNSELECTED_ICON,
        label: 'Status',
        value: 'status-color',
      },
      items: [
        {
          label: 'IN YARD',
          value: 'White',
          isSelected:
            localStorageFilter?.['status-color']?.White !== undefined
              ? localStorageFilter?.['status-color']?.White
              : true,
          style: {
            width: '64px',
          },
          onClick: () => {
            setFilters(prev =>
              toggleFilterSelection(prev, 'status-color', 'White'),
            );
          },
        },
        {
          label: 'TO YARD',
          value: 'Yellow',
          isSelected:
            localStorageFilter?.['status-color']?.Yellow !== undefined
              ? localStorageFilter?.['status-color']?.Yellow
              : true,
          style: {
            width: '64px',
            backgroundColor: '#FFFDE9',
            hoverBackgroundColor: '#F0E787',
            selectedBackgroundColor: '#C6C18F',
          },
          onClick: () => {
            setFilters(prev =>
              toggleFilterSelection(prev, 'status-color', 'Yellow'),
            );
          },
        },
        {
          label: 'IN RTN',
          value: 'Blue',
          isSelected:
            localStorageFilter?.['status-color']?.Blue !== undefined
              ? localStorageFilter?.['status-color']?.Blue
              : true,
          style: {
            width: '64px',
            backgroundColor: '#E7EEFF',
            hoverBackgroundColor: '#CDDAF9',
            selectedBackgroundColor: '#98A6C7',
          },
          onClick: () => {
            setFilters(prev =>
              toggleFilterSelection(prev, 'status-color', 'Blue'),
            );
          },
        },
        {
          label: 'TO RTN',
          value: 'Green',
          isSelected:
            localStorageFilter?.['status-color']?.Green !== undefined
              ? localStorageFilter?.['status-color']?.Green
              : true,
          style: {
            width: '64px',
            backgroundColor: '#F2FFFA',
            hoverBackgroundColor: '#D3FAEB',
            selectedBackgroundColor: '#8BA79C',
          },
          onClick: () => {
            setFilters(prev =>
              toggleFilterSelection(prev, 'status-color', 'Green'),
            );
          },
        },
      ],
    },
    {
      title: {
        icon: PATH_BUTTON_FILTER_YARD_UNSELECTED_ICON,
        label: 'Yard',
        value: 'ngl-yard',
      },
      items: yardList?.map(yard => ({
        label: yard,
        value: yard,
        isSelected:
          localStorageFilter?.['ngl-yard']?.[yard] !== undefined
            ? localStorageFilter?.['ngl-yard']?.[yard]
            : yard?.includes(`NGL${defaultDiv}`),
        style: {
          width: '80px',
        },
        onClick: () => {
          setFilters(prev => toggleFilterSelection(prev, 'ngl-yard', yard));
        },
      })),
    },
  ];
};

export default createFilter;
