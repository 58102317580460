const saveFilter = ({ storageName, filters, setOpen }) => {
  // Get user ID
  const userID = localStorage.getItem('userId');

  const updatedFilter = localStorage?.getItem(storageName)?.[userID] || {};

  filters?.forEach(group => {
    const key = group?.title?.value;
    if (!updatedFilter[key]) {
      updatedFilter[key] = {};
    }
    group.items.forEach(item => {
      updatedFilter[key][item.value] = item.isSelected;
    });
  });

  const existingFilter = JSON.parse(localStorage.getItem(storageName)) || {};

  // Filter out the keys that are not needed
  const UNNECESSARY_LEGACY_FILTER_KEYS = [
    'sche-type',
    'div',
    'category',
    'status-color',
    'ngl-yard',
    'date',
  ];
  const filteredData = Object.fromEntries(
    Object.entries(existingFilter).filter(
      ([key, value]) => ![...UNNECESSARY_LEGACY_FILTER_KEYS].includes(key),
    ),
  );

  // Set the filter to local storage
  localStorage.setItem(
    storageName,
    JSON.stringify({
      ...filteredData,
      [userID]: {
        ...filteredData[userID],
        ...updatedFilter,
      },
    }),
  );

  setOpen?.(prev => false);
};

export default saveFilter;
