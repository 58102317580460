import React, { useState, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import useGetPerdiemReconcileList from '../../../hooks/Acct/PerdiemReconcile/Get/useGetPerdiemReconcileList';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import HeaderButtonArea from '../../../components/Acct/Common/HeaderButtonArea';
import AdvancedSearchModal from '../../../components/Acct/PerdiemReconcile/AdvancedSearchModal';
import { generateSSLOption } from '../../../utils/Acct/PerdiemReconcile/Generator/optionListGenerator';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import DataGridArea from '../../../components/Acct/PerdiemReconcile/DataGridArea';
import {
  ACCT_PAGE,
  EDITABLE,
} from '../../../constants/Acct/Common/staticNames';
import UploadInvoiceModal from '../../../components/Acct/Common/UploadInvoiceModal';
import usePostScanPerdiem from '../../../hooks/Acct/PerdiemReconcile/Post/usePostScanPerdiem';
import usePostPerdiemInvoice from '../../../hooks/Acct/PerdiemReconcile/Post/usePostPerdiemInvoice';
import usePatchWarningModalPerdiem from '../../../hooks/Acct/PerdiemReconcile/Patch/usePatchWarningModalPerdiem';
import useControlLoading from '../../../hooks/Acct/Common/Control/useControlLoading';
import { STATUS_CHECK_BOX_OPTION } from '../../../constants/Acct/Common/selectOptions';

const PerdiemReconcile = () => {
  dayjs.extend(isoWeek);

  const gridRef = useRef(null);

  const duplicateCheckFields = ['inv_no', 'cont_no'];

  /* Import Chassis Reconcile Data */
  const initCheckBoxValue = STATUS_CHECK_BOX_OPTION?.reduce((acc, curr) => {
    acc[curr.inputName] = curr.defaultValue;
    return acc;
  }, {});

  const [queryObject, setQueryObject] = useState({
    from: dayjs()
      ?.subtract(1, 'week')
      ?.startOf('isoWeek')
      ?.format('YYYY-MM-DD'),
    to: dayjs()?.subtract(1, 'week')?.endOf('isoWeek')?.format('YYYY-MM-DD'),
    ...initCheckBoxValue,
  });

  const [productStatus, setProductStatus] = useState(EDITABLE);

  const [openModal, setOpenModal] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);

  /** Error Modal */
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const setErrorModalOptions = {
    setErrorModalOpen,
    setErrorMessage,
    setIsSuccess,
  };

  const productController = useForm();

  const sslList = generateSSLOption(useSelector(state => state?.common?.ssl));

  const {
    perdiemReconcileList,
    isLoading,
    isFetching,
    refetchPerdiemReconcileList,
  } = useGetPerdiemReconcileList({ queryObject });

  const { isLoadingOpen } = useControlLoading({ isLoadingList: [isLoading] });

  return (
    <div className="pr-[40px] flex flex-col h-full">
      <FormProvider {...productController}>
        <form>
          <HeaderButtonArea
            page={ACCT_PAGE?.perDiemReconcile}
            gridRef={gridRef}
            setOpenModal={setOpenModal}
            productStatus={productStatus}
            setProductStatus={setProductStatus}
            setOpenUploadModal={setOpenUploadModal}
            setQueryObject={setQueryObject}
          />
        </form>
        <div className="w-full flex-1 flex flex-col">
          <DataGridArea
            gridRef={gridRef}
            queryObject={queryObject}
            productStatus={productStatus}
            perdiemReconcileList={perdiemReconcileList}
            refetchPerdiemReconcileList={refetchPerdiemReconcileList}
            setErrorModalOptions={setErrorModalOptions}
          />
        </div>
      </FormProvider>
      <AdvancedSearchModal
        sslList={sslList}
        openModal={openModal}
        queryObject={queryObject}
        setQueryObject={setQueryObject}
        setOpenModal={setOpenModal}
        setErrorModalOptions={setErrorModalOptions}
      />
      {openUploadModal && (
        <UploadInvoiceModal
          open={openUploadModal}
          type="perDiem"
          setOpen={setOpenUploadModal}
          sslList={sslList}
          duplicateCheckFields={duplicateCheckFields}
          refetchList={refetchPerdiemReconcileList}
          setErrorModalOptions={setErrorModalOptions}
          usePostScan={usePostScanPerdiem}
          usePatchWarningModal={usePatchWarningModalPerdiem}
          usePostInvoice={usePostPerdiemInvoice}
        />
      )}
      <CommonLoading open={isLoadingOpen} />
      {errorModalOpen && !isSuccess && (
        <CommonErrorModal
          open={errorModalOpen}
          message={errorMessage}
          setIsOpen={setErrorModalOpen}
          isSuccess={isSuccess}
        />
      )}
    </div>
  );
};

export default PerdiemReconcile;
