export const generateSpecificFilter = ({ filterState, specificKey }) => {
  const specificItemList =
    filterState?.find(filter => filter?.title?.value === specificKey)?.items ||
    [];

  const newSpecificFilter = specificItemList.reduce((acc, item) => {
    acc[item.value] = item?.isSelected;
    return acc;
  }, {});

  return newSpecificFilter;
};

export const generateAllFilter = ({ filterState }) => {
  const allFilter = filterState?.reduce((acc, filter) => {
    acc[filter.title.value] = filter?.items?.reduce((itemAcc, item) => {
      itemAcc[item.value] = item?.isSelected;
      return itemAcc;
    }, {});
    return acc;
  }, {});

  return allFilter;
};
