import React from 'react';
import dayjs from 'dayjs';
import CommonButton from '../../../CommonButton/CommonButton';
import SearchDate from '../SearchDate';
import CommonFilter from '../../../Common/CommonFilter';
import saveFilter from '../../../../utils/Common/CommonFilter/saveFilter';

const HeaderButtons = ({
  setDateQuery,
  isReturn,
  isOpenFilter,
  setIsOpenFilter,
  filterState,
  storageName,
  dateQuery,
}) => {
  const prevArrow = '../../../../assets/images/common_icon/prevArrow.svg';
  const nextArrow = '../../../../assets/images/common_icon/nextArrow.svg';

  return (
    <div className="grow-0 flex flex-col">
      <div className="flex flex-row justify-end items-center my-[20px]">
        <div className="text-[14px] font-[500] text-[#666666]">Date</div>
        <div className="w-[117px] mr-[13px] ml-[9px]">
          <SearchDate date={dateQuery} setDate={setDateQuery} />
        </div>

        <div className="flex h-[14px] border-l-[0.4px] border-[#00000099] self-center" />
        <CommonFilter
          open={isOpenFilter}
          setOpen={setIsOpenFilter}
          filters={filterState}
          onSave={() =>
            saveFilter({
              storageName,
              filters: filterState,
              setOpen: setIsOpenFilter,
            })
          }
        />

        {isReturn && (
          <CommonButton
            category="blueYard"
            onClick={() => {
              window.location.href = `/daily-report/return-yard`;
            }}
            color="#556DA1"
          >
            RETURN YARD
          </CommonButton>
        )}
      </div>
      <div className="flex flex-row justify-between h-[30px] relative pb-[5px]">
        <div
          className="flex items-center cursor-pointer text-[13px] font-[500] text-[#222222]"
          onClick={() => {
            const newDate = dayjs(dateQuery)
              .subtract(7, 'day')
              .format('YYYY-MM-DD');
            setDateQuery(newDate);
          }}
        >
          <img src={prevArrow} alt="prevArrow" />
          <div className="pt-[2px]">Prev Week</div>
        </div>
        <div
          className="flex items-center cursor-pointer text-[13px] font-[500] text-[#222222]"
          onClick={() => {
            const newDate = dayjs(dateQuery).add(7, 'day').format('YYYY-MM-DD');
            setDateQuery(newDate);
          }}
        >
          <div className="pt-[2px]">Next Week</div>
          <img src={nextArrow} alt="nextArrow" />
        </div>
      </div>
    </div>
  );
};

export default HeaderButtons;
