const handleServiceCodeAdd = (params, division, addServiceCodeMutator) => {
  params?.setAddDisabled(true);

  const addData = { ...params?.gridParam?.data, div: division };
  addServiceCodeMutator.mutate({
    division,
    data: addData,
    setAddDisabled: params?.setAddDisabled,
  });
};

export default handleServiceCodeAdd;
