const clearLocalStorage = () => {
  /** Please DO NOT remove filter */
  localStorage.removeItem('userId');
  localStorage.removeItem('csrfToken');
  localStorage.removeItem('permission');
  localStorage.removeItem('allDiv');
  localStorage.removeItem('defaultDiv');
  localStorage.removeItem('firstPage');
  localStorage.removeItem('bookingOption');
};

export default clearLocalStorage;
