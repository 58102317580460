import updateButtonDisabled from '../../../Common/Handler/updateButtonDisabled';

const handleRowSave = async ({
  handleSubmit,
  id,
  type,
  woNo,
  documentList,
  documentMutator,
  buttonRef,
  ...props
}) => {
  await handleSubmit(submitData => {
    const woCategory = location?.pathname?.split('/')?.[3];

    if (buttonRef?.current)
      updateButtonDisabled({ ref: buttonRef, disabled: true });

    const docData = {};

    const newList = [...documentList];

    docData[type] = submitData?.file?.[id]?.[type];

    const tempSubmitData = {
      ...submitData[id],
      accuracy: docData[type] ? submitData[id]?.accuracy : null,
    };

    newList[id - 1] = tempSubmitData;

    if (type === 'DO' && woCategory !== 'export') {
      if (tempSubmitData?.file_url === '' && !docData?.DO) {
        props?.setMessageModalOpen(true);
        props?.setMessageContent('Please upload DO.');
        updateButtonDisabled({ ref: buttonRef, disabled: false });
        return;
      }
    }

    docData.doc = JSON.stringify(newList);

    documentMutator({
      woNo,
      docData,
      buttonRef,
      changedDocList: [type],
    });
  })?.();
};

export default handleRowSave;
