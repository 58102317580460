import React, { useEffect } from 'react';
import StyledFilterItemWrapper from '../../../../../../styles/Common/CommonFilter/FilterModal/FilterContent/FilterItem/StyledFilterItemWrapper';

const FilterItem = ({ ...props }) => {
  const { label, style, onClick, isSelected } = props;

  return (
    <StyledFilterItemWrapper
      $style={style}
      onClick={onClick}
      isSelected={isSelected}
    >
      {label || '-'}
    </StyledFilterItemWrapper>
  );
};

export default FilterItem;
