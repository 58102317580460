import React from 'react';
import { FormProvider } from 'react-hook-form';
import DocumentHeader from './DocumentHeader';
import DocumentAddRow from './DocumentAddRow';
import DocumentRow from './DocumentRow';
import { PRESET_DOC } from '../../../../constants/BCDetail/Document/presetDocElement';
import useControlWindowSize from '../../../../hooks/BCDetail/Document/useControlWindowSize';

const DocumentTable = ({ documentController, ...props }) => {
  const {
    documentList,
    setDelType,
    selectedDocType,
    setSelectedDocType,
    loadingValidDocList,
  } = props;

  const { width: windowWidth } = useControlWindowSize();

  return (
    <FormProvider {...documentController}>
      <div className="flex flex-col gap-y-[2px]">
        <DocumentHeader />
        <DocumentAddRow id={0} {...props} windowWidth={windowWidth} />
        {documentList &&
          documentList.map((row, index) => (
            <DocumentRow
              key={row.type}
              row={row}
              id={index + 1}
              preset={PRESET_DOC.includes(row.type)}
              setDelType={setDelType}
              selectedDocType={selectedDocType}
              setSelectedDocType={setSelectedDocType}
              windowWidth={windowWidth}
              {...props}
            />
          ))}
      </div>
    </FormProvider>
  );
};

export default DocumentTable;
