import {
  DRIVER_POSITION,
  DRIVER_POSITION_OBJECT,
} from '../../../constants/Master/APRate/elements';
import {
  TIERWIEHT_STATUS,
  TIERWIEHT_STATUS_OBJECT,
} from '../../../constants/Master/TierWeight/elements';
import convertStandardCodeToText from '../../Common/convertStandardCodeToText';

const setResultData = (setList, setIsLoading, resultData, companyList) => {
  resultData?.forEach((data, index) => {
    resultData[index].category =
      convertStandardCodeToText(
        data.category,
        DRIVER_POSITION,
        DRIVER_POSITION_OBJECT,
      ) || data.category;
    resultData[index].status =
      convertStandardCodeToText(
        data.status,
        TIERWIEHT_STATUS,
        TIERWIEHT_STATUS_OBJECT,
      ) || data.status;

    const fromLocation = companyList?.data.find(
      ({ company }) => company === data.from_location,
    )?.business_name;
    resultData[index].from_location = fromLocation
      ? `${resultData[index].from_location}/${fromLocation}`
      : data.from_location;

    const toLocation = companyList?.data.find(
      ({ company }) => company === data.to_location,
    )?.business_name;
    resultData[index].to_location = toLocation
      ? `${resultData[index].to_location}/${toLocation}`
      : data.to_location;

    const poLocation = companyList?.data.find(
      ({ company }) => company === data.po_location,
    )?.business_name;
    resultData[index].po_location = poLocation
      ? `${resultData[index].po_location}/${poLocation}`
      : data.po_location;

    const dilvLocation = companyList?.data.find(
      ({ company }) => company === data.dilv_location,
    )?.business_name;
    resultData[index].dilv_location = dilvLocation
      ? `${resultData[index].dilv_location}/${dilvLocation}`
      : data.dilv_location;

    const billTo = companyList?.data.find(
      ({ company }) => company === data.billto,
    )?.business_name;
    resultData[index].billto = billTo
      ? `${resultData[index].billto}/${billTo}`
      : data.billto;
  });
  setList(resultData);
  setTimeout(() => {
    setIsLoading(false);
  }, 100);
};

export default setResultData;
