import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import DocumentTable from '../../../components/BCDetail/Document/DocumentTable';
import DocDeleteConfirmModal from '../../../components/BCDetail/Document/DocDeleteConfirmModal';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import useGetDocumentList from '../../../hooks/BCDetail/Document/useGetDocumentList';
import usePatchDocumentList from '../../../hooks/BCDetail/Document/usePatchDocumentList';
import useGetBasicInfo from '../../../hooks/BCDetail/DetailInfo/BasicInfo/useGetBasicInfo';
import TopUtilityBar from '../../../components/BCDetail/Document/TopUtilityBar';
import CommonButton from '../../../components/CommonButton/CommonButton';
import handleAllSave from '../../../utils/BCDetail/Document/Handler/handleAllSave';
import Preview from '../../../components/BCDetail/Document/Preview';
import StyledDocumentPageWrapper from '../../../styles/BCDetail/Document/StyledDocumentPageWrapper';
import StyledDocumentAlignmentContentWrapper from '../../../styles/BCDetail/Document/StyledDocumentAlignmentContentWrapper';
import StyledDocumentContentWrapper from '../../../styles/BCDetail/Document/StyledDocumentContentWrapper';
import StyledDocumentTableWrapper from '../../../styles/BCDetail/Document/StyledDocumentTableWrapper';

const Document = ({ isEditable }) => {
  const buttonRef = useRef(null);

  const [fileVersions, setFileVersions] = useState({});

  const woNo = document.URL?.split('/')?.pop();

  const [delType, setDelType] = useState(false);
  const [onOkClickHandler, setOnOkClickHandler] = useState(null);

  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [messageContents, setMessageContent] = useState('');

  const [selectedDocType, setSelectedDocType] = useState(null);

  const [loadingValidDocList, setLoadingValidDocList] = useState([]);

  const { basicInfoData } = useGetBasicInfo();

  const documentController = useForm();

  const modalProps = {
    setDelType,
    setOnOkClickHandler,
    setMessageModalOpen,
    setMessageContent,
  };

  const { documentList, docListRefetch, isLoading, isError } =
    useGetDocumentList({
      woNo,
      ...modalProps,
    });

  const { mutate: documentMutator, isPending: isLoadingPatchDocument } =
    usePatchDocumentList({
      documentController,
      delType,
      docListRefetch,
      fileVersions,
      setFileVersions,
      setLoadingValidDocList,
      ...modalProps,
    });

  const submitProps = {
    woNo,
    documentList,
    documentMutator,
  };

  useEffect(() => {
    if (loadingValidDocList && loadingValidDocList?.length > 0) {
      const isExistAccuracyList = documentList
        ?.filter(doc => doc?.accuracy !== undefined && doc?.accuracy !== null)
        ?.map(doc => doc?.type);

      const newLoadingDocList = loadingValidDocList?.filter(
        doc => !isExistAccuracyList?.includes(doc),
      );
      setLoadingValidDocList(newLoadingDocList);
    }
  }, [documentList]);

  return (
    <>
      {isLoading && <CommonLoading open={isLoading} />}
      <StyledDocumentPageWrapper>
        <StyledDocumentAlignmentContentWrapper>
          <TopUtilityBar basicInfoData={basicInfoData} />
          <StyledDocumentContentWrapper>
            <StyledDocumentTableWrapper>
              {isEditable && (
                <div className="w-full flex justify-end">
                  <CommonButton
                    ref={buttonRef}
                    type="button"
                    category="save"
                    disabled={isLoadingPatchDocument}
                    onClick={() =>
                      handleAllSave({
                        handleSubmit: documentController?.handleSubmit,
                        buttonRef,
                        ...modalProps,
                        ...submitProps,
                      })
                    }
                  >
                    Save
                  </CommonButton>
                </div>
              )}
              <div className="w-full h-full">
                <DocumentTable
                  basicInfoData={basicInfoData}
                  documentController={documentController}
                  isEditable={isEditable}
                  selectedDocType={selectedDocType}
                  setSelectedDocType={setSelectedDocType}
                  loadingValidDocList={loadingValidDocList}
                  isLoadingPatchDocument={isLoadingPatchDocument}
                  {...modalProps}
                  {...submitProps}
                />
              </div>
            </StyledDocumentTableWrapper>
            <Preview
              documentController={documentController}
              selectedDocType={selectedDocType}
              documentList={documentList}
              fileVersions={fileVersions}
            />
          </StyledDocumentContentWrapper>
        </StyledDocumentAlignmentContentWrapper>
      </StyledDocumentPageWrapper>
      {delType && (
        <DocDeleteConfirmModal
          delType={delType}
          setDelType={setDelType}
          {...submitProps}
        />
      )}
      {messageModalOpen && (
        <CommonErrorModal
          open={messageModalOpen}
          message={messageContents}
          setIsOpen={setMessageModalOpen}
          onOkClickHandler={onOkClickHandler}
        />
      )}
    </>
  );
};

export default Document;
