import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PermissionDenied from '../../../components/Common/PermissionDenied';
import clearLocalStorage from '../../../utils/Common/Handler/clearLocalStorage';

const PagePermissionDenied = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      clearLocalStorage();
      sessionStorage.clear();
      navigate('/sign-in');
    }, 6000);
  }, []);

  return <PermissionDenied />;
};

export default PagePermissionDenied;
