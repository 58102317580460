import dayjs from 'dayjs';
import updateButtonDisabled from '../../../../Common/Handler/updateButtonDisabled';

const portSubmitData = ({ data, submitType, seq, productCode }) => {
  if (submitType === 'DEL') return null;
  const dataNameTail =
    submitType === 'MOD' && seq !== null && seq !== undefined ? `_${seq}` : '';
  const productCodeSeq =
    productCode &&
    productCode[
      productCode.findIndex(
        item =>
          item?.product_code ===
          data?.[`product_code${dataNameTail}`]?.split('/')[0]?.trim(),
      )
    ]?.seq;

  if (data?.[`category${dataNameTail}`] === 'DRIVER') {
    return {
      job: data?.[`job_idx${dataNameTail}`],
      driver: data?.[`driver${dataNameTail}`],
      vendor: null,
      product_code: productCodeSeq,
      bill_date:
        data?.[`inv_date${dataNameTail}`] &&
        dayjs(data?.[`inv_date${dataNameTail}`])?.isValid()
          ? dayjs(data?.[`inv_date${dataNameTail}`])?.format('YYYY-MM-DD')
          : null,
      amt: parseFloat(data?.[`amt${dataNameTail}`]),
      description: data?.[`desc${dataNameTail}`],
    };
  }

  if (data?.[`category${dataNameTail}`] === 'VENDOR') {
    return {
      job: null,
      driver: null,
      vendor: data?.[`ven${dataNameTail}`],
      product_code: productCodeSeq,
      bill_date:
        data?.[`inv_date${dataNameTail}`] &&
        dayjs(data?.[`inv_date${dataNameTail}`])?.isValid()
          ? dayjs(data?.[`inv_date${dataNameTail}`])?.format('YYYY-MM-DD')
          : null,
      bill_no: data?.[`bill_no${dataNameTail}`],
      amt: parseFloat(data?.[`amt${dataNameTail}`]),
      description: data?.[`desc${dataNameTail}`],
    };
  }
  return null;
};

const validateNull = ({
  data,
  setIsAlertOpen,
  setErrorMessage,
  setIsSuccess,
}) => {
  if (!data?.driver && !data?.vendor) {
    setIsSuccess(false);
    setIsAlertOpen(true);
    setErrorMessage('Please select driver or vendor');
    return false;
  }
  if (!data?.bill_date) {
    setIsSuccess(false);
    setIsAlertOpen(true);
    setErrorMessage('Please input bill date');
    return false;
  }
  if (!data?.product_code) {
    setIsSuccess(false);
    setIsAlertOpen(true);
    setErrorMessage('Please select service code');
    return false;
  }

  if (isNaN(data?.amt)) {
    setIsSuccess(false);
    setIsAlertOpen(true);
    setErrorMessage('Please input amount');
    return false;
  }
  return true;
};

const onAPSubmit = ({
  data,
  apMutator,
  submitType,
  APProductCodeQuery,
  seq,
  setIsAlertOpen,
  setErrorMessage,
  setIsSuccess,
  buttonRef,
}) => {
  const woNo =
    document.URL?.split('/')?.[document?.URL?.split('/')?.length - 1];

  const targetData = submitType === 'ADD' ? data?.add : data?.mod;

  if (submitType === 'DEL') {
    apMutator?.({ seq, buttonRef });
    return;
  }

  const submitData = portSubmitData({
    data: targetData,
    submitType,
    seq,
    productCode: APProductCodeQuery?.productCode,
  });

  const isDataValid = validateNull({
    data: submitData,
    setIsAlertOpen,
    setErrorMessage,
    setIsSuccess,
  });
  if (!isDataValid) {
    updateButtonDisabled({ ref: buttonRef, disabled: false });
    return;
  }

  if (submitType === 'ADD') {
    apMutator?.({ woNo, data: submitData, buttonRef });
  } else if (submitType === 'MOD') {
    apMutator?.({ data: submitData, seq, buttonRef });
  }
};

export default onAPSubmit;
