import { useEffect } from 'react';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

const useControlAddressOptions = ({
  placeDetailList,
  setPlaceDetailList,
  gridRef,
}) => {
  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
    placesService,
  } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE_KEY,
    debounce: 500,
    options: {
      language: 'en',
    },
  });

  const getShortName = ({ details, type }) => {
    return (
      details?.address_components?.find(({ types }) => types.includes(type))
        ?.short_name || ''
    );
  };

  const getAddressInstance = () => {
    return {
      addAddressInstance: gridRef?.current?.api
        ?.getCellRendererInstances()
        ?.find(element => element?.column?.colId === 'address'),
      editAddressInstance: gridRef?.current?.api
        ?.getCellEditorInstances()
        ?.find(
          element => element?.cellEditorParams?.column?.colId === 'address',
        )?.componentInstance,
    };
  };

  useEffect(() => {
    if (!_?.isEmpty(placePredictions)) {
      placePredictions?.forEach(item => {
        placesService?.getDetails(
          {
            placeId: item.place_id,
          },
          details => {
            const label = item?.description || '';
            const value = [
              getShortName({ details, type: 'street_number' }),
              getShortName({ details, type: 'route' }),
            ].join(' ');
            const city = getShortName({ details, type: 'locality' });
            const state = getShortName({
              details,
              type: 'administrative_area_level_1',
            });
            const zip = getShortName({ details, type: 'postal_code' });
            setPlaceDetailList(prev => {
              if (
                !_?.isEmpty(value?.trim()) &&
                prev?.findIndex(option => option?.value === value) === -1
              ) {
                return [...prev, { label, value, city, state, zip }];
              }
              return prev;
            });
          },
        );
      });
    }
  }, [placePredictions]);

  useEffect(() => {
    const { addAddressInstance, editAddressInstance } = getAddressInstance();
    addAddressInstance?.setOption?.(placeDetailList);
    editAddressInstance?.setOption?.(placeDetailList);
  }, [placeDetailList]);

  useEffect(() => {
    const { addAddressInstance, editAddressInstance } = getAddressInstance();
    addAddressInstance?.setIsLoading?.(isPlacePredictionsLoading);
    editAddressInstance?.setIsLoading?.(isPlacePredictionsLoading);
  }, [isPlacePredictionsLoading]);

  return { getPlacePredictions };
};

export default useControlAddressOptions;
