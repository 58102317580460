import React from 'react';
import WeeklyTable from '../../../components/TmsReport/Schedules/WeeklyTable';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import HeaderButtons from '../../../components/TmsReport/Schedules/HeaderButtons';
import useGetSchedule from '../../../hooks/TmsReport/Schedules/useGetSchedule';
import useControlQueries from '../../../hooks/TmsReport/Schedules/useControlQueries';
import useControlWeeklyData from '../../../hooks/TmsReport/Schedules/useControlWeeklyData';
import useControlFilters from '../../../hooks/TmsReport/Schedules/useControlFilters';

const Return = () => {
  const STORAGE_NAME = 'returnFilter';

  const { isOpenFilter, setIsOpenFilter, filterState, setFilterState } =
    useControlFilters({ storageName: STORAGE_NAME });

  const { dateQuery, setDateQuery, filterQuery, weeklyDateQuery } =
    useControlQueries({
      filterState,
    });

  const {
    isLoading: isLoadingWeekly,
    isFetching: isFetchingWeekly,
    scheduleData,
  } = useGetSchedule({
    type: 'rtn',
    weeklyDateQuery,
    ...filterQuery,
  });

  const { weeklyData } = useControlWeeklyData({
    weeklyDateQuery,
    scheduleData,
  });

  const filterProps = {
    isOpenFilter,
    setIsOpenFilter,
    filterState,
    setFilterState,
  };

  return (
    <>
      <div className="flex flex-col pr-[40px] pb-[20px] h-full w-full">
        <HeaderButtons
          dateQuery={dateQuery}
          setDateQuery={setDateQuery}
          storageName={STORAGE_NAME}
          {...filterProps}
          isReturn
        />
        {Object?.keys?.(weeklyData)?.length > 0 && (
          <WeeklyTable
            pageType="rtn"
            weeklyData={weeklyData}
            filterQuery={filterQuery}
          />
        )}
      </div>
      <CommonLoading open={isLoadingWeekly || isFetchingWeekly} />
    </>
  );
};

export default Return;
