import acctKeys from '../../../../hooks/Acct/keys';
import checkDateDiff from '../../../Common/Handler/checkDateDiff';
import generateQueryParamObject from '../Generator/generateQueryParamObject';
import { ACCT_PAGE } from '../../../../constants/Acct/Common/staticNames';

const searchQBContent = ({
  handleSubmit,
  errorModalOptions,
  setQueryObject,
  setIsOpenModal,
  queryObject,
  queryClient,
  page,
}) => {
  const { setIsSuccess, setErrorMessage, setIsErrorModalOpen } =
    errorModalOptions;

  const queryParam = generateQueryParamObject({
    queryObject,
    page,
  });

  const queryKeyMap = {
    [ACCT_PAGE.unInvoiced]: acctKeys.uninvoicedList({ queryParam }),
    [ACCT_PAGE.qbAPAR]: acctKeys.qbAPAR({ queryParam }),
    [ACCT_PAGE.qbReconcile]: acctKeys.apARReconcile({ queryParam }),
  };

  const queryKey = queryKeyMap[page] || null;

  handleSubmit(submitData => {
    if (
      !checkDateDiff({
        fromDate: submitData?.from_date,
        toDate: submitData?.to_date,
      })
    ) {
      setIsSuccess(false);
      setErrorMessage(
        'The date range cannot exceed one year. Please try again.',
      );
      setIsErrorModalOpen(true);
      return;
    }

    const filteredSubmitData = {};

    Object?.keys?.(submitData)?.forEach(key => {
      if (submitData[key]) {
        filteredSubmitData[key] = submitData[key];
      }
    });

    setQueryObject(filteredSubmitData);
    setIsOpenModal(false);

    queryClient.invalidateQueries({
      queryKey,
    });
  })();
};

export default searchQBContent;
