import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import generateInitFilter from '../../../utils/TmsReport/Schedule/Generator/generateInitFilter';

const useControlFilters = ({ storageName }) => {
  const divList = useSelector(state => state?.common?.allDiv);

  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [filterState, setFilterState] = useState(null);

  useEffect(() => {
    if (divList?.length > 0) {
      const newInitFilter = generateInitFilter({
        setFilterState,
        divList,
        storageName,
      });

      setFilterState(newInitFilter);
    }
  }, [divList]);

  return {
    isOpenFilter,
    setIsOpenFilter,
    filterState,
    setFilterState,
  };
};
export default useControlFilters;
