import React from 'react';
import { useSelector } from 'react-redux';
import { FormProvider } from 'react-hook-form';
import UpdateInfoArea from '../../Common/UpdateInfoArea';
import BookingDetailContent from '../../Common/BookingDetailContent';
import getValidBookingData from '../../../../../utils/Dispatch/Export/Booking/getValidBookingData';
import { DISPATCH_BOOKING_STATUS_ADD } from '../../../../../constants/Dispatch/Export/Common/bookingModalStatus';
import updateButtonDisabled from '../../../../../utils/Common/Handler/updateButtonDisabled';

const AddBookingDetailContent = ({
  mutateNewBooking,
  addBookingController,
  today,
  saveButtonRef,
  ...props
}) => {
  const div = useSelector(state => state.nowDiv);
  const userID = localStorage.getItem('userId');

  return (
    <FormProvider {...addBookingController}>
      <form
        id="newBookingForm"
        onSubmit={addBookingController.handleSubmit(data => {
          updateButtonDisabled({ ref: saveButtonRef, disabled: true });
          mutateNewBooking({
            bookingForm: {
              ...getValidBookingData(data),
              div,
            },
            buttonRef: saveButtonRef,
          });
        })}
        className="flex flex-col gap-y-[27px] h-fit"
      >
        <UpdateInfoArea userID={userID} today={today} />
        <BookingDetailContent
          status={DISPATCH_BOOKING_STATUS_ADD}
          isEditMode={false}
          {...props}
        />
      </form>
    </FormProvider>
  );
};

export default AddBookingDetailContent;
