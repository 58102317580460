import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import CommonDataGrid from '../../../components/CommonDataGrid';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import CommonButton from '../../../components/CommonButton/CommonButton';
import TextMessageModal from '../../../components/MobileAdmin/PODCheckList/TextMessageModal';
import defaultColumnDefsGenerator from '../../../utils/Common/Datagrid/Generator/defaultColumnDefsGenerator';
import useGetPODCheckList from '../../../hooks/MobileAdmin/PODCheckList/useGetPODCheckList';
import usePostPODMessage from '../../../hooks/MobileAdmin/PODCheckList/usePostPODMessage';
import exportToExcel from '../../../utils/Common/Datagrid/Handler/exportToExcel';
import handleCellClick from '../../../utils/MobileAdmin/Common/Handler/handleCellClick';
import createPODCheckListColumnDefs from '../../../utils/MobileAdmin/PODCheckList/Generator/createPODCheckListColumnDefs';
import TotalBottomBar from '../../../components/Common/CommonTotalBottomBar';
import useControlTotalInfo from '../../../hooks/Common/TotalBottomRow/useControlTotalInfo';
import RefreshButton from '../../../components/RefreshButton';
import mobileAdminKeys from '../../../hooks/MobileAdmin/keys';

const PODCheckList = () => {
  const gridRef = useRef(null);
  const defaultColumnDefs = defaultColumnDefsGenerator();
  const division = useSelector(state => state.nowDiv);

  const [cellNo, setCellNo] = useState('');
  const [openTextMessage, setOpenTextMessage] = useState(false);

  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [messageContents, setMessageContent] = useState('');

  const {
    data: podCheckList,
    isLoading,
    isError,
  } = useGetPODCheckList({ division });

  const postMessageMutator = usePostPODMessage({
    setMessageModalOpen,
    setMessageContent,
    setOpenTextMessage,
  });

  const handleSendClick = props => {
    setOpenTextMessage(true);
    setCellNo(props?.data?.cell_no);
  };

  const [columnDefs, setColumnDefs] = useState(
    createPODCheckListColumnDefs({
      handleSendClick,
    }),
  );

  const { totalRows, setChangeFlag } = useControlTotalInfo({
    gridRef,
    queryData: podCheckList?.data,
  });

  return (
    <>
      {(isLoading || isError) && <CommonLoading open={isLoading || isError} />}
      <div className="pr-[40px] h-full flex flex-col">
        <div className="flex items-center mt-[20px] my-[20px] justify-end gap-x-[15px]">
          <RefreshButton
            queryKeyList={[mobileAdminKeys?.podCheckList({ division })]}
          />
          <CommonButton
            onClick={() =>
              exportToExcel({ gridRef, fileName: 'POD CHECK LIST' })
            }
            type="button"
            category="excel"
          >
            EXCEL
          </CommonButton>
        </div>
        <div className="w-full flex-1">
          <CommonDataGrid
            gridRef={gridRef}
            sideBar={false}
            data={podCheckList?.data}
            columnDefs={columnDefs}
            defaultColDef={defaultColumnDefs}
            onCellClicked={handleCellClick}
            onFilterChanged={() => {
              setChangeFlag(prev => !prev);
            }}
            onSelectionChanged={() => {
              setChangeFlag(prev => !prev);
            }}
          />
        </div>
        <TotalBottomBar totalRows={totalRows} />

        {openTextMessage && (
          <TextMessageModal
            open={openTextMessage}
            setOpen={setOpenTextMessage}
            postMessageMutator={postMessageMutator}
            cellNo={cellNo}
          />
        )}
        {messageModalOpen && (
          <CommonErrorModal
            open={messageModalOpen}
            message={messageContents}
            setIsOpen={setMessageModalOpen}
          />
        )}
      </div>
    </>
  );
};

export default PODCheckList;
