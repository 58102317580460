import React from 'react';
import { TRACKING_HEADER_ITEM_STYLE } from '../../../../styles/BCDetail/Tracking/TrackingHeaderItem';

const TrackingHeader = () => {
  return (
    <div className="flex space-x-[2px] ml-[330px]">
      <div className={`w-[95px] ${TRACKING_HEADER_ITEM_STYLE}`}>
        <h1>CITY</h1>
      </div>
      <div className={`w-[63px] ${TRACKING_HEADER_ITEM_STYLE}`}>
        <h1>ST</h1>
      </div>
      <div className={`w-[90px] ${TRACKING_HEADER_ITEM_STYLE}`}>
        <h1>TIMEZONE</h1>
      </div>
      <div className={`w-[252px] ${TRACKING_HEADER_ITEM_STYLE}`}>
        <h1>LOCATION</h1>
      </div>
      <div className={`w-[252px] ${TRACKING_HEADER_ITEM_STYLE}`}>
        <h1>MOBILE</h1>
      </div>
      <div className={`w-[252px] ${TRACKING_HEADER_ITEM_STYLE}`}>
        <h1>VEHICLE</h1>
      </div>
      <div className={`w-[252px] ${TRACKING_HEADER_ITEM_STYLE}`}>
        <h1>DRIVER</h1>
      </div>
      <div className={`w-[252px] ${TRACKING_HEADER_ITEM_STYLE}`}>
        <h1>STATUS</h1>
      </div>
    </div>
  );
};

export default TrackingHeader;
