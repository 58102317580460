import { FormHelperText } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import StyledCommonInputWrapper from '../../../styles/Common/CommonInput/StyledCommonInputWrapper';
import StyledCommonLabel from '../../../styles/Common/CommonInput/StyledCommonLabel';
import StyledCommonInputRequiredLabel from '../../../styles/Common/CommonInput/StyledCommonInputRequiredLabel';
import defaultInputRules from '../../../utils/Common/Input/defaultInputRules';
import { PATH_INPUT_CLEAR_ICON } from '../../../constants/Common/path';

const CommonFile = ({ ...props }) => {
  /**
   * @description
   * Get react-hook-form object from useFormContext.
   * @control Must-have elements for getting values from a form.
   * @unregister Allows to unregister a single input or an array of inputs.
   * @setValue Setter value of input.
   * @setError Setter error of input.
   * @formState Object containing information about the form.
   */
  const {
    control,
    unregister,
    setValue,
    setError,
    clearErrors,
    formState: { errors: formErrors, isValid, touchedFields },
  } = useFormContext();

  const fileUrl = props?.fileUrl || null;

  const [maxLength, setMaxLength] = useState(props?.maxLength || 20);

  const [label, setLabel] = useState(props?.label);

  const [required, setRequired] = useState(props?.required || false);
  useEffect(() => {
    /**
     * If default required value changed, component required value would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.required !== undefined && props?.required !== null) {
      setRequired(props?.required);
    }
  }, [props?.required]);

  const [disabled, setDisabled] = useState(props?.disabled || false);
  useEffect(() => {
    /**
     * If default disabled value changed, component disabled value would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.disabled !== undefined && props?.disabled !== null) {
      setDisabled(props?.disabled);
    }
  }, [props?.disabled]);

  const [inputName, setInputName] = useState(
    props?.inputName
      ? [`${props?.inputName}_file_name`, props?.inputName]
      : ['multi_file_input_1', 'multi_file_input_2'],
  );

  const [defaultValue, setDefaultValue] = useState(
    props?.defaultValue || ['', null],
  );
  useEffect(() => {
    /**
     * If default value changed, component default value would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.defaultValue !== undefined && props?.defaultValue !== null) {
      setDefaultValue(props?.defaultValue);
      setValue(inputName?.[0], props?.defaultValue?.[0]);
      setValue(inputName?.[1], props?.defaultValue?.[1]);
    }
  }, [props?.defaultValue?.[0], props?.defaultValue?.[1]]);

  useEffect(() => {
    unregister(`${props?.inputName}_file_name`);
    unregister(props?.inputName);
  }, []);

  /**
   * @description
   * File change handler.
   * An error occurs when a file other than the specified file type is entered.
   */
  const onFileChanged = ({ e, ...params }) => {
    const fileElement = document.getElementById(inputName?.[1])?.files;
    if (
      ![
        'text/plain',
        'image/jpg',
        'image/jpeg',
        'image/png',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
      ].includes(fileElement?.[0]?.type)
    ) {
      setError(inputName?.[0], { message: 'This file type is not supported' });
      return;
    }
    clearErrors(inputName?.[0]);
    setValue(inputName?.[0], fileElement?.[0]?.name);
    params?.onChange?.(fileElement?.[0]);
  };

  /**
   * Render Auto-Complete component.
   * @control - Must-have elements for getting values from a form.
   * @name - Name of input.
   * @defaultValue - Default value of input.
   * @rules - Rules of input - Check constraints.
   * @render - Render target input component.
   * - field : Object containing field props, like name, value, onChange, onBlur.
   * - fieldState : Object containing field state, like error, invalid, etc.
   */
  return (
    <>
      <StyledCommonInputWrapper labelStyle={props?.labelStyle}>
        {(!!label || props.labelVisible !== false) && (
          <StyledCommonLabel
            labelStyle={props?.labelStyle}
            lbl-pd-right={props?.[`lbl-pd-right`]}
          >
            {!!label && (
              <>
                {!!required && (
                  <StyledCommonInputRequiredLabel>
                    *
                  </StyledCommonInputRequiredLabel>
                )}
                {label}
              </>
            )}
          </StyledCommonLabel>
        )}
        <div className={`${props?.width ? `${props?.width}` : 'w-full'}`}>
          <div className="flex flex-row">
            <Controller
              control={control}
              name={inputName?.[0]}
              defaultValue={defaultValue?.[0]}
              rules={defaultInputRules({
                required,
                ...props,
              })}
              render={({
                field: { ref, onChange, value, ...field },
                fieldState: { invalid, error },
              }) => {
                // Package of handler props
                const handlerProps = {
                  onChange,
                  value,
                  maxLength,
                  ...field,
                  ...props,
                };

                // Render Control Input Component
                return (
                  <div style={{ position: 'relative', width: '100%' }}>
                    <input
                      {...field}
                      ref={ref}
                      value={value}
                      id={inputName?.[0]}
                      type="search"
                      className={`border border-solid h-[30px] rounded-l-[5px] font-medium
                                    ${
                                      !formErrors?.[inputName[0]]?.message &&
                                      !formErrors?.[inputName[1]]?.message &&
                                      `
                                    enabled:hover:border-[#8E9396] enabled:focus:border-[#264B9F] 
                                    enabled:focus:shadow-[0_0_4px_0_#8BBCE9]
                                    `
                                    } outline-none text-xs
                                    disabled:bg-[#F9F9F9] w-full
                                    px-[14px] w-auto min-w-0 ${
                                      invalid
                                        ? 'border-[#C24D4D] border-[1.2px]'
                                        : 'border-[#D9D9D9]'
                                    } ${
                        disabled ? 'bg-[#F9F9F9] text-[#222]' : 'bg-white'
                      }`}
                      onChange={e => {
                        onChange?.(e?.target?.value);
                        clearErrors(inputName?.[0]);
                      }}
                      onClick={() => {
                        if (fileUrl) {
                          window.open(fileUrl, '_blank');
                        }
                      }}
                      readOnly
                      required={required}
                      disabled={disabled}
                    />
                    {value && !disabled && (
                      <div
                        style={{
                          height: '100%',
                          position: 'absolute',
                          right: '4px',
                          top: '0px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignContent: 'center',
                        }}
                        id={`${inputName?.[0]}ClearButtonDiv`}
                      >
                        <button
                          type="button"
                          onClick={() => {
                            setValue(inputName?.[0], '');
                            setValue(inputName?.[1], '');
                            document.getElementById(inputName?.[0]).value = '';
                            document.getElementById(inputName?.[1]).value = '';
                          }}
                        >
                          <img
                            src={PATH_INPUT_CLEAR_ICON}
                            alt="clear"
                            className="p-[8px] hover:bg-[#0000000a] hover:rounded-full"
                          />
                        </button>
                      </div>
                    )}
                  </div>
                );
              }}
            />
            <button
              type="button"
              className={`border-l-transparent border-[1px] h-[30px] rounded-r-[5px]
                                ease-in-out duration-150 font-bold text-[#264B9F] text-xs px-[12px]
                                disabled:cursor-not-allowed disabled:bg-[#F9F9F9]
                                ${
                                  !formErrors?.[inputName[0]]?.message &&
                                  !formErrors?.[inputName[1]]?.message &&
                                  `
                                enabled:hover:border-[#8E9396] enabled:hover:border-[1px] enabled:hover:box-border
                                `
                                }
                                bg-[#fff]
                                enabled:active:border-[#264B9F]
                                enabled:active:bg-[#264B9F] enabled:active:text-[#fff]
                                enabled:active:shadow-[0_0_4px_0_#8BBCE9]
                                ${
                                  disabled
                                    ? 'bg-[#F9F9F9] text-[#b8b8b8]'
                                    : 'bg-white'
                                }
                                ${
                                  formErrors?.[inputName?.[0]]?.message
                                    ? `border-[#C24D4D] border-[1.2px]`
                                    : `border-[#D9D9D9]`
                                }
                                `}
              onClick={() => document.getElementById(inputName?.[1]).click()}
              disabled={disabled}
            >
              Browse
            </button>

            <Controller
              control={control}
              name={inputName?.[1]}
              defaultValue={defaultValue?.[1]}
              render={({
                field: { ref, onChange, value, ...field },
                fieldState: { invalid, error },
              }) => {
                // Package of handler props
                const handlerProps = {
                  onChange,
                  value,
                  maxLength,
                  ...field,
                  ...props,
                };

                // Render Control Input Component
                return (
                  <input
                    {...field}
                    accept="text/plain,image/jpg,image/jpeg,image/png,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                    ref={ref}
                    id={inputName?.[1]}
                    type="file"
                    className="hidden"
                    onChange={e => onFileChanged({ ...handlerProps, e })}
                  />
                );
              }}
            />
          </div>
        </div>
      </StyledCommonInputWrapper>
      <FormHelperText className="text-[#ff2424] text-[11px] font-normal min-h-[11px] h-[11px] mb-[3px] leading-none">
        {formErrors?.[inputName?.[0]]?.message}
      </FormHelperText>
    </>
  );
};

export default CommonFile;
