import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import usePostAPData from '../../../../../hooks/BCDetail/BillingInfo/AP/usePostAPData';
import StyledBCDetailBillingInfoCommonHeaderCell from '../../../../../styles/BCDetail/BillingInfo/Common/TableHeader/StyledBCDetailBillingInfoCommonHeaderCell';
import StyledBCDetailBillingInfoCommonHeaderCellTitle from '../../../../../styles/BCDetail/BillingInfo/Common/TableHeader/StyledBCDetailBillingInfoCommonHeaderCellTitle';
import StyledBCDetailBillingInfoCommonHeaderInputContainer from '../../../../../styles/BCDetail/BillingInfo/Common/TableHeader/StyledBCDetailBillingInfoCommonHeaderInputContainer';
import StyledBCDetailBillingInfoCommonHeaderQBContainer from '../../../../../styles/BCDetail/BillingInfo/Common/TableHeader/StyledBCDetailBillingInfoCommonHeaderQBContainer';
import StyledBCDetailBillingInfoCommonHeaderRateCfmContainer from '../../../../../styles/BCDetail/BillingInfo/Common/TableHeader/StyledBCDetailBillingInfoCommonHeaderRateCfmContainer';
import StyledBCDetailBillingInfoCommonHeaderRow from '../../../../../styles/BCDetail/BillingInfo/Common/TableHeader/StyledBCDetailBillingInfoCommonHeaderRow';
import onAPSubmitHandler from '../../../../../utils/BCDetail/BillingInfo/AP/Handler/onAPSubmitHandler';
import onDateInputDefaultChangeHandler from '../../../../../utils/BCDetail/Common/Handler/onDateInputDefaultChangeHandler';
import onInputDefaultChangeHandler from '../../../../../utils/BCDetail/Common/Handler/onInputDefaultChangeHandler';
import {
  createCompanySelectOption,
  createDriverSelectOption,
  createProductCodeSelectOption,
} from '../../../../../utils/Common/createCodeSelectOption';
import getCurrentDateWithTimezone from '../../../../../utils/Common/Generator/getCurrentDateWithTimezone';
import updateButtonDisabled from '../../../../../utils/Common/Handler/updateButtonDisabled';
import APHeaderDate from '../../../../BCDetail/BillingInfo/APHeaderDate';
import Input from '../../../../BCDetail/BillingInfo/Input';
import CommonButton from '../../../../CommonButton/CommonButton';
import CommonAutoComplete from '../../../../SecondInputs/CommonAutoComplete/CommonAutoComplete';
import CommonCheckbox from '../../../../SecondInputs/CommonCheckbox/CommonCheckbox';
import CommonSelect from '../../../../SecondInputs/CommonSelect/CommonSelect';

const APTableFixedHeader = () => {
  return (
    <StyledBCDetailBillingInfoCommonHeaderRow>
      <StyledBCDetailBillingInfoCommonHeaderCellTitle width="108px" />
      <StyledBCDetailBillingInfoCommonHeaderCellTitle width="85px">
        VEN/DRV
      </StyledBCDetailBillingInfoCommonHeaderCellTitle>
      <StyledBCDetailBillingInfoCommonHeaderCellTitle width="88px">
        ID
      </StyledBCDetailBillingInfoCommonHeaderCellTitle>
      <StyledBCDetailBillingInfoCommonHeaderCellTitle width="143px">
        NAME
      </StyledBCDetailBillingInfoCommonHeaderCellTitle>
      <StyledBCDetailBillingInfoCommonHeaderCellTitle width="30px">
        DRV CFM
      </StyledBCDetailBillingInfoCommonHeaderCellTitle>
      <StyledBCDetailBillingInfoCommonHeaderCellTitle width="179px">
        ACCT CODE
      </StyledBCDetailBillingInfoCommonHeaderCellTitle>
      <StyledBCDetailBillingInfoCommonHeaderCellTitle width="94px">
        RATE CFM
      </StyledBCDetailBillingInfoCommonHeaderCellTitle>
      <StyledBCDetailBillingInfoCommonHeaderCellTitle width="90px">
        AP AMT
      </StyledBCDetailBillingInfoCommonHeaderCellTitle>
      <StyledBCDetailBillingInfoCommonHeaderCellTitle width="140px">
        DESC
      </StyledBCDetailBillingInfoCommonHeaderCellTitle>
      <StyledBCDetailBillingInfoCommonHeaderCellTitle width="90px">
        AP TOTAL
      </StyledBCDetailBillingInfoCommonHeaderCellTitle>
      <StyledBCDetailBillingInfoCommonHeaderCellTitle width="155px">
        BILL #
      </StyledBCDetailBillingInfoCommonHeaderCellTitle>
      <StyledBCDetailBillingInfoCommonHeaderCellTitle width="30px">
        CK
      </StyledBCDetailBillingInfoCommonHeaderCellTitle>
      <StyledBCDetailBillingInfoCommonHeaderCellTitle width="80px">
        QB ID
      </StyledBCDetailBillingInfoCommonHeaderCellTitle>
      <StyledBCDetailBillingInfoCommonHeaderCellTitle width="80px">
        QB TOTAL
      </StyledBCDetailBillingInfoCommonHeaderCellTitle>
      <StyledBCDetailBillingInfoCommonHeaderCellTitle width="80px">
        QB PAID AMT
      </StyledBCDetailBillingInfoCommonHeaderCellTitle>
      <StyledBCDetailBillingInfoCommonHeaderCellTitle width="80px">
        QB DATE
      </StyledBCDetailBillingInfoCommonHeaderCellTitle>
      <StyledBCDetailBillingInfoCommonHeaderCellTitle width="90px">
        UPDATED BY
      </StyledBCDetailBillingInfoCommonHeaderCellTitle>
    </StyledBCDetailBillingInfoCommonHeaderRow>
  );
};

const APTableAddInputHeader = ({
  APProductCodeQuery,
  APDriverVendorList,
  setPaymentModal,
  setSelectedDriver,
  onAPHeaderSubmitHandler,
  isAPEditable,
  buttonRef,
  basicInfoData,
  isSubmitting,
}) => {
  const [addCategory, setAddCategory] = useState();
  const { setValue, getValues } = useFormContext();
  const timezone = basicInfoData?.div?.timezone;
  const currentDate = getCurrentDateWithTimezone({ timezone });
  const [driverName, setDriverName] = useState('');

  useEffect(() => {
    if (!isSubmitting) {
      !getValues('add.driver') && setDriverName('');
    }
  }, [isSubmitting]);

  return (
    <StyledBCDetailBillingInfoCommonHeaderRow>
      <StyledBCDetailBillingInfoCommonHeaderCell width="108px">
        <StyledBCDetailBillingInfoCommonHeaderInputContainer backgroundColor="#DDE2EC">
          <APHeaderDate
            width="121px"
            placeholder="MM/DD/YY"
            inputName="add.inv_date"
            defaultValue={currentDate}
            disabled={!isAPEditable}
            onChangeHandler={onDateInputDefaultChangeHandler}
          />
        </StyledBCDetailBillingInfoCommonHeaderInputContainer>
      </StyledBCDetailBillingInfoCommonHeaderCell>
      <StyledBCDetailBillingInfoCommonHeaderCell width="85px">
        <CommonSelect
          inputName="add.category"
          backgroundColor="#dde2ec"
          defaultValue={null}
          disabled={!isAPEditable}
          onChangeHandler={({ e, onChange }) => {
            onChange?.(e.target.value);
            setAddCategory(e.target.value);
            setValue('add.driver', null);
            setValue('add.vendor', null);
            setSelectedDriver?.(null);
            setDriverName('');
          }}
          option={[
            { value: 'VENDOR', label: 'VENDOR' },
            { value: 'DRIVER', label: 'DRIVER' },
          ]}
        />
      </StyledBCDetailBillingInfoCommonHeaderCell>
      <StyledBCDetailBillingInfoCommonHeaderCell width="88px">
        <CommonAutoComplete
          inputName={addCategory === 'DRIVER' ? 'add.driver' : 'add.ven'}
          backgroundColor="#dde2ec"
          option={
            addCategory === 'DRIVER'
              ? createDriverSelectOption(APDriverVendorList?.driverList)
              : createCompanySelectOption(APDriverVendorList?.vendorList)
          }
          defaultValue={null}
          disabled={!isAPEditable}
          onChangeHandler={({ e, onChange, ...props }) => {
            setSelectedDriver?.(props.selectProps?.value);
            onChange?.(props.selectProps?.value);
            const getOptions =
              addCategory === 'DRIVER'
                ? APDriverVendorList?.driverList
                : APDriverVendorList?.vendorList;
            setDriverName(
              addCategory === 'DRIVER'
                ? getOptions.find(
                    element => element?.id === props.selectProps?.value,
                  )?.name
                : getOptions.find(
                    element => element?.company === props.selectProps?.value,
                  )?.business_name,
            );
            if (addCategory === 'DRIVER' && props.selectProps)
              setPaymentModal(true);
          }}
          isLoading={
            addCategory === 'DRIVER'
              ? APDriverVendorList?.driverQuery?.isLoading
              : APDriverVendorList?.vendorQuery?.isLoading
          }
        />
      </StyledBCDetailBillingInfoCommonHeaderCell>
      <StyledBCDetailBillingInfoCommonHeaderCell width="143px">
        <StyledBCDetailBillingInfoCommonHeaderInputContainer backgroundColor="#C9CCD3">
          <Input
            inputName="add.driver_name"
            defaultValue={driverName}
            disabled
          />
        </StyledBCDetailBillingInfoCommonHeaderInputContainer>
      </StyledBCDetailBillingInfoCommonHeaderCell>
      <StyledBCDetailBillingInfoCommonHeaderCell width="30px">
        <StyledBCDetailBillingInfoCommonHeaderInputContainer backgroundColor="#C9CCD3">
          <Input inputName="add.driver_confirm_date" defaultValue="" disabled />
        </StyledBCDetailBillingInfoCommonHeaderInputContainer>
      </StyledBCDetailBillingInfoCommonHeaderCell>
      <StyledBCDetailBillingInfoCommonHeaderCell width="179px">
        <CommonAutoComplete
          inputName="add.product_code"
          backgroundColor="#dde2ec"
          maxLength={100}
          option={createProductCodeSelectOption(
            APProductCodeQuery?.productCode,
          )}
          disabled={!isAPEditable}
          onChangeHandler={({ e, onChange, ...props }) => {
            onChange?.(props.selectProps?.value);
          }}
          isLoading={APProductCodeQuery?.isLoading}
        />
      </StyledBCDetailBillingInfoCommonHeaderCell>
      <StyledBCDetailBillingInfoCommonHeaderCell width="94px">
        <StyledBCDetailBillingInfoCommonHeaderRateCfmContainer />
      </StyledBCDetailBillingInfoCommonHeaderCell>
      <StyledBCDetailBillingInfoCommonHeaderCell width="90px">
        <StyledBCDetailBillingInfoCommonHeaderInputContainer backgroundColor="#DDE2EC">
          <Input
            inputName="add.amt"
            dollarSign
            defaultValue=""
            textAlign="center"
            maxLength={12}
            isInt
            disabled={!isAPEditable}
            onChangeHandler={({
              e,
              onChange,
              setText,
              setTextLength,
              maxTextLength,
              ...props
            }) => {
              const inputText = e?.target?.value;
              if (/^-?\d*(\.\d{0,2})?$/.test(inputText)) {
                if (inputText.length <= maxTextLength) {
                  setText(inputText);
                  onChange(inputText);
                  setTextLength(inputText.length);
                }
              }
            }}
            onKeyDownHandler={e => {
              if (e?.e?.key === 'Enter') onAPHeaderSubmitHandler({ buttonRef });
            }}
          />
        </StyledBCDetailBillingInfoCommonHeaderInputContainer>
      </StyledBCDetailBillingInfoCommonHeaderCell>
      <StyledBCDetailBillingInfoCommonHeaderCell width="140px">
        <StyledBCDetailBillingInfoCommonHeaderInputContainer backgroundColor="#DDE2EC">
          <Input
            inputName="add.desc"
            defaultValue={null}
            maxLength={128}
            disabled={!isAPEditable}
            onChangeHandler={onInputDefaultChangeHandler}
            onKeyDownHandler={e => {
              if (e?.e?.key === 'Enter') onAPHeaderSubmitHandler({ buttonRef });
            }}
          />
        </StyledBCDetailBillingInfoCommonHeaderInputContainer>
      </StyledBCDetailBillingInfoCommonHeaderCell>
      <StyledBCDetailBillingInfoCommonHeaderCell width="90px">
        <StyledBCDetailBillingInfoCommonHeaderInputContainer backgroundColor="#C9CCD3">
          <Input
            inputName="add.total"
            dollarSign
            defaultValue=""
            textAlign="center"
            maxLength={12}
            disabled
          />
        </StyledBCDetailBillingInfoCommonHeaderInputContainer>
      </StyledBCDetailBillingInfoCommonHeaderCell>
      <StyledBCDetailBillingInfoCommonHeaderCell width="155px">
        {addCategory === 'VENDOR' ? (
          <StyledBCDetailBillingInfoCommonHeaderInputContainer backgroundColor="#DDE2EC">
            <Input
              inputName="add.bill_no"
              defaultValue={null}
              onChangeHandler={onInputDefaultChangeHandler}
              onKeyDownHandler={e => {
                if (e?.e?.key === 'Enter')
                  onAPHeaderSubmitHandler({ buttonRef });
              }}
            />
          </StyledBCDetailBillingInfoCommonHeaderInputContainer>
        ) : (
          <StyledBCDetailBillingInfoCommonHeaderInputContainer backgroundColor="#C9CCD3" />
        )}
      </StyledBCDetailBillingInfoCommonHeaderCell>
      <StyledBCDetailBillingInfoCommonHeaderCell width="30px">
        <StyledBCDetailBillingInfoCommonHeaderInputContainer backgroundColor="#C9CCD3">
          <CommonCheckbox inputName="mod.checked" disabled />
        </StyledBCDetailBillingInfoCommonHeaderInputContainer>
      </StyledBCDetailBillingInfoCommonHeaderCell>
      <StyledBCDetailBillingInfoCommonHeaderCell width="80px">
        <StyledBCDetailBillingInfoCommonHeaderQBContainer />
      </StyledBCDetailBillingInfoCommonHeaderCell>
      <StyledBCDetailBillingInfoCommonHeaderCell width="80px">
        <StyledBCDetailBillingInfoCommonHeaderQBContainer />
      </StyledBCDetailBillingInfoCommonHeaderCell>
      <StyledBCDetailBillingInfoCommonHeaderCell width="80px">
        <StyledBCDetailBillingInfoCommonHeaderQBContainer />
      </StyledBCDetailBillingInfoCommonHeaderCell>
      <StyledBCDetailBillingInfoCommonHeaderCell width="80px">
        <StyledBCDetailBillingInfoCommonHeaderQBContainer />
      </StyledBCDetailBillingInfoCommonHeaderCell>
      <StyledBCDetailBillingInfoCommonHeaderCell width="90px">
        <StyledBCDetailBillingInfoCommonHeaderInputContainer backgroundColor="#C9CCD3" />
      </StyledBCDetailBillingInfoCommonHeaderCell>
      {isAPEditable && (
        <StyledBCDetailBillingInfoCommonHeaderCell width="75.5px">
          <CommonButton
            ref={buttonRef}
            type="button"
            borderColor="#556DA1"
            category="add"
            preventDupClick
            onClick={onAPHeaderSubmitHandler}
          >
            Add
          </CommonButton>
        </StyledBCDetailBillingInfoCommonHeaderCell>
      )}
    </StyledBCDetailBillingInfoCommonHeaderRow>
  );
};

const APStyledBCDetailBillingInfoCommonHeader = ({
  APProductCodeQuery,
  APDriverVendorList,
  setPaymentModal,
  setSelectedDriver,
  setIsAlertOpen,
  setIsSuccess,
  setErrorMessage,
  isAPEditable,
  isSubmitting,
  setIsSubmitting,
  refetchAPData,
  basicInfoData,
}) => {
  const { handleSubmit, reset } = useFormContext();

  const { mutate: apAddMutator } = usePostAPData({
    setIsAlertOpen,
    setIsSuccess,
    setErrorMessage,
    reset,
    setIsSubmitting,
    refetchAPData,
  });

  const addRef = useRef(null);

  const onAPHeaderSubmitHandler = handleSubmit(data => {
    if (isSubmitting) {
      updateButtonDisabled({ ref: addRef, disabled: false });
      return;
    }
    setIsSubmitting(true);

    onAPSubmitHandler({
      handleSubmit,
      submitType: 'ADD',
      apMutator: apAddMutator,
      data,
      APProductCodeQuery,
      setIsAlertOpen,
      setErrorMessage,
      setIsSuccess,
      buttonRef: addRef,
    });
  });

  return (
    <>
      <APTableFixedHeader />
      <APTableAddInputHeader
        APProductCodeQuery={APProductCodeQuery}
        APDriverVendorList={APDriverVendorList}
        setPaymentModal={setPaymentModal}
        setSelectedDriver={setSelectedDriver}
        onAPHeaderSubmitHandler={() => handleSubmit(onAPHeaderSubmitHandler)()}
        isAPEditable={isAPEditable}
        buttonRef={addRef}
        basicInfoData={basicInfoData}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

export default APStyledBCDetailBillingInfoCommonHeader;
