import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { REGEX_NUMBER_ONLY_POSITIVE_DECIMAL } from '../../../../../constants/Common/regex';
import { COMMON_FREEDAYS_SELECT_OPTION } from '../../../../../constants/Common/selectOption';
import {
  DISPATCH_BOOKING_STATUS_ADD,
  DISPATCH_BOOKING_STATUS_INACTIVE,
} from '../../../../../constants/Dispatch/Export/Common/bookingModalStatus';
import CommonButton from '../../../../CommonButton/CommonButton';
import CommonAutoComplete from '../../../../Inputs/CommonAutoComplete/CommonAutoComplete';
import CommonDate from '../../../../Inputs/CommonDate/CommonDate';
import CommonFile from '../../../../Inputs/CommonFile/CommonFile';
import CommonSelect from '../../../../Inputs/CommonSelect/CommonSelect';
import CommonTextInput from '../../../../Inputs/CommonTextInput/CommonTextInput';
import CommonTextSelect from '../../../../Inputs/CommonTextSelect/CommonTextSelect';
import CommonTime from '../../../../Inputs/CommonTime/CommonTime';
import DispatchBookingTable from '../DispatchBookingTable';
import HistoryLogSection from './HistoryLogSection';
import generateBookingContainerTable from '../../../../../utils/Dispatch/Export/Booking/generateBookingContainerTable';
import useGetTerminalBooking from '../../../../../hooks/Dispatch/Export/Booking/useGetTerminalBooking';

const BookingDetailContent = ({
  status,
  size,
  type,
  ssl,
  billTo,
  loc,
  isEditMode,
  editData,
  isLoadingBillToLoc,
}) => {
  const { setValue, getValues } = useFormContext();

  const [isDisabled, setIsDisabled] = useState(true);
  const [isDisabledBookingNo, setIsDisabledBookingNo] = useState(true);

  const [logERDOpen, setLogERDOpen] = useState(false);
  const [logCOOpen, setLogCOOpen] = useState({ date: false, time: false });

  useEffect(() => {
    const tempIsDisabled = status === DISPATCH_BOOKING_STATUS_INACTIVE;

    setIsDisabled(tempIsDisabled);
    setIsDisabledBookingNo(
      status !== DISPATCH_BOOKING_STATUS_ADD || tempIsDisabled,
    );
  }, [status]);

  const [docURL, setDocURL] = useState('');
  const watchDocument = useWatch({ name: 'doc' });

  const [erdDefaultValue, setERDDefaultValue] = useState(undefined);
  const [coDefaultValues, setCODefaultValue] = useState([undefined, undefined]);

  useEffect(() => {
    setDocURL(editData?.doc || '');
    setERDDefaultValue(editData?.erd || undefined);
    setCODefaultValue(editData?.cutoff?.split(' ') || [undefined, undefined]);
  }, [editData]);

  useEffect(() => {
    if (!!watchDocument && typeof watchDocument === 'object') {
      setDocURL(URL.createObjectURL(watchDocument));
    }
    if (watchDocument === null) {
      setDocURL('');
    }
  }, [watchDocument]);

  const [tableCell, setTableCell] = useState([]);
  useEffect(() => {
    const newTableCell = generateBookingContainerTable({
      isEditMode,
      isDisabled,
      size,
      type,
      setValue,
      getValues,
    });
    setTableCell(newTableCell);
  }, [isEditMode, isDisabled, size]);

  const [terminalQueryObject, setTerminalQueryObject] = useState({});

  const { data } = useGetTerminalBooking({
    terminalQueryObject,
    isEditMode,
  });

  useEffect(() => {
    setValue('terminal_ssl', data?.ssl || '');

    if (!getValues('vessel')) {
      setValue('vessel', data?.vessel || '');
    }

    if (!getValues('cutoff_date')) {
      setValue('cutoff_date', data?.cutoff || '');
    }

    if (!getValues('cutoff_time')) {
      setValue('cutoff_time', data?.cutoff || '');
    }

    if (!getValues('erd')) {
      setValue('erd', data?.erd || '');
    }

    const containerList = data?.container || [];
    if (!containerList || containerList?.length === 0) {
      for (let idx = 0; idx < 3; idx++) {
        setValue(`iso_code_${idx + 1}`, '');
        if (!getValues(`total_${idx + 1}`)) {
          setValue(`total_${idx + 1}`, '');
        }
      }
    } else {
      containerList?.forEach((cont, idx) => {
        setValue(`iso_code_${idx + 1}`, cont?.type || '');
        if (!getValues(`total_${idx + 1}`)) {
          setValue(`total_${idx + 1}`, cont?.total || '');
        }
      });
    }
  }, [data]);

  return (
    <div className="pb-[8px]">
      <div className="grid grid-cols-6 w-[910px] gap-x-[20px] gap-y-[10px]">
        <div className="col-span-2">
          <CommonTextInput
            label="BOOKING #"
            inputName="bk_no"
            required
            disabled={isDisabledBookingNo}
            onChangeHandler={({ e, onChange }) => {
              onChange?.(e?.target?.value);
            }}
            onBlurHandler={e => {
              setTerminalQueryObject(prev => ({
                ...prev,
                refNo: e?.value?.trim(),
              }));
            }}
          />
        </div>
        <div className="col-span-2">
          <CommonAutoComplete
            label="BILL TO"
            inputName="billto"
            required
            maxLength={8}
            disabled={isDisabled}
            option={billTo || []}
            onChangeHandler={({ e, selectProps, onChange }) => {
              onChange?.(selectProps?.value);
            }}
            isLoading={isLoadingBillToLoc}
          />
        </div>
        <div className="col-span-2">
          <div className="flex flex-row gap-x-[20px]">
            <div>
              <CommonFile
                label="DOCUMENT"
                inputName="doc"
                disabled={isDisabled}
              />
            </div>
            <div className="items-start mt-[14px]">
              <CommonButton
                category="pdf"
                height="30px"
                width="70px"
                onClick={() => {
                  window.open(docURL, '_blank');
                }}
                disabled={!docURL}
              >
                PDF
              </CommonButton>
            </div>
          </div>
        </div>
        <div className="col-span-2">
          <div className="flex justify-between">
            <div className={`${isEditMode ? 'w-full' : 'w-[143px]'}`}>
              <CommonSelect
                label="SSL"
                inputName="ssl"
                required
                disabled={isDisabled}
                option={ssl || []}
                onChangeHandler={({ e, onChange }) => {
                  onChange?.(e?.target?.value);
                }}
              />
            </div>
            {!isEditMode && (
              <div className="w-[143px]">
                <CommonTextInput
                  label=""
                  inputName="terminal_ssl"
                  maxLength={30}
                  disabled
                />
              </div>
            )}
          </div>
        </div>
        <div className="col-span-2">
          <CommonTextInput
            label="VESSEL"
            inputName="vessel"
            maxLength={30}
            disabled={isDisabled}
            onChangeHandler={({ e, onChange }) => {
              onChange?.(e?.target?.value);
            }}
          />
        </div>
        <div className="col-span-2">
          <CommonTextInput
            label="COMMODITY"
            inputName="commodity"
            disabled={isDisabled}
            onChangeHandler={({ e, onChange }) => {
              onChange?.(e?.target?.value);
            }}
          />
        </div>
        <div className="col-span-2">
          <CommonDate
            label="ERD"
            inputName="erd"
            disabled={isDisabled}
            onChangeHandler={({ e, onChange, ...handlerProps }) => {
              if (e && dayjs(e).isValid())
                onChange?.(dayjs(e).format('YYYY-MM-DD'));
              else onChange?.(null);

              if (e?.format('YYYY-MM-DD') !== erdDefaultValue)
                setLogERDOpen(true);
              else setLogERDOpen(false);
            }}
          />
        </div>
        <div className="col-span-1">
          <CommonDate
            label="C/O"
            inputName="cutoff_date"
            disabled={isDisabled}
            onChangeHandler={({ e, onChange, ...handlerProps }) => {
              if (e && dayjs(e).isValid())
                onChange?.(dayjs(e).format('YYYY-MM-DD'));
              else onChange?.(null);

              if (e?.format('YYYY-MM-DD') !== coDefaultValues[0])
                setLogCOOpen(prev => ({ ...prev, date: true }));
              else setLogCOOpen(prev => ({ ...prev, date: false }));
            }}
            required={!!useWatch({ name: 'cutoff_time' })}
          />
        </div>
        <div className="col-span-1">
          <CommonTime
            inputName="cutoff_time"
            disabled={isDisabled}
            onChangeHandler={({ e, onChange, ...handlerProps }) => {
              if (e && dayjs(e).isValid()) onChange?.(dayjs(e));
              else onChange?.(null);

              if (e?.format('HH:mm:ss') !== coDefaultValues[1])
                setLogCOOpen(prev => ({ ...prev, time: true }));
              else setLogCOOpen(prev => ({ ...prev, time: false }));
            }}
            required={!!useWatch({ name: 'cutoff_date' })}
          />
        </div>
        <div className="col-span-2">
          <CommonTextSelect
            label="FREEDAYS"
            inputName={['freedays', 'freedays_type']}
            required={[true, true]}
            disabled={[isDisabled, isDisabled]}
            option={COMMON_FREEDAYS_SELECT_OPTION}
            regex={REGEX_NUMBER_ONLY_POSITIVE_DECIMAL}
            onChangeHandler={[
              ({ e, onChange, regex }) => {
                if (e?.target?.value?.match(regex)) {
                  onChange?.(e?.target?.value);
                }
              },
              ({ e, onChange }) => {
                onChange?.(e?.target?.value);
              },
            ]}
          />
        </div>
        <div className="col-span-2">
          <CommonAutoComplete
            label="PULL OUT LOC"
            inputName="po_location"
            disabled={isDisabled}
            maxLength={8}
            option={loc || []}
            onChangeHandler={({ e, selectProps, onChange }) => {
              onChange?.(selectProps?.value);
            }}
            isLoading={isLoadingBillToLoc}
          />
        </div>
        <div className="col-span-2">
          <CommonAutoComplete
            label="DELIVERY LOC"
            inputName="dilv_location"
            required
            disabled={isDisabled}
            maxLength={8}
            option={loc || []}
            onChangeHandler={({ e, selectProps, onChange }) => {
              onChange?.(selectProps?.value);
            }}
            isLoading={isLoadingBillToLoc}
          />
        </div>
        <div className="col-span-2">
          <CommonAutoComplete
            label="RETURN LOC"
            inputName="rtn_location"
            required
            disabled={isDisabled}
            maxLength={8}
            option={loc || []}
            onChangeHandler={({ e, selectProps, onChange }) => {
              onChange?.(selectProps?.value);
            }}
            onBlurHandler={({ e }) => {
              const value = e?.target?.value?.split('/')?.[0]?.trim();

              setTerminalQueryObject(prev => ({
                ...prev,
                terminal: value,
              }));
            }}
            isLoading={isLoadingBillToLoc}
          />
        </div>
      </div>
      {isEditMode && logERDOpen && (
        <HistoryLogSection title="ERD" field="erd" />
      )}
      {isEditMode &&
        !Object.values(logCOOpen).every(value => value === false) && (
          <HistoryLogSection title="C/O" field="cutoff" />
        )}
      <div className="flex pt-[20px]">
        <DispatchBookingTable
          colHeaderColor
          rowHeaderColor
          width="w-[910px]"
          data={tableCell}
        />
      </div>
    </div>
  );
};

export default BookingDetailContent;
