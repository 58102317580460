import dayjs from 'dayjs';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { HYPEN_ICON } from '../../../../constants/Acct/Common/iconPath';
import { periodOptions } from '../../../../constants/Common/period';
import checkDateDiff from '../../../../utils/Common/Handler/checkDateDiff';
import changePeriod from '../../../../utils/TmsReport/Common/changePeriod';
import CommonButton from '../../../CommonButton/CommonButton';
import CommonMessageModal from '../../../CommonMessageModal/CommonMessageModal';
import CommonDate from '../../../Inputs/CommonDate/CommonDate';
import CommonSelect from '../../../Inputs/CommonSelect/CommonSelect';
import tmsReportKeys from '../../../../hooks/TmsReport/keys';

const SearchModal = ({
  open,
  setOpen,
  division,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  setIsMessageOpen,
  setMessage,
  setIsSuccess,
}) => {
  const searchController = useForm();
  const [period, setPeriod] = useState('');
  const queryClient = useQueryClient();

  const onSearch = () => {
    if (
      !checkDateDiff({
        fromDate: searchController.getValues('fromDate'),
        toDate: searchController.getValues('toDate'),
      })
    ) {
      setIsMessageOpen(true);
      setMessage('The date range cannot exceed one year. Please try again.');
      setIsSuccess(false);
      return;
    }
    setPeriod(searchController.getValues('period'));
    setFromDate(searchController.getValues('fromDate'));
    setToDate(searchController.getValues('toDate'));
    setOpen?.(false);
    queryClient.invalidateQueries({
      queryKey: tmsReportKeys.perdiemList({ division, fromDate, toDate }),
    });
  };

  return (
    <CommonMessageModal
      open={open}
      setOpen={setOpen}
      header="Search"
      content={
        <FormProvider {...searchController}>
          <form>
            <div>
              <div className="w-full flex flex-row mt-[10px]">
                <div className="mr-[20px]">
                  <CommonSelect
                    width="w-[153px]"
                    label="PERIOD"
                    defaultValue={period}
                    option={periodOptions}
                    inputName="period"
                    onChangeHandler={({ e, onChange }) => {
                      onChange(e?.target?.value);
                      const dateRange = changePeriod(e?.target?.value);
                      searchController.setValue(
                        'fromDate',
                        dateRange?.fromDate,
                      );
                      searchController.setValue('toDate', dateRange?.toDate);
                    }}
                  />
                </div>
                <div className="mr-[5px]">
                  <CommonDate
                    label=""
                    inputName="fromDate"
                    width="w-[116px]"
                    defaultValue={fromDate}
                    required
                    onChangeHandler={({ e, onChange }) => {
                      const value = dayjs(e)?.isValid()
                        ? dayjs(e)?.format('YYYY-MM-DD')
                        : null;
                      onChange?.(value);
                      searchController.setValue('period', '');
                    }}
                  />
                </div>
                <div className="mr-[5px] flex items-center">
                  <img src={HYPEN_ICON} alt="hyphen-icon" width={13} />
                </div>
                <div>
                  <CommonDate
                    label=""
                    inputName="toDate"
                    width="w-[116px]"
                    defaultValue={toDate}
                    required
                    onChangeHandler={({ e, onChange }) => {
                      const value = dayjs(e)?.isValid()
                        ? dayjs(e)?.format('YYYY-MM-DD')
                        : null;
                      onChange?.(value);
                      searchController.setValue('period', '');
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-center mt-[23px]">
              <div className="mr-[18px]">
                <CommonButton
                  category="cancel"
                  onClick={() => setOpen?.(false)}
                >
                  cancel
                </CommonButton>
              </div>
              <CommonButton
                type="button"
                category="search"
                onClick={searchController.handleSubmit(onSearch)}
              >
                Search
              </CommonButton>
            </div>
          </form>
        </FormProvider>
      }
    />
  );
};
export default SearchModal;
