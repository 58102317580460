export const PRESET_DOC = [
  'DO',
  'POD',
  'POL',
  'BL',
  'IT',
  'ITE',
  'CF',
  'CFS',
  'WAREHOUSE-BL',
  'WAREHOUSE-INBOUND',
];

export const VALIDATION_PRESET_DOC_LIST = [
  'DO',
  'POD',
  'POL',
  'BL',
  'IT',
  'ITE',
];
