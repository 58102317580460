import React, { forwardRef } from 'react';
import CommonButton from '../../../CommonButton/CommonButton';

const FilterButton = forwardRef(({ ...props }, ref) => {
  const { setOpen } = props;

  return (
    <CommonButton
      ref={ref}
      category="filter"
      border="0"
      backgroundColor="transparent"
      color="#666666"
      onClick={() => setOpen?.(prev => !prev)}
    />
  );
});

export default FilterButton;
