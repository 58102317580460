import { PATH_BUTTON_FILTER_YARD_UNSELECTED_ICON } from '../../../../constants/Common/path';

const toggleFilterSelection = (groups, groupValue, itemValue) => {
  return groups.map(group => {
    if (group.title.value === groupValue) {
      return {
        ...group,
        items: group.items.map(item => {
          if (item.value === itemValue) {
            return { ...item, isSelected: !item.isSelected };
          }
          return item;
        }),
      };
    }
    return group;
  });
};

const createFilter = ({ filters, setFilters, yardList, status }) => {
  // Get userID from local storage
  const userID = localStorage?.getItem('userId');
  const defaultDiv = localStorage?.getItem('defaultDiv');
  // Get filter from local storage
  const existFilter = localStorage.getItem('dispatchFilter');
  // If there is a filter in local storage, return it
  const localStorageFilter = existFilter
    ? JSON.parse(existFilter)?.[userID]
    : null;

  return [
    {
      title: {
        icon: PATH_BUTTON_FILTER_YARD_UNSELECTED_ICON,
        label: 'Yard',
        value: status,
      },
      items: yardList?.map(yard => ({
        label: yard?.label,
        value: yard?.value,
        isSelected:
          localStorageFilter?.[status]?.[yard?.value] !== undefined
            ? localStorageFilter?.[status]?.[yard?.value]
            : yard?.value?.includes(`NGL${defaultDiv}`),
        style: {
          width: '80px',
        },
        onClick: () => {
          setFilters(prev => toggleFilterSelection(prev, status, yard?.value));
        },
      })),
    },
  ];
};

export default createFilter;
