import dayjs from 'dayjs';
import combineNumber from '../../Common/Generator/combineNumber';
import { concatPhoneNumber } from '../../../Common/Handler/phoneNumberHandler';

const handleDriverData = data => {
  const deleteUndefinedProperties = obj => {
    Object.keys(obj).forEach(key => {
      if (
        obj[key] === undefined ||
        obj[key] === 'undefinedundefinedundefined'
      ) {
        delete obj[key];
      }
    });
  };

  data.ssn = combineNumber(['ssn_first', 'ssn_second', 'ssn_third'], data);

  const phoneNumbers = ['cell_no', 'home_no', 'emergency_phone_no'];

  phoneNumbers.forEach(field => {
    const phoneNumber = combineNumber(
      [`${field}_first`, `${field}_second`, `${field}_third`],
      data,
    );

    data[field] = concatPhoneNumber({
      country: 'US',
      phone: phoneNumber,
    });
  });

  const dateFields = [
    'hire_date',
    'rehire_date',
    'contract_date',
    'terminate_date',
    'dob',
    'legal_exp',
    'dl_exp',
    'medical_exp',
    'twic_exp',
    'insurance_exp',
    'mvr',
    'road_test',
    'drug_test',
  ];

  dateFields.forEach(field => {
    if (data?.[field])
      data[field] = dayjs(data[field])?.isValid()
        ? dayjs(data[field]).format('YYYY-MM-DD')
        : null;
  });

  data.per_hour = data?.per_hour ? data.per_hour : false;

  if (data?.ein_no === '') {
    data.ein_no = null;
  }

  if (data?.truck_year === '') {
    data.truck_year = null;
  }

  if (!data?.per_hour) {
    delete data?.rate;
  }

  if (data.created_by) {
    data.created_by = data.created_by.split(',')[0];
  }
  if (data.updated_by) {
    data.updated_by = data.updated_by.split(',')[0];
  }

  data.geofence = {
    place_id: data.place_id,
    latitude: data.lat,
    longitude: data.lng,
  };

  deleteUndefinedProperties(data);
  return data;
};

export default handleDriverData;
