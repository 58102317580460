import styled from 'styled-components';

const StyledBCDetailBillingInfoCommonSeparateLine = styled.div`
  margin-left: -83px;
  margin-top: 100px;
  border: 1px solid #878d9b;
  width: 1920px;
`;

export default StyledBCDetailBillingInfoCommonSeparateLine;
