import React, { useEffect } from 'react';
import StyledReportDataGridWrapper from '../../../styles/CustomerReport/Common/StyledReportDataGridWrapper';
import CommonDataGrid from '../../CommonDataGrid';

const ReportDataGrid = ({ ...props }) => {
  const { gridRef, columnDefs, data } = props;

  return (
    <StyledReportDataGridWrapper>
      <CommonDataGrid
        gridRef={gridRef}
        data={data || []}
        columnDefs={columnDefs}
        modType={props?.modType}
        foldable={props?.foldable || false}
        onDeleteClicked={props?.onDeleteClicked}
        foldableContent={props?.foldableContent}
        onFilterChanged={props?.onFilterChanged}
        onSelectionChanged={props?.onSelectionChanged}
        onGridReady={props?.onGridReady}
      />
    </StyledReportDataGridWrapper>
  );
};

export default ReportDataGrid;
