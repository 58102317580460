import styled from 'styled-components';

const StyledBCDetailCommonTopBarFieldItemWrapper = styled.div`
  display: flex;
  gap: 2px;
  text-align: center;
  height: 30px;
  font-size: 11px;
`;

export default StyledBCDetailCommonTopBarFieldItemWrapper;
