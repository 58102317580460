import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import useGetChassisReconcileList from '../../../hooks/Acct/ChassisReconcile/Get/useGetChassisReconcileList';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import HeaderButtonArea from '../../../components/Acct/Common/HeaderButtonArea';
import AdvancedSearchModal from '../../../components/Acct/ChassisReconcile/AdvancedSearchModal';
import UploadInvoiceModal from '../../../components/Acct/Common/UploadInvoiceModal';
import {
  ACCT_PAGE,
  EDITABLE,
} from '../../../constants/Acct/Common/staticNames';
import usePostChassisInvoice from '../../../hooks/Acct/ChassisReconcile/Post/usePostChassisInvoice';
import usePostScanChassisInvoice from '../../../hooks/Acct/ChassisReconcile/Post/usePostScanChassisInvoice';
import usePatchWarningModalChassis from '../../../hooks/Acct/ChassisReconcile/Patch/usePatchWarningModalChassis';
import useControlLoading from '../../../hooks/Acct/Common/Control/useControlLoading';
import { STATUS_CHECK_BOX_OPTION } from '../../../constants/Acct/Common/selectOptions';
import DataGridArea from '../../../components/Acct/ChassisReconcile/DataGridArea';

const ChassisReconcile = () => {
  dayjs.extend(isoWeek);

  const poolList = useSelector(state => state?.common?.pool)?.map(pool => ({
    label: pool?.pool,
    value: pool?.pool,
  }));

  const duplicateCheckFields = ['inv_no', 'ch_no'];

  const productController = useForm();
  const [productStatus, setProductStatus] = useState(EDITABLE);

  /* Modal */
  const [openModal, setOpenModal] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);

  /** Error Modal */
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(null);

  const setErrorModalOptions = {
    setErrorModalOpen,
    setErrorMessage,
    setIsSuccess,
  };

  /* Import Chassis Reconcile Data */
  const initCheckBoxValue = STATUS_CHECK_BOX_OPTION?.reduce((acc, curr) => {
    acc[curr.inputName] = curr.defaultValue;
    return acc;
  }, {});

  const [queryObject, setQueryObject] = useState({
    from: dayjs()
      ?.subtract(1, 'week')
      ?.startOf('isoWeek')
      ?.format('YYYY-MM-DD'),
    to: dayjs()?.subtract(1, 'week')?.endOf('isoWeek')?.format('YYYY-MM-DD'),
    ...initCheckBoxValue,
  });

  const { chassisReconcileList, isLoading, isFetching, refetchChassisList } =
    useGetChassisReconcileList({
      queryObject,
    });

  /* Common DataGrid */
  const gridRef = useRef(null);

  const { isLoadingOpen } = useControlLoading({ isLoadingList: [isLoading] });

  return (
    <div className="pr-[40px] flex flex-col h-full">
      <FormProvider {...productController}>
        <form>
          <HeaderButtonArea
            page={ACCT_PAGE?.chassisReconcile}
            gridRef={gridRef}
            setOpenModal={setOpenModal}
            setOpenUploadModal={setOpenUploadModal}
            productStatus={productStatus}
            setProductStatus={setProductStatus}
            setQueryObject={setQueryObject}
          />
        </form>

        <div className="w-full flex-1 flex flex-col">
          <DataGridArea
            gridRef={gridRef}
            chassisReconcileList={chassisReconcileList}
            productStatus={productStatus}
            queryObject={queryObject}
            setErrorModalOptions={setErrorModalOptions}
          />
        </div>
      </FormProvider>
      <AdvancedSearchModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        queryObject={queryObject}
        setQueryObject={setQueryObject}
        poolList={poolList}
        setErrorModalOptions={setErrorModalOptions}
      />
      {openUploadModal && (
        <UploadInvoiceModal
          open={openUploadModal}
          type="chassis"
          setOpen={setOpenUploadModal}
          poolList={poolList}
          duplicateCheckFields={duplicateCheckFields}
          usePostScan={usePostScanChassisInvoice}
          usePatchWarningModal={usePatchWarningModalChassis}
          usePostInvoice={usePostChassisInvoice}
          refetchList={refetchChassisList}
          setErrorModalOptions={setErrorModalOptions}
        />
      )}
      <CommonLoading open={isLoadingOpen} />
      {errorModalOpen && !isSuccess && (
        <CommonErrorModal
          open={errorModalOpen}
          setIsOpen={setErrorModalOpen}
          message={errorMessage}
          isSuccess={isSuccess}
        />
      )}
    </div>
  );
};

export default ChassisReconcile;
