import styled from 'styled-components';

const StyledBCDetailBillingInfoCommonContainer = styled.div`
  width: 1920px;
  height: 100%;
  padding: 0px 50px 146px 83px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default StyledBCDetailBillingInfoCommonContainer;
