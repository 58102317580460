import React, { useState, useEffect } from 'react';
import { PLUS_ICON, MINUS_ICON } from '../../../constants/Order/Common/path';

const CommonRowHeader = ({ content, ...props }) => {
  const [disabled, setDisabled] = useState(props?.disabled || [false, false]);
  const [disabledTheme, setDisabledTheme] = useState(
    props?.disabledTheme || false,
  );

  const [bgColor, setBGColor] = useState('bg-[#fff]');
  useEffect(() => {
    if (props?.bgColor !== undefined && props?.bgColor !== null) {
      setBGColor(props?.bgColor);
    }
  }, [props?.bgColor]);

  useEffect(() => {
    if (props?.disabled !== undefined && props?.disabled !== null) {
      setDisabled(props?.disabled);
    }
  }, [props?.disabled]);

  useEffect(() => {
    if (props?.disabledTheme !== undefined && props?.disabledTheme !== null) {
      setDisabledTheme(props?.disabledTheme);
    }
  }, [props?.disabledTheme]);

  return (
    <div className="flex items-center space-x-[2px] w-full h-full">
      <div
        className={`relative w-full h-full flex justify-between items-center shadow box-shadow-[0_0.5px_1px_rgba(0,0,0,0.25)] ${
          disabledTheme ? 'bg-[#DDE2EC]' : bgColor
        }
        `}
      >
        <div
          className={`w-[7px] h-[30px] absolute
          ${disabledTheme ? 'bg-[#CCD3E3]' : 'bg-[#264B9f]'}`}
        />
        <div
          className={`ml-[19px] w-fit max-w[100px] text-[11px] font-[700]  text-opacity-65 pt-[8px] pb-[9px]
          ${disabledTheme ? 'text-[#A6A6A6]' : 'text-[#264B9f]'}`}
        >
          {content}
        </div>
        <div className="flex gap-x-[8px] mr-[10px]">
          {props?.isPlus && (
            <div className="flex items-center">
              <button
                type={props?.submit?.[0] ? 'submit' : 'button'}
                onClick={props?.onPlusClick}
                onBlur={props?.onPlusBlur}
                disabled={disabled[0]}
                className={`${
                  disabled[0] ? 'cursor-not-allowed' : 'cursor-pointer'
                } `}
                data-testid={props?.testId?.[0]}
              >
                <img
                  src={PLUS_ICON}
                  alt="plus-icon"
                  className="w-[14px] h-[14px]"
                />
              </button>
            </div>
          )}
          {props?.isMinus && (
            <div className="flex items-center">
              <button
                type={props?.submit?.[1] ? 'submit' : 'button'}
                onClick={props?.onMinusClick}
                onBlur={props?.onMinusBlur}
                disabled={disabled[1]}
                className={`${
                  disabled[1] ? 'cursor-not-allowed' : 'cursor-pointer'
                } `}
                data-testid={props?.testId?.[1]}
              >
                <img
                  src={MINUS_ICON}
                  alt="minus-icon"
                  className="w-[14px] h-[14px]"
                />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CommonRowHeader;
