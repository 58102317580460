import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { FormProvider } from 'react-hook-form';
import CommonMessageModal from '../../CommonMessageModal/CommonMessageModal';
import AdvancedSearchContent from '../AdvancedSearchContent';
import AdvancedSearchButtonContent from '../AdvancedSearchContent/AdvancedSearchButtonContent';
import handleAdvancedSearchSubmit from '../../../utils/Dispatch/Common/Handler/handleAdvancedSearchSubmit';

const DispatchAdvancedSearchModal = ({
  advancedSearchController,
  setQueryObject,
  isOpen,
  setIsOpen,
  setIsMessageModalOpen,
  setMessageModalMessage,
  woType,
}) => {
  const queryClient = useQueryClient();

  return (
    <FormProvider {...advancedSearchController}>
      <form
        onSubmit={advancedSearchController.handleSubmit(data => {
          handleAdvancedSearchSubmit({
            data,
            setQueryObject,
            advancedSearchController,
            setIsMessageModalOpen,
            setMessageModalMessage,
            setIsAdvancedSearchOpen: setIsOpen,
            queryClient,
          });
        })}
      >
        <CommonMessageModal
          open={isOpen}
          setOpen={setIsOpen}
          header="Advanced Search"
          content={<AdvancedSearchContent woType={woType} />}
          buttonContent={
            <AdvancedSearchButtonContent setAdvancedSearchOpen={setIsOpen} />
          }
        />
      </form>
    </FormProvider>
  );
};

export default DispatchAdvancedSearchModal;
