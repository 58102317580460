import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { generateAllFilter } from '../../../utils/TmsReport/Common/Generator/filterStateGenerator';
import WEEK_DAY_LIST from '../../../constants/TmsReport/Schedules/weeklyDayList';

const useControlQueries = ({ filterState }) => {
  const [dateQuery, setDateQuery] = useState(dayjs());
  const [filterQuery, setFilterQuery] = useState(null);

  useEffect(() => {
    const newFilterQuery = generateAllFilter({ filterState });
    setFilterQuery(newFilterQuery);
  }, [filterState]);

  /** Date Query */
  const [weeklyDateQuery, setWeeklyDateQuery] = useState({});
  useEffect(() => {
    const currentFilterDate =
      dateQuery && dayjs?.(dateQuery)?.isValid() ? dayjs?.(dateQuery) : '';

    if (currentFilterDate) {
      /** Set the first day of the week to Sunday */
      const startOfWeek = currentFilterDate?.startOf('week');

      const tempWeeklyDate = WEEK_DAY_LIST.reduce((acc, day, index) => {
        acc[day] = startOfWeek.add(index, 'day').format('YYYY-MM-DD');
        return acc;
      }, {});

      setWeeklyDateQuery(tempWeeklyDate);
    }
  }, [dateQuery]);

  return {
    dateQuery,
    setDateQuery,
    filterQuery,
    setFilterQuery,
    weeklyDateQuery,
  };
};

export default useControlQueries;
