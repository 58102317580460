import { WO_CATEGORY_NAME } from '../../../../constants/TmsReport/Common';
import {
  PATH_BUTTON_FILTER_CATEGORY_UNSELECTED_ICON,
  PATH_BUTTON_FILTER_DIVISION_UNSELECTED_ICON,
  PATH_BUTTON_FILTER_SCHEDULE_TYPE_UNSELECTED_ICON,
} from '../../../../constants/Common/path';

const toggleFilterSelection = (groups, groupValue, itemValue) => {
  return groups.map(group => {
    if (group.title.value === groupValue) {
      return {
        ...group,
        items: group.items.map(item => {
          if (item.value === itemValue) {
            return { ...item, isSelected: !item.isSelected };
          }
          return item;
        }),
      };
    }
    return group;
  });
};

const generateInitFilter = ({ setFilterState, divList, storageName }) => {
  const userID = localStorage?.getItem('userId');
  const defaultDiv = localStorage?.getItem('defaultDiv');

  const existFilter = localStorage?.getItem(storageName);
  const userFilter = existFilter ? JSON.parse(existFilter)?.[userID] : null;

  const initFilter = [
    {
      title: {
        icon: PATH_BUTTON_FILTER_SCHEDULE_TYPE_UNSELECTED_ICON,
        label: 'Schedule Type',
        value: 'sche-type',
      },
      items: [
        {
          label: 'LIVE',
          value: 'LIVE',
          isSelected:
            userFilter?.['sche-type']?.LIVE !== undefined
              ? userFilter?.['sche-type']?.LIVE
              : true,
          style: {
            width: '42px',
          },
          onClick: () => {
            setFilterState(prev =>
              toggleFilterSelection(prev, 'sche-type', 'LIVE'),
            );
          },
        },
        {
          label: 'DROP',
          value: 'DROP',
          isSelected:
            userFilter?.['sche-type']?.DROP !== undefined
              ? userFilter?.['sche-type']?.DROP
              : true,
          style: {
            width: '42px',
          },
          onClick: () => {
            setFilterState(prev =>
              toggleFilterSelection(prev, 'sche-type', 'DROP'),
            );
          },
        },
        {
          label: 'VAN',
          value: 'LVAN',
          isSelected:
            userFilter?.['sche-type']?.LVAN !== undefined
              ? userFilter?.['sche-type']?.LVAN
              : true,
          style: {
            width: '42px',
          },
          onClick: () => {
            setFilterState(prev =>
              toggleFilterSelection(prev, 'sche-type', 'LVAN'),
            );
          },
        },
      ],
    },
    {
      title: {
        icon: PATH_BUTTON_FILTER_DIVISION_UNSELECTED_ICON,
        label: 'Division',
        value: 'div',
      },
      items: divList?.map(div => ({
        label: div,
        value: div,
        isSelected:
          userFilter?.div?.[div] !== undefined
            ? userFilter?.div?.[div]
            : div === defaultDiv,
        style: {
          width: '42px',
        },
        onClick: () => {
          setFilterState(prev => toggleFilterSelection(prev, 'div', div));
        },
      })),
    },
    {
      title: {
        icon: PATH_BUTTON_FILTER_CATEGORY_UNSELECTED_ICON,
        label: 'Category',
        value: 'category',
      },
      items: [
        {
          label: 'IMPORT',
          value: WO_CATEGORY_NAME?.IMPORT,
          isSelected:
            userFilter?.category?.[WO_CATEGORY_NAME?.IMPORT] !== undefined
              ? userFilter?.category?.[WO_CATEGORY_NAME?.IMPORT]
              : true,
          style: {
            width: '51px',
          },
          onClick: () => {
            setFilterState(prev =>
              toggleFilterSelection(prev, 'category', WO_CATEGORY_NAME?.IMPORT),
            );
          },
        },
        {
          label: 'EXPORT',
          value: WO_CATEGORY_NAME?.EXPORT,
          isSelected:
            userFilter?.category?.[WO_CATEGORY_NAME?.EXPORT] !== undefined
              ? userFilter?.category?.[WO_CATEGORY_NAME?.EXPORT]
              : true,
          style: {
            width: '51px',
          },
          onClick: () => {
            setFilterState(prev =>
              toggleFilterSelection(prev, 'category', WO_CATEGORY_NAME?.EXPORT),
            );
          },
        },
        {
          label: 'VAN',
          value: WO_CATEGORY_NAME?.VAN,
          isSelected:
            userFilter?.category?.WC0003 !== undefined
              ? userFilter?.category?.[WO_CATEGORY_NAME?.VAN]
              : true,
          style: {
            width: '34px',
          },
          onClick: () => {
            setFilterState(prev =>
              toggleFilterSelection(prev, 'category', WO_CATEGORY_NAME?.VAN),
            );
          },
        },
        {
          label: 'BROKERAGE',
          value: WO_CATEGORY_NAME?.BROKERAGE,
          isSelected:
            userFilter?.category?.[WO_CATEGORY_NAME?.BROKERAGE] !== undefined
              ? userFilter?.category?.[WO_CATEGORY_NAME?.BROKERAGE]
              : true,
          style: {
            width: '67px',
          },
          onClick: () => {
            setFilterState(prev =>
              toggleFilterSelection(
                prev,
                'category',
                WO_CATEGORY_NAME?.BROKERAGE,
              ),
            );
          },
        },
        {
          label: 'BARE CHASSIS',
          value: WO_CATEGORY_NAME?.BARE_CHASSIS,
          isSelected:
            userFilter?.category?.[WO_CATEGORY_NAME?.BARE_CHASSIS] !== undefined
              ? userFilter?.category?.[WO_CATEGORY_NAME?.BARE_CHASSIS]
              : true,
          style: {
            width: '84px',
          },
          onClick: () => {
            setFilterState(prev =>
              toggleFilterSelection(
                prev,
                'category',
                WO_CATEGORY_NAME?.BARE_CHASSIS,
              ),
            );
          },
        },
      ],
    },
  ];

  return initFilter;
};

export default generateInitFilter;
