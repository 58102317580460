import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import dailyReportKeys from '../keys';
import getDailySchedule from '../../../services/TmsReport/Schedules/Get/getDailySchedule';
import getURLSearchParams from '../../../utils/TmsReport/Schedule/Generator/getURLSearchParams';

const useGetDailySchedule = ({
  date,
  filterQuery,
  type,
  setErrorMessage,
  setIsErrorModalOpen,
}) => {
  const params = getURLSearchParams({ ...filterQuery, date });
  const pageCategory = document.URL.split('/')?.pop()?.split('?')?.[0];
  const result = useQuery({
    queryKey: dailyReportKeys.list({ ...filterQuery, date, type }),
    queryFn: () => getDailySchedule({ params, pageCategory }),
    refetchOnWindowFocus: false,
    enabled: !!date,
  });

  useEffect(() => {
    if (result?.isError) {
      setIsErrorModalOpen(true);
      setErrorMessage(result?.error?.message);
    }
  }, [result?.isError]);

  return result;
};

export default useGetDailySchedule;
