import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import AdvancedSearchModal from '../../../components/TmsReport/Statistics/Modal/AdvancedSearchModal';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import HeaderButtons from '../../../components/TmsReport/Statistics/HeaderButtons';
import PeriodNavigator from '../../../components/TmsReport/Statistics/PeriodNavigator';
import StatTable from '../../../components/TmsReport/Statistics/StatTable';
import StyledStatAreaWrapper from '../../../styles/TmsReport/Statistics/StyledStatAreaWrapper';
import useGetStatisticsList from '../../../hooks/TmsReport/Statistics/useGetStatisticsList';
import {
  SORTED_DIVISION,
  STATISTICS_TABLE_TITLE_VALUE,
} from '../../../constants/TmsReport/Statistics/statisticsValues';
import useControlStatisticsList from '../../../hooks/TmsReport/Statistics/useControlStatisticsList';
import generateInitFilter from '../../../utils/TmsReport/Statistics/Generator/generateInitFilter';
import { generateSpecificFilter } from '../../../utils/TmsReport/Common/Generator/filterStateGenerator';

const Statistics = () => {
  /** Sorted Division */
  const divList = useSelector(state => state?.common?.allDiv);

  /** Search */
  const [cycle, setCycle] = useState('weekly');
  const [dateQuery, setDateQuery] = useState({
    date: dayjs()?.format('YYYY-MM-DD'),
  });
  const [divQuery, setDivQuery] = useState({});

  /** Advanced Search Modal */
  const [isOpenAdvancedSearch, setIsOpenAdvancedSearch] = useState(false);

  /** Error Modal */
  const [errorMessage, setErrorMessage] = useState(null);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);

  /** Filter State */
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [filterState, setFilterState] = useState(null);

  /** Set Up Sorted Division List */
  useEffect(() => {
    if (divList?.length > 0) {
      const sortedDivList = [
        ...SORTED_DIVISION?.filter(item => divList?.includes(item)),
        ...divList?.filter(item => !SORTED_DIVISION?.includes(item)),
      ];

      const initFilterState = generateInitFilter({
        setFilterState,
        divList: sortedDivList,
      });

      setFilterState(initFilterState);
    }
  }, [divList]);

  useEffect(() => {
    const newDivQuery = generateSpecificFilter({
      filterState,
      specificKey: 'div',
    });
    setDivQuery(newDivQuery);
  }, [filterState]);

  const filterProps = {
    isOpenFilter,
    setIsOpenFilter,
    filterState,
    setFilterState,
  };

  const { statisticsList, isLoading } = useGetStatisticsList({
    cycle,
    dateQuery,
    setErrorMessage,
    setIsOpenErrorModal,
  });

  const { filteredStatisticsList } = useControlStatisticsList({
    statisticsList,
    divQuery,
  });

  const excelProps = {
    cycle,
    filteredStatisticsList,
    currentDate: dateQuery?.date,
  };

  return (
    <>
      <div className="flex flex-col pr-[40px] h-full">
        <HeaderButtons
          setIsOpenAdvancedSearch={setIsOpenAdvancedSearch}
          dateQuery={dateQuery}
          {...filterProps}
          {...excelProps}
        />
        <PeriodNavigator
          cycle={cycle}
          dateQuery={dateQuery}
          setDateQuery={setDateQuery}
        />
        <StyledStatAreaWrapper>
          {Object?.keys(STATISTICS_TABLE_TITLE_VALUE)?.map(title => {
            const isShow = !(title === 'empty-load' && !divQuery?.PHX);
            const tableData = filteredStatisticsList?.[title] || {};
            return (
              isShow && (
                <StatTable
                  key={title}
                  tableTitle={title}
                  tableData={tableData}
                  cycle={cycle}
                  dateQuery={dateQuery}
                />
              )
            );
          })}
        </StyledStatAreaWrapper>
      </div>
      <CommonLoading open={isLoading} />
      <AdvancedSearchModal
        open={isOpenAdvancedSearch}
        setIsOpen={setIsOpenAdvancedSearch}
        cycle={cycle}
        setCycle={setCycle}
        dateQuery={dateQuery}
        setDateQuery={setDateQuery}
      />
      <CommonErrorModal
        open={isOpenErrorModal}
        setIsOpen={setIsOpenErrorModal}
        message={errorMessage}
      />
    </>
  );
};

export default Statistics;
