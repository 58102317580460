export const EDITABLE = 'editable';
export const SAVE = 'save';
export const APPLY_TYPE = {
  OVERWRITE: 'overwrite',
  SKIP: 'skip',
};

export const DUPLICATE_ERROR = 'DuplicateError';
export const NOT_EDITABLE_ERROR = 'not_editable';

export const DUPLICATE_FIELD_LIST = [
  { label: 'CONT #', value: 'cont_no' },
  { label: 'CHASSIS #', value: 'ch_no' },
  { label: 'INV #', value: 'inv_no' },
];

export const SCAN_FILE_INPUT_NAME = 'scanFile';

export const NULL_PARAMETER_VALUE = '';

export const NOT_NULL_FIELDS_CHASSIS_RECONCILE = [
  'div',
  'pool',
  'ch_no',
  'status',
  'wo_form',
  'wo_to',
  'inv_no',
  'inv_date',
  'inv_from',
  'inv_to',
  'inv_amt',
];

export const NOT_NULL_FIELDS_PER_DIEM_RECONCILE = [
  'div',
  'ch_no',
  'ssl',
  'status',
  'wo',
  'inv_no',
  'inv_date',
  'inv_from',
  'inv_to',
  'inv_amt',
];

export const SAVE_TYPE = {
  ONE: 'one',
  ALL: 'all',
};

export const MODAL_STYLES = { width: '440px', height: '220px' };

export const ACCT_PAGE = {
  unInvoiced: 'unInvoiced',
  qbAPAR: 'qbAPAR',
  qbReconcile: 'qbReconcile',
  chassisReconcile: 'chassisReconcile',
  perDiemReconcile: 'perDiemReconcile',
};

export const ACCT_EXCEL_FILE_NAMES = {
  [ACCT_PAGE.unInvoiced]: 'APAR UNINVOICED',
  [ACCT_PAGE.qbAPAR]: 'QB APAR',
  [ACCT_PAGE.qbReconcile]: 'APAR RECONCILE',
  [ACCT_PAGE.chassisReconcile]: 'CHASSIS RECONCILE',
  [ACCT_PAGE.perDiemReconcile]: 'PERDIEM RECONCILE',
};

export const ACCT_QUERY_KEY_NAMES = {
  [ACCT_PAGE?.qbAPAR]: 'qbAPAR',
  [ACCT_PAGE?.qbReconcile]: 'apARReconcile',
  [ACCT_PAGE?.chassisReconcile]: 'chassisReconcileList',
  [ACCT_PAGE?.perDiemReconcile]: 'perdiemReconcileList',
};
