import getURLSearchParams from '../Schedule/Generator/getURLSearchParams';

const openDailyReport = ({ page, date, filterQuery }) => {
  const stringParams = getURLSearchParams({ ...filterQuery })?.toString();
  window.location.href = `/daily-report/${page}?date=${date}${
    stringParams && `&${stringParams}`
  }`;
};

export default openDailyReport;
