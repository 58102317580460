import styled from 'styled-components';

const StyledDocumentPageWrapper = styled.div`
  width: 1920px;
  padding: 51px 72px 31px 72px;

  display: flex;
  flex-direction: column;

  background-color: #ffffff;

  @media (max-width: 960px) {
    width: 960px;
    padding-right: 30px;
    padding-left: 30px;
  }
`;

export default StyledDocumentPageWrapper;
