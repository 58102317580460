export const EQUIP_WO_STATUS_MAPPINGS = {
  import: { label: 'CONT #', valueField: 'cont_no' },
  export: { label: 'CONT #', valueField: 'cont_no' },
  van: { label: 'TRAILER #', valueField: 'trailer_no' },
  brokerage: { label: 'EQUIPMENT', valueField: 'trailer_no' },
  'bare-chassis': { label: 'CHASSIS #', valueField: 'ch_no' },
};

export const PREVIEW_STATUS = {
  NO_SELECTED_DOC: 'no_selected_document',
  NO_UPLOAD_DOC: 'no_uploaded_document',
  NO_PDF_FILE: 'no_pdf_file',
  AVAILABLE_PREVIEW: 'available_preview',
};
