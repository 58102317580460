import React from 'react';
import CommonButton from '../../../CommonButton/CommonButton';
import handleExcelClick from '../../../../utils/TmsReport/Statistics/Handler/handleExcelClick';
import CommonFilter from '../../../Common/CommonFilter';
import saveFilter from '../../../../utils/Common/CommonFilter/saveFilter';

const HeaderButtons = ({
  setIsOpenAdvancedSearch,
  dateQuery,
  isOpenFilter,
  setIsOpenFilter,
  filterState,
  ...props
}) => {
  return (
    <div className="flex justify-between my-[20px]">
      <div className="flex justify-center items-center text-[12px] font-[500] text-[#666]">
        Search date: {dateQuery?.date}
      </div>
      <div className="flex gap-x-[5px] justify-end items-center">
        <CommonButton
          category="greySearch"
          onClick={() => {
            setIsOpenAdvancedSearch(true);
          }}
          border="0"
          backgroundColor="transparent"
          color="#666666"
        >
          Search
        </CommonButton>
        <div className="flex h-[14px] border-l-[0.4px] border-[#00000099] self-center" />

        <CommonFilter
          open={isOpenFilter}
          setOpen={setIsOpenFilter}
          filters={filterState}
          onSave={() =>
            saveFilter({
              storageName: 'statisticsFilter',
              filters: filterState,
              setOpen: setIsOpenFilter,
            })
          }
        />
        <CommonButton
          category="excel"
          onClick={() => handleExcelClick({ ...props })}
        >
          EXCEL
        </CommonButton>
      </div>
    </div>
  );
};

export default HeaderButtons;
