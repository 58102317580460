import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import CommonButton from '../../../CommonButton/CommonButton';
import searchQBContent from '../../../../utils/Acct/Common/Handler/searchQBContent';

const AdvancedSearchModalButton = ({
  setIsOpenModal,
  setQueryObject,
  errorModalOptions,
  queryObject,
  page,
}) => {
  const { handleSubmit } = useFormContext();
  const queryClient = useQueryClient();
  return (
    <div className="flex justify-center items-center gap-x-[15px] ">
      <div>
        <CommonButton category="cancel" onClick={() => setIsOpenModal?.(false)}>
          Cancel
        </CommonButton>
      </div>
      <div>
        <CommonButton
          type="button"
          category="search"
          onClick={() => {
            searchQBContent({
              handleSubmit,
              errorModalOptions,
              setQueryObject,
              setIsOpenModal,
              queryClient,
              queryObject,
              page,
            });
          }}
        >
          Search
        </CommonButton>
      </div>
    </div>
  );
};

export default AdvancedSearchModalButton;
