import {
  DISPATCH_PAGE_EXPORT_AVAILABLE,
  DISPATCH_PAGE_EXPORT_DELIVERY,
  DISPATCH_PAGE_EXPORT_RETURN,
  DISPATCH_PAGE_EXPORT_UNAVAILABLE,
  DISPATCH_PAGE_IMPORT_AVAILABLE,
  DISPATCH_PAGE_IMPORT_DELIVERY,
  DISPATCH_PAGE_IMPORT_RETURN,
} from '../../../../constants/Dispatch/Common/category';
import { LOCATION_TAB_FILTERS } from '../../../../constants/Dispatch/Common/filterButtonSettings';

const getYardQueries = ({ yardQueryObject, subFilter, status, isCounter }) => {
  const dispatchFilter = localStorage.getItem('dispatchFilter');
  const userID = localStorage.getItem('userId');
  const yardFilter = JSON.parse(dispatchFilter)?.[userID];
  const thisPage = document.location.pathname.split('/')[3];

  if (isCounter) {
    subFilter = [
      DISPATCH_PAGE_IMPORT_DELIVERY,
      DISPATCH_PAGE_EXPORT_DELIVERY,
    ]?.includes(status)
      ? 'cnee'
      : 'yard';
  }

  if (!LOCATION_TAB_FILTERS?.includes(subFilter)) return {};
  if (
    status !== undefined &&
    ![
      DISPATCH_PAGE_IMPORT_AVAILABLE,
      DISPATCH_PAGE_IMPORT_DELIVERY,
      DISPATCH_PAGE_IMPORT_RETURN,
      DISPATCH_PAGE_EXPORT_UNAVAILABLE,
      DISPATCH_PAGE_EXPORT_AVAILABLE,
      DISPATCH_PAGE_EXPORT_DELIVERY,
      DISPATCH_PAGE_EXPORT_RETURN,
    ].includes(status)
  )
    return {};

  const yardQueries = {};

  if (subFilter === 'cnee') {
    yardQueries.yard = true;
    return yardQueries;
  }
  yardQueries.yard = [
    ...Object.keys(yardQueryObject).filter(yard => yardQueryObject[yard]),
  ];

  const yardStatusFilter = yardFilter?.[status?.replace('-', '_')];

  if (['unava']?.includes(status?.split('_')?.[1])) {
    yardQueries['una-yard'] = [
      ...(yardStatusFilter
        ? Object.keys(yardStatusFilter).filter(yard => yardStatusFilter[yard])
        : Object.keys(yardQueryObject).filter(yard => yardQueryObject[yard])),
    ];
  } else if (['ava']?.includes(status?.split('_')?.[1])) {
    yardQueries['ava-yard'] = [
      ...(yardStatusFilter
        ? Object.keys(yardStatusFilter).filter(yard => yardStatusFilter[yard])
        : Object.keys(yardQueryObject).filter(yard => yardQueryObject[yard])),
    ];
  } else if (['rtn']?.includes(status?.split('_')?.[1])) {
    yardQueries['rtn-yard'] = [
      ...(yardStatusFilter
        ? Object.keys(yardStatusFilter).filter(yard => yardStatusFilter[yard])
        : Object.keys(yardQueryObject).filter(yard => yardQueryObject[yard])),
    ];
  }

  if (thisPage?.includes(['unavailable'])) {
    yardQueries['una-yard'] = [
      ...Object.keys(yardQueryObject).filter(yard => yardQueryObject[yard]),
    ];
  } else if (thisPage?.includes(['return'])) {
    yardQueries['rtn-yard'] = [
      ...Object.keys(yardQueryObject).filter(yard => yardQueryObject[yard]),
    ];
  } else if (thisPage?.includes(['available'])) {
    yardQueries['ava-yard'] = [
      ...Object.keys(yardQueryObject).filter(yard => yardQueryObject[yard]),
    ];
  }

  return yardQueries;
};

export default getYardQueries;
