import axios from 'axios';

/**
 * Common base url
 */
const TERMINAL_BASE_URL = `${process.env.REACT_APP_TERMINAL_URL}/`;

/**
 * Axios object with base url
 */
const terminalAPI = axios.create({
  withCredentials: false,
  baseURL: TERMINAL_BASE_URL,
});

export default terminalAPI;
