import React, { useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import CommonButton from '../../../../CommonButton/CommonButton';
import CommonFile from '../../../../Inputs/CommonFile/CommonFile';
import CommonTextInput from '../../../../SecondInputs/CommonTextInput/CommonTextInput';
import handleRowAdd from '../../../../../utils/BCDetail/Document/Handler/handleRowAdd';
import CommonRowHeader from '../../../../SecondInputs/CommonRowHeader.jsx/CommonRowHeader';
import StyledDisabledField from '../../../../../styles/BCDetail/Document/StyledDisabledField';
import CommonCheckbox from '../../../../Inputs/CommonCheckbox/CommonCheckbox';
import StyledFieldWrapper from '../../../../../styles/BCDetail/Document/StyledFieldWrapper';
import { DOCUMENT_COLUMN_STYLES } from '../../../../../constants/BCDetail/Document/documentTableElements';

const DocumentAddRow = ({ id, isEditable, windowWidth, ...props }) => {
  const [fileURL, setFileURL] = useState('');

  const { handleSubmit, resetField, setValue } = useFormContext();

  const buttonRef = useRef(null);

  const watchType = useWatch({ name: `${id}.type` });
  const watchFile = useWatch({ name: `file.${id}.newRow` });

  useEffect(() => {
    if (watchFile) {
      setFileURL(URL.createObjectURL(watchFile));
    } else if (watchFile === null) {
      setValue(`${id}.file_url`, '');
      setFileURL('');
    }
  }, [watchFile]);

  return (
    <div className="flex items-center gap-x-[2px] w-full">
      {/* Date */}
      <StyledFieldWrapper {...DOCUMENT_COLUMN_STYLES?.DATE} isCursor={false}>
        <CommonRowHeader content="MM/DD/YY HH:mm" disabledTheme />
      </StyledFieldWrapper>

      {/* Document */}
      <StyledFieldWrapper
        {...DOCUMENT_COLUMN_STYLES?.DOCUMENT}
        isCursor={false}
      >
        <CommonTextInput
          backgroundColor="#DDE2EC"
          inputName={`${id}.type`}
          disabled={!isEditable}
          onChangeHandler={({ e, onChange }) => {
            onChange(e?.target?.value);
          }}
        />
      </StyledFieldWrapper>

      {/* Updated by */}
      <StyledFieldWrapper
        {...DOCUMENT_COLUMN_STYLES?.['UPDATED BY']}
        isCursor={false}
      >
        <StyledDisabledField className="bg-[#CCD3E3]" />
      </StyledFieldWrapper>

      {/* Verif */}
      <StyledFieldWrapper {...DOCUMENT_COLUMN_STYLES?.VERIF} isCursor={false}>
        <StyledDisabledField className="bg-[#CCD3E3]" />
      </StyledFieldWrapper>

      {/* CK */}
      <StyledFieldWrapper
        {...DOCUMENT_COLUMN_STYLES?.CK}
        bgColor="#DDE2EC"
        isCursor={false}
      >
        <CommonCheckbox
          inputName={`${id}.checkbox`}
          labelVisible={false}
          options={[
            {
              defaultValue: false,
              inputName: `${id}.checked`,
            },
          ]}
          onChangeHandler={({ e, onChange }) => {
            onChange(e?.target?.checked);
          }}
          disabled={!isEditable}
        />
      </StyledFieldWrapper>

      {/* Browse */}
      <StyledFieldWrapper {...DOCUMENT_COLUMN_STYLES?.BROWSE}>
        <CommonFile
          inputName={`file.${id}.newRow`}
          labelVisible={false}
          disabled={!watchType}
        />
      </StyledFieldWrapper>

      <StyledFieldWrapper {...DOCUMENT_COLUMN_STYLES?.SAVE}>
        <CommonButton
          ref={buttonRef}
          category="save"
          disabled={!watchType}
          type="button"
          className={`w-full h-full  flex items-center 
                          ${!watchType && ' border-[#DADADA] bg-[#DADADA]'}`}
          onClick={() => {
            handleSubmit(submitData =>
              handleRowAdd({ submitData, resetField, id, buttonRef, ...props }),
            )?.();
          }}
        >
          {windowWidth > 960 && (
            <div className="pt-[1px] pr-[4px] w-full">SAVE</div>
          )}
        </CommonButton>
      </StyledFieldWrapper>
    </div>
  );
};

export default DocumentAddRow;
