import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import useGetColumnDefs from '../../hooks/Common/DataGrid/useGetColumnDefs';
import useGetGroupColBgColor from '../../hooks/Common/DataGrid/useGetGroupColBgColor';
import useGetGroupExpandIcon from '../../hooks/Common/DataGrid/useGetGroupColExpandIcon';
import useGetGroupTextColor from '../../hooks/Common/DataGrid/useGetGroupColTextColor';
import useGetRowData from '../../hooks/Common/DataGrid/useGetRowData';
import StyledCommonDatagrid from '../../styles/Datagrid/StyledCommonDatagrid';
import gridOptionsGenerator from '../../utils/Common/Datagrid/Generator/gridOptionsGenerator';
import judgeDisableCellClickHandler from '../../utils/Common/Datagrid/Handler/judgeDisableCellClickHandler';
import judgeDisableRowClickHandler from '../../utils/Common/Datagrid/Handler/judgeDisableRowClickHandler';

/** Import ag-grid css according to reference */
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import useGetExcelStyles from '../../hooks/Common/DataGrid/useGetExcelStyle';
import StyledDataGridWrapper from '../../styles/Common/CommonDatagrid/DataGridWrapper/StyledDataGridWrapper';
import handleCellFocused from '../../utils/Common/Datagrid/Handler/handleCellFocused';
import handleCellKeyDown from '../../utils/Common/Datagrid/Handler/handleCellKeyDown';
import handleTabToNextCell from '../../utils/Common/Datagrid/Handler/handleTabToNextCell';

/**
 * @description
 * Common datagrid component for all pages
 * @param {object} data
 * Data to be shown in the grid
 */
const CommonDataGrid = ({
  gridRef: userDefinedGridRef,
  data,
  columnDefs: paramColumnDefs,
  defaultColDef: paramDefaultColDef,
  defaultColGroupDef: paramDefaultColGroupDef,
  columnTypes: paramColumnTypes,
  onRowClicked,
  onCellClicked,
  onCellDoubleClicked,
  onSaveClicked,
  onDeleteClicked,
  onAddClicked,
  getContextMenuItems,
  modType,
  addable,
  foldable,
  foldableContent,
  enableCellClickColumn,
  ...props
}) => {
  /** Grid ref */
  const gridRef = useRef(null);
  const [isGridReady, setIsGridReady] = useState(false);

  /** Row to add new data into data grid */
  const pinnedTopRowData = useMemo(() => {
    if (addable) return [{}];
    return null;
  }, []);

  const [columnDefs, setColumnDefs] = useState(paramColumnDefs);
  useEffect(() => {
    setColumnDefs(paramColumnDefs);
  }, [paramColumnDefs]);

  /** Data to be shown in the grid. */
  const { rowData } = useGetRowData({ data });

  /** Setting Grouping Column backgroundColor */
  const { bgColor } = useGetGroupColBgColor({
    columnDefs: paramColumnDefs,
  });

  /** Setting Grouping Column textColor */
  const { textColor } = useGetGroupTextColor({
    columnDefs: paramColumnDefs,
  });

  /** Setting Grouping Column expand icon */
  const { icon } = useGetGroupExpandIcon({ columnDefs: paramColumnDefs });

  /** Setting Excel Export */
  const { excelStyles } = useGetExcelStyles();

  /**
   * Column definitions of the grid.
   * Use useMemo because this value doesn't change.
   */
  const gridColumnDefs = useGetColumnDefs({
    paramColumnDefs: columnDefs,
    paramDefaultColDef,
    paramDefaultColGroupDef,
    paramColumnTypes,
    onDeleteClicked,
    onAddClicked,
    modType,
    addable,
    foldable,
    ...props,
  });

  /** Row click handler: Apply useCallback hook */
  const onRowClickedHandler = useCallback(
    gridParam => {
      if (judgeDisableRowClickHandler({ gridParam })) {
        onRowClicked && onRowClicked({ gridRef, gridParam, ...props });
      }
    },
    [onRowClicked],
  );

  /** Cell click handler: Apply useCallback hook */
  const onCellClickHandler = useCallback(
    gridParam => {
      if (judgeDisableCellClickHandler({ gridParam, enableCellClickColumn })) {
        onCellClicked && onCellClicked({ gridRef, gridParam, ...props });
      }
    },
    [onCellClicked],
  );

  /** Cell double click handler: Apply useCallback hook */
  const onCellDoubleClickHandler = useCallback(
    gridParam => {
      handleCellFocused({ gridParam });

      if (gridParam.column?.colDef?.editable) {
        gridParam.api.startEditingCell({
          rowIndex: gridParam.rowIndex,
          colKey: gridParam.column.colId,
        });
      }
    },
    [onCellDoubleClicked],
  );

  /**
   * Set grid option \w setting property.
   * @see https://www.ag-grid.com/react-data-grid/grid-options/
   */
  const gridOptions = gridOptionsGenerator({
    gridColumnDefs,
    onSaveClicked,
    getContextMenuItems,
    undoRedoCellEditing: true,
    undoRedoCellEditingLimit: 1,
    ...props,
  });

  setTimeout(() => {
    setIsGridReady(true);
  }, 100);

  /** Render Datagrid */
  return (
    <StyledDataGridWrapper className="ag-theme-alpine">
      {isGridReady && (
        <StyledCommonDatagrid
          key={gridOptions}
          {...props}
          /** Group column backgroundColor */
          background={bgColor}
          /** Group column textColor */
          color={textColor}
          /** Group column icon */
          icon={icon}
          /** Grid Reference */
          ref={userDefinedGridRef || gridRef}
          /** Grid data */
          rowData={rowData}
          /** Grid options */
          gridOptions={gridOptions}
          /** Row click handler */
          onRowClicked={onRowClickedHandler}
          /** Cell click handler */
          onCellClicked={onCellClickHandler}
          /** Cell double click handler */
          onCellDoubleClicked={onCellDoubleClickHandler}
          /** Column definition */
          columnDefs={gridOptions?.columnDefs || []}
          /** Row that add new data into grid */
          pinnedTopRowData={pinnedTopRowData}
          defaultColGroupDef={{
            marryChildren: true,
          }}
          /** Expanded row setting */
          masterDetail={foldable}
          detailCellRenderer={foldableContent}
          onCellKeyDown={gridParam =>
            handleCellKeyDown({ gridParam, onAddClicked })
          }
          tabToNextCell={gridParam => handleTabToNextCell({ gridParam })}
          onCellFocused={gridParam => handleCellFocused({ gridParam })}
          /* Excel Export */
          excelStyles={excelStyles}
        />
      )}
    </StyledDataGridWrapper>
  );
};

export default CommonDataGrid;
