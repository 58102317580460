import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import CommonButton from '../../../CommonButton/CommonButton';
import CommonSelect from '../../../Inputs/CommonSelect/CommonSelect';
import CommonTextInput from '../../../Inputs/CommonTextInput/CommonTextInput';
import CommonDate from '../../../Inputs/CommonDate/CommonDate';
import { STATUS_CHECK_BOX_OPTION } from '../../../../constants/Acct/Common/selectOptions';
import CommonCheckbox from '../../../Inputs/CommonCheckbox/CommonCheckbox';
import {
  changeCheckBox,
  changeDate,
  changeSelect,
  changeText,
} from '../../../../utils/Acct/Common/Handler/changedInputFieldHandler';
import { generateStatusOption } from '../../../../utils/Acct/Common/Generator/optionGenerator';
import { HYPEN_ICON } from '../../../../constants/Acct/Common/iconPath';
import searchReconcileContent from '../../../../utils/Acct/Common/Handler/searchReconcileContent';
import createEmptySelect from '../../../../utils/Common/Option/createEmptySelect';

const SearchModalContent = ({
  setOpenModal,
  queryObject,
  poolList,
  setQueryObject,
  setErrorModalOptions,
}) => {
  const { handleSubmit } = useFormContext();
  const queryClient = useQueryClient();

  return (
    <>
      <div className="bg-[#F9F9F9] w-[488px] h-[210px] px-[30px] py-[15px] flex flex-col">
        <div className="w-full flex flex-row gap-x-[10px] justify-between">
          <CommonCheckbox
            alignCenter
            helpTextVisible={false}
            inputName="status"
            label="STATUS"
            onChangeHandler={changeCheckBox}
            options={generateStatusOption({
              statusOptions: STATUS_CHECK_BOX_OPTION,
              queryObject,
            })}
          />
        </div>
        <div className="w-full flex flex-row gap-x-[20px] mt-[17px]">
          <div className="w-[153px]">
            <CommonSelect
              label="POOL"
              option={createEmptySelect({
                selectOption: poolList,
                emptyValue: '',
              })}
              inputName="pool"
              defaultValue={queryObject?.pool}
              onChangeHandler={changeSelect}
            />
          </div>
          <div className="w-[153px]">
            <CommonTextInput
              label="CHASSIS #"
              inputName="chassis"
              placeholder="Type here..."
              defaultValue={queryObject?.chassis}
              onChangeHandler={changeText}
            />
          </div>
        </div>
        <div className="w-full flex flex-row gap-x-[10px] mt-[9px]">
          <div className="w-[116px]">
            <CommonDate
              required
              inputName="from"
              label="INV DATE"
              defaultValue={queryObject?.from}
              onChangeHandler={changeDate}
            />
          </div>
          <div className="flex items-center">
            <img src={HYPEN_ICON} alt="hyphen-icon" width={13} />
          </div>
          <div className="w-[116px]">
            <CommonDate
              required
              inputName="to"
              defaultValue={queryObject?.to}
              onChangeHandler={changeDate}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-[23px]">
        <div className="mr-[18px]">
          <CommonButton category="cancel" onClick={() => setOpenModal?.(false)}>
            Cancel
          </CommonButton>
        </div>
        <CommonButton
          type="button"
          category="search"
          onClick={() =>
            searchReconcileContent({
              handleSubmit,
              setQueryObject,
              setOpenModal,
              setErrorModalOptions,
              queryObject,
              queryClient,
              pageType: 'chassisReconcile',
            })
          }
        >
          Search
        </CommonButton>
      </div>
    </>
  );
};

export default SearchModalContent;
