import updateButtonDisabled from '../../../Common/Handler/updateButtonDisabled';

const handleAllSave = async ({
  handleSubmit,
  woNo,
  documentMutator,
  buttonRef,
  ...props
}) => {
  await handleSubmit(submitData => {
    const woCategory = location?.pathname?.split('/')?.[3];

    const changedDocList = [];
    if (buttonRef?.current)
      updateButtonDisabled({ ref: buttonRef, disabled: true });

    const docData = {};
    const newList = [];
    const fileData = submitData?.file;

    Object.entries(submitData).forEach(([id, data]) => {
      if (id === '0') return;
      if (id === 'file') return;
      newList.push(data);
    });

    Object.entries(fileData).forEach(([key, file]) => {
      const type = submitData[key].type;
      if (type) {
        if (file[type]) {
          changedDocList.push(type);
          docData[type] = file?.[type];
        } else if (file[type] === null) {
          const tempSubmitData = { ...submitData[key], accuracy: null };
          newList[Number(key) - 1] = tempSubmitData;
        }
      }
    });

    docData.doc = JSON.stringify(newList);

    const doBinary = docData?.DO;
    const doURL = Object.values(submitData).filter(
      ({ type }) => type === 'DO',
    )[0]?.file_url;

    if (woCategory !== 'export' && doURL === '' && doBinary === undefined) {
      props?.setMessageModalOpen(true);
      props?.setMessageContent('Please upload DO.');
      updateButtonDisabled({ ref: buttonRef, disabled: false });
      return;
    }

    documentMutator({
      woNo,
      docData,
      buttonRef,
      changedDocList,
    });
  })?.();
};

export default handleAllSave;
