import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import usePostARData from '../../../../../hooks/BCDetail/BillingInfo/AR/usePostARData';
import StyledBCDetailBillingInfoCommonHeaderCell from '../../../../../styles/BCDetail/BillingInfo/Common/TableHeader/StyledBCDetailBillingInfoCommonHeaderCell';
import StyledBCDetailBillingInfoCommonHeaderCellTitle from '../../../../../styles/BCDetail/BillingInfo/Common/TableHeader/StyledBCDetailBillingInfoCommonHeaderCellTitle';
import StyledBCDetailBillingInfoCommonHeaderInputContainer from '../../../../../styles/BCDetail/BillingInfo/Common/TableHeader/StyledBCDetailBillingInfoCommonHeaderInputContainer';
import StyledBCDetailBillingInfoCommonHeaderQBContainer from '../../../../../styles/BCDetail/BillingInfo/Common/TableHeader/StyledBCDetailBillingInfoCommonHeaderQBContainer';
import StyledBCDetailBillingInfoCommonHeaderRow from '../../../../../styles/BCDetail/BillingInfo/Common/TableHeader/StyledBCDetailBillingInfoCommonHeaderRow';
import onARSubmitHandler from '../../../../../utils/BCDetail/BillingInfo/AR/Handler/onARSubmitHandler';
import onDateInputDefaultChangeHandler from '../../../../../utils/BCDetail/Common/Handler/onDateInputDefaultChangeHandler';
import onInputDefaultChangeHandler from '../../../../../utils/BCDetail/Common/Handler/onInputDefaultChangeHandler';
import {
  createCompanySelectOption,
  createProductCodeSelectOption,
} from '../../../../../utils/Common/createCodeSelectOption';
import ARHeaderDate from '../../../../BCDetail/BillingInfo/ARHeaderDate';
import Input from '../../../../BCDetail/BillingInfo/Input';
import CommonButton from '../../../../CommonButton/CommonButton';
import CommonAutoComplete from '../../../../SecondInputs/CommonAutoComplete/CommonAutoComplete';
import CommonCheckbox from '../../../../SecondInputs/CommonCheckbox/CommonCheckbox';

const ARTableFixedHeader = () => {
  return (
    <StyledBCDetailBillingInfoCommonHeaderRow>
      <StyledBCDetailBillingInfoCommonHeaderCellTitle width="108px" />
      <StyledBCDetailBillingInfoCommonHeaderCellTitle width="85px">
        ID
      </StyledBCDetailBillingInfoCommonHeaderCellTitle>
      <StyledBCDetailBillingInfoCommonHeaderCellTitle width="177px">
        NAME
      </StyledBCDetailBillingInfoCommonHeaderCellTitle>
      <StyledBCDetailBillingInfoCommonHeaderCellTitle width="179px">
        ACCT CODE
      </StyledBCDetailBillingInfoCommonHeaderCellTitle>
      <StyledBCDetailBillingInfoCommonHeaderCellTitle width="90px">
        RATE
      </StyledBCDetailBillingInfoCommonHeaderCellTitle>
      <StyledBCDetailBillingInfoCommonHeaderCellTitle width="90px">
        QTY
      </StyledBCDetailBillingInfoCommonHeaderCellTitle>
      <StyledBCDetailBillingInfoCommonHeaderCellTitle width="90px">
        AR AMT
      </StyledBCDetailBillingInfoCommonHeaderCellTitle>
      <StyledBCDetailBillingInfoCommonHeaderCellTitle width="140px">
        DESC
      </StyledBCDetailBillingInfoCommonHeaderCellTitle>
      <StyledBCDetailBillingInfoCommonHeaderCellTitle width="90px">
        AR TOTAL
      </StyledBCDetailBillingInfoCommonHeaderCellTitle>
      <StyledBCDetailBillingInfoCommonHeaderCellTitle width="155px">
        INV #
      </StyledBCDetailBillingInfoCommonHeaderCellTitle>
      <StyledBCDetailBillingInfoCommonHeaderCellTitle width="30px">
        CK
      </StyledBCDetailBillingInfoCommonHeaderCellTitle>
      <StyledBCDetailBillingInfoCommonHeaderCellTitle width="80px">
        QB ID
      </StyledBCDetailBillingInfoCommonHeaderCellTitle>
      <StyledBCDetailBillingInfoCommonHeaderCellTitle width="80px">
        QB TOTAL
      </StyledBCDetailBillingInfoCommonHeaderCellTitle>
      <StyledBCDetailBillingInfoCommonHeaderCellTitle width="80px">
        QB RCVD AMT
      </StyledBCDetailBillingInfoCommonHeaderCellTitle>
      <StyledBCDetailBillingInfoCommonHeaderCellTitle width="80px">
        QB DATE
      </StyledBCDetailBillingInfoCommonHeaderCellTitle>
      <StyledBCDetailBillingInfoCommonHeaderCellTitle width="90px">
        UPDATED BY
      </StyledBCDetailBillingInfoCommonHeaderCellTitle>
    </StyledBCDetailBillingInfoCommonHeaderRow>
  );
};

const ARTableAddInputHeader = ({
  ARProductCodeQuery,
  ARCompanyList,
  setIsCreditHoldCompany,
  onARHeaderSubmitHandler,
  isAREditable,
  buttonRef,
  isSubmitting,
}) => {
  const { setValue, getValues } = useFormContext();
  const [companyName, setCompanyName] = useState('');

  useEffect(() => {
    if (!isSubmitting) {
      !getValues('add.company') && setCompanyName('');
    }
  }, [isSubmitting]);

  return (
    <StyledBCDetailBillingInfoCommonHeaderRow>
      <StyledBCDetailBillingInfoCommonHeaderCell width="108px">
        <StyledBCDetailBillingInfoCommonHeaderInputContainer backgroundColor="#ECDDDD">
          <ARHeaderDate
            width="121px"
            placeholder="MM/DD/YY"
            inputName="add.inv_date"
            disabled
            onChangeHandler={onDateInputDefaultChangeHandler}
            onARHeaderSubmitHandler={onARHeaderSubmitHandler}
          />
        </StyledBCDetailBillingInfoCommonHeaderInputContainer>
      </StyledBCDetailBillingInfoCommonHeaderCell>
      <StyledBCDetailBillingInfoCommonHeaderCell width="85px">
        <CommonAutoComplete
          inputName="add.company"
          backgroundColor="#ECDDDD"
          option={createCompanySelectOption(ARCompanyList?.companyList)}
          defaultValue={null}
          disabled={!isAREditable}
          onChangeHandler={({ e, onChange, ...props }) => {
            if (props.selectProps?.credit === true) {
              setIsCreditHoldCompany(true);
              setValue('add.company', null);
              setCompanyName('');
              return;
            }
            onChange?.(props.selectProps?.value);
            const getOptions = ARCompanyList?.companyList;
            setCompanyName(
              getOptions?.find(
                element =>
                  element?.company === props.selectProps?.value &&
                  element?.credit_hold !== true,
              )?.business_name,
            );
          }}
          isLoading={ARCompanyList?.isLoading}
        />
      </StyledBCDetailBillingInfoCommonHeaderCell>
      <StyledBCDetailBillingInfoCommonHeaderCell width="177px">
        <StyledBCDetailBillingInfoCommonHeaderInputContainer backgroundColor="#C7B9B9">
          <Input
            inputName="add.company_name"
            defaultValue={companyName}
            disabled
          />
        </StyledBCDetailBillingInfoCommonHeaderInputContainer>
      </StyledBCDetailBillingInfoCommonHeaderCell>
      <StyledBCDetailBillingInfoCommonHeaderCell width="179px">
        <CommonAutoComplete
          inputName="add.product_code"
          backgroundColor="#ECDDDD"
          maxLength={100}
          option={createProductCodeSelectOption(
            ARProductCodeQuery?.productCode,
          )}
          disabled={!isAREditable}
          onChangeHandler={({ e, onChange, ...props }) => {
            onChange?.(props.selectProps?.value);
          }}
          isLoading={ARProductCodeQuery?.isLoading}
        />
      </StyledBCDetailBillingInfoCommonHeaderCell>
      <StyledBCDetailBillingInfoCommonHeaderCell width="90px">
        <StyledBCDetailBillingInfoCommonHeaderInputContainer backgroundColor="#ECDDDD">
          <Input
            inputName="add.rate"
            dollarSign
            defaultValue=""
            textAlign="center"
            maxLength={14}
            isInt
            disabled={!isAREditable}
            onChangeHandler={({
              e,
              onChange,
              setText,
              setTextLength,
              maxTextLength,
              ...props
            }) => {
              const inputText = e?.target?.value;
              if (/^-?\d*(\.\d{0,2})?$/.test(inputText)) {
                if (inputText.length <= maxTextLength) {
                  setText(inputText);
                  onChange(inputText);
                  setTextLength(inputText.length);
                }
              }
            }}
            onKeyDownHandler={e => {
              if (e?.e?.key === 'Enter') onARHeaderSubmitHandler({ buttonRef });
            }}
          />
        </StyledBCDetailBillingInfoCommonHeaderInputContainer>
      </StyledBCDetailBillingInfoCommonHeaderCell>
      <StyledBCDetailBillingInfoCommonHeaderCell width="90px">
        <StyledBCDetailBillingInfoCommonHeaderInputContainer backgroundColor="#ECDDDD">
          <Input
            inputName="add.qty"
            defaultValue=""
            maxLength={10}
            isInt
            disabled={!isAREditable}
            onChangeHandler={({
              e,
              onChange,
              setText,
              setTextLength,
              maxTextLength,
              ...props
            }) => {
              const inputText = e?.target?.value;
              if (/^-?\d*(\.\d{0,2})?$/.test(inputText)) {
                if (inputText.length <= maxTextLength) {
                  setText(inputText);
                  onChange(inputText);
                  setTextLength(inputText.length);
                }
              }
            }}
            onKeyDownHandler={e => {
              if (e?.e?.key === 'Enter') onARHeaderSubmitHandler({ buttonRef });
            }}
          />
        </StyledBCDetailBillingInfoCommonHeaderInputContainer>
      </StyledBCDetailBillingInfoCommonHeaderCell>
      <StyledBCDetailBillingInfoCommonHeaderCell width="90px">
        <StyledBCDetailBillingInfoCommonHeaderInputContainer backgroundColor="#C7B9B9">
          <Input inputName="add.amt" dollarSign defaultValue="" disabled />
        </StyledBCDetailBillingInfoCommonHeaderInputContainer>
      </StyledBCDetailBillingInfoCommonHeaderCell>
      <StyledBCDetailBillingInfoCommonHeaderCell width="140px">
        <StyledBCDetailBillingInfoCommonHeaderInputContainer backgroundColor="#ECDDDD">
          <Input
            inputName="add.desc"
            defaultValue={null}
            maxLength={128}
            disabled={!isAREditable}
            onChangeHandler={onInputDefaultChangeHandler}
            onKeyDownHandler={e => {
              if (e?.e?.key === 'Enter') onARHeaderSubmitHandler({ buttonRef });
            }}
          />
        </StyledBCDetailBillingInfoCommonHeaderInputContainer>
      </StyledBCDetailBillingInfoCommonHeaderCell>
      <StyledBCDetailBillingInfoCommonHeaderCell width="90px">
        <StyledBCDetailBillingInfoCommonHeaderInputContainer backgroundColor="#C7B9B9">
          <Input
            inputName="add.total"
            dollarSign
            defaultValue=""
            maxLength={12}
            isInt
            disabled
          />
        </StyledBCDetailBillingInfoCommonHeaderInputContainer>
      </StyledBCDetailBillingInfoCommonHeaderCell>
      <StyledBCDetailBillingInfoCommonHeaderCell width="155px">
        <StyledBCDetailBillingInfoCommonHeaderInputContainer backgroundColor="#C7B9B9">
          <Input inputName="add.inv_no" disabled />
        </StyledBCDetailBillingInfoCommonHeaderInputContainer>
      </StyledBCDetailBillingInfoCommonHeaderCell>
      <StyledBCDetailBillingInfoCommonHeaderCell width="30px">
        <StyledBCDetailBillingInfoCommonHeaderInputContainer backgroundColor="#C7B9B9">
          <CommonCheckbox inputName="mod.checked" disabled />
        </StyledBCDetailBillingInfoCommonHeaderInputContainer>
      </StyledBCDetailBillingInfoCommonHeaderCell>
      <StyledBCDetailBillingInfoCommonHeaderCell width="80px">
        <StyledBCDetailBillingInfoCommonHeaderQBContainer />
      </StyledBCDetailBillingInfoCommonHeaderCell>
      <StyledBCDetailBillingInfoCommonHeaderCell width="80px">
        <StyledBCDetailBillingInfoCommonHeaderQBContainer />
      </StyledBCDetailBillingInfoCommonHeaderCell>
      <StyledBCDetailBillingInfoCommonHeaderCell width="80px">
        <StyledBCDetailBillingInfoCommonHeaderQBContainer />
      </StyledBCDetailBillingInfoCommonHeaderCell>
      <StyledBCDetailBillingInfoCommonHeaderCell width="80px">
        <StyledBCDetailBillingInfoCommonHeaderQBContainer />
      </StyledBCDetailBillingInfoCommonHeaderCell>
      <StyledBCDetailBillingInfoCommonHeaderCell width="90px">
        <StyledBCDetailBillingInfoCommonHeaderInputContainer backgroundColor="#C7B9B9" />
      </StyledBCDetailBillingInfoCommonHeaderCell>
      {isAREditable && (
        <StyledBCDetailBillingInfoCommonHeaderCell width="75.5px">
          <CommonButton
            ref={buttonRef}
            type="button"
            borderColor="#556DA1"
            category="add"
            preventDupClick
            onClick={onARHeaderSubmitHandler}
          >
            Add
          </CommonButton>
        </StyledBCDetailBillingInfoCommonHeaderCell>
      )}
    </StyledBCDetailBillingInfoCommonHeaderRow>
  );
};

const ARTableHeader = ({
  ARProductCodeQuery,
  ARCompanyList,
  setIsCreditHoldCompany,
  setIsAlertOpen,
  setIsSuccess,
  setErrorMessage,
  isAREditable,
  isSubmitting,
  setIsSubmitting,
  refetchARData,
}) => {
  const { handleSubmit, reset } = useFormContext();

  const { mutate: arAddMutator } = usePostARData({
    setIsAlertOpen,
    setIsSuccess,
    setErrorMessage,
    reset,
    setIsSubmitting,
    refetchARData,
  });

  const addRef = useRef(null);

  const onARHeaderSubmitHandler = handleSubmit(data => {
    if (isSubmitting) {
      updateButtonDisabled({ ref: addRef, disabled: false });
      return;
    }
    setIsSubmitting(true);

    onARSubmitHandler({
      handleSubmit,
      submitType: 'ADD',
      arMutator: arAddMutator,
      data,
      ARProductCodeQuery,
      setIsAlertOpen,
      setErrorMessage,
      setIsSuccess,
      buttonRef: addRef,
    });
  });

  return (
    <>
      <ARTableFixedHeader />
      <ARTableAddInputHeader
        ARProductCodeQuery={ARProductCodeQuery}
        ARCompanyList={ARCompanyList}
        setIsCreditHoldCompany={setIsCreditHoldCompany}
        onARHeaderSubmitHandler={() => handleSubmit(onARHeaderSubmitHandler)()}
        isAREditable={isAREditable}
        buttonRef={addRef}
        isSubmitting={isSubmitting}
      />
    </>
  );
};
export default ARTableHeader;
