import React, { useState } from 'react';
import { FormProvider, useWatch, useForm } from 'react-hook-form';
import StyledBottomBar from '../../../../styles/BCDetail/StyledBottomBar';
import EmailButtonComponent from './Component/EmailButtonComponent';
import usePatchDeleteWO from '../../../../hooks/BCDetail/DetailInfo/Common/usePatchDeleteWO';
import CommonErrorModal from '../../../CommonErrorModal/CommonErrorModal';
import WODeleteConfirmModal from './ModalContents/WODeleteConfirmModal';
import WOManageButtonComponent from './Component/WOManageButtonComponent';

const BottomBar = ({ detailInfoData, infoRefetch, isEditable, ...props }) => {
  const {
    basicInfoFormController,
    scheduleFormController,
    updateDetailInfo,
    isFormInvalid,
  } = props;

  /** Basic info data */
  const basicInfoData = props?.basicInfoFormController?.getValues();

  /** Schedule data */
  const scheduleData = props?.scheduleFormController?.getValues();

  /** WO */
  const woNo = detailInfoData?.wo_no;

  /** Modal message */
  const [message, setMessage] = useState('');
  const [deleteOpen, setDeleteOpen] = useState(false);

  /** Modal opener */
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [alertModalOpen, setAlertModalOpen] = useState(false);

  const modalFormController = useForm();

  const modalProps = {
    setMessage,
    setErrorModalOpen,
    setAlertModalOpen,
    setDeleteOpen,
  };

  const deleteWO = usePatchDeleteWO({ infoRefetch, ...modalProps });

  const manageProps = {
    woNo,
    detailInfoData,
    infoRefetch,
    scheduleData,
    basicInfoData,
    isFormInvalid,
    basicInfoFormController,
    scheduleFormController,
    updateDetailInfo,
  };

  return (
    <div>
      <StyledBottomBar>
        <EmailButtonComponent woNo={woNo} {...modalProps} />
        <WOManageButtonComponent
          isEditable={isEditable}
          {...manageProps}
          {...modalProps}
        />
      </StyledBottomBar>
      <FormProvider {...modalFormController}>
        <form>
          {deleteOpen && (
            <WODeleteConfirmModal
              deleteOpen={deleteOpen}
              setDeleteOpen={setDeleteOpen}
              woNo={woNo}
              deleteWO={deleteWO}
            />
          )}
        </form>
        {/* Error Modal */}
        {errorModalOpen && (
          <CommonErrorModal
            open={errorModalOpen}
            setIsOpen={setErrorModalOpen}
            message={message}
          />
        )}
      </FormProvider>
    </div>
  );
};

export default BottomBar;
