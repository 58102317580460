import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import CommonCustomColumnModal from '../../../components/CommonCustomColumnModal';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import AdvancedSearchModal from '../../../components/CustomerReport/AdvancedSearchModal';
import ReportDataGrid from '../../../components/CustomerReport/ReportDataGrid';
import SavedListModal from '../../../components/CustomerReport/SavedListModal';
import SaveItemModal from '../../../components/CustomerReport/SaveItemModal';
import TopUtilityBar from '../../../components/CustomerReport/TopUtilityBar';
import useGetLoadingOpener from '../../../hooks/CustomerReport/useGetLoadingOpener';
import useGetMainData from '../../../hooks/CustomerReport/useGetMainData';
import usePostMain from '../../../hooks/CustomerReport/usePostMain';
import usePostSaved from '../../../hooks/CustomerReport/usePostSaved';
import StyledCustomerReportWrapper from '../../../styles/CustomerReport/Common/StyledCustomerReportWrapper';
import createCustomerReportColDefs from '../../../utils/CustomerReport/Generator/createCustomerReportColDefs';
import generateCustomizedColumn from '../../../utils/CustomerReport/Generator/generateCustomizedColumn';
import generateReportGridColumnDefinition from '../../../utils/CustomerReport/Generator/generateReportGridColumnDefinition';
import filterColumnDefinition from '../../../utils/CustomerReport/Handler/filterColumnDefiniton';
import setQueryParameter from '../../../utils/CustomerReport/Handler/setQueryParameter';
import TotalBottomBar from '../../../components/Common/CommonTotalBottomBar';
import useControlTotalInfo from '../../../hooks/Common/TotalBottomRow/useControlTotalInfo';

const CustomerReportMain = ({ ...props }) => {
  /** Get props data */
  const { subCategory } = props;

  /** Data-grid related value */
  const gridRef = useRef(null);
  const [columnDefs, setColumnDefs] = useState(
    generateReportGridColumnDefinition({
      subCategory,
    }),
  );

  /** Query Key (filter) */
  const div = useSelector(state => state?.nowDiv);

  /** Get grid data */
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams?.size === 0) {
      searchParams?.set('date_1', 'created_date');
      searchParams?.set('before_1', '3month');
      setSearchParams(prev => searchParams);
    }
  }, [searchParams]);
  const {
    mainData: rowData,
    isLoading: isMainLoading,
    isPending: isMainPending,
    isRefetching: isMainRefetching,
  } = useGetMainData({ div, searchParams });

  /** Modal opener */
  const [advancedSearchOpener, setAdvancedSearchOpener] = useState(false);
  const [savedListOpener, setSavedListOpener] = useState(false);
  const [saveItemOpener, setSaveItemOpener] = useState(false);
  const [messageOpener, setMessageOpener] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);
  const [customColumnOpener, setCustomColumnOpener] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  /** Error State */
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  /** Mutator */
  const [isSaveEnable, setIsSaveEnable] = useState(false);
  const { mutate: postSavedMutate } = usePostSaved({
    setIsSuccess,
    setModalMessage,
    setMessageOpener,
    setIsSaveEnable,
  });
  const { mutate: postMainMutate } = usePostMain({
    setIsSuccess,
    setModalMessage,
    setMessageOpener,
    setOpen: setSaveItemOpener,
  });
  const { loadingOpener, setLoadingOpener } = useGetLoadingOpener({
    isMainLoading,
    isMainPending,
  });

  /** Selected Saved List */
  const [selectedList, setSelectedList] = useState(null);

  useEffect(() => {
    if (selectedList) {
      /** Apply query parameter */
      setQueryParameter({ searchParams, setSearchParams, selectedList });
      /** Specify column */
      setColumnDefs(prev => filterColumnDefinition({ selectedList }));
    }
  }, [selectedList]);

  /** Handle SaveItemModal opening */
  const handleOpenSaveItemModal = () => {
    if (columnDefs?.length > 0) {
      setSaveItemOpener(true);
    } else {
      setErrorMessage('Please select at least one column.');
      setIsErrorModalOpen(true);
    }
  };

  const { totalRows, setChangeFlag } = useControlTotalInfo({
    gridRef,
    queryData: rowData,
  });

  return (
    <>
      <StyledCustomerReportWrapper>
        <TopUtilityBar
          gridRef={gridRef}
          subCategory={subCategory}
          setAdvancedSearchOpener={setAdvancedSearchOpener}
          setSavedListOpener={setSavedListOpener}
          setSaveItemOpener={handleOpenSaveItemModal}
          setCustomColumnOpener={setCustomColumnOpener}
        />
        <ReportDataGrid
          data={rowData}
          subCategory={subCategory}
          gridRef={gridRef}
          columnDefs={columnDefs}
          onFilterChanged={() => {
            setChangeFlag(prev => !prev);
          }}
          onSelectionChanged={() => {
            setChangeFlag(prev => !prev);
          }}
        />
        <TotalBottomBar totalRows={totalRows} />
      </StyledCustomerReportWrapper>
      {/* Advanced Search Modal */}
      {advancedSearchOpener && (
        <AdvancedSearchModal
          open={advancedSearchOpener}
          setOpen={setAdvancedSearchOpener}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      )}
      {/* Saved List Modal */}
      {savedListOpener && (
        <SavedListModal
          div={div}
          open={savedListOpener}
          setOpen={setSavedListOpener}
          selectedList={selectedList}
          setSelectedList={setSelectedList}
        />
      )}
      {/* Save Item Modal */}
      {saveItemOpener && (
        <SaveItemModal
          open={saveItemOpener}
          setOpen={setSaveItemOpener}
          setModalMessage={setModalMessage}
          setMessageOpener={setMessageOpener}
          setIsSuccess={setIsSuccess}
          div={div}
          searchParams={searchParams}
          columnDefs={columnDefs}
          postSavedMutate={postSavedMutate}
          postMainMutate={postMainMutate}
          isSaveEnable={isSaveEnable}
          setIsSaveEnable={setIsSaveEnable}
        />
      )}
      {/* Custom Column Modal */}
      {customColumnOpener && (
        <CommonCustomColumnModal
          openModal={customColumnOpener}
          setOpenModal={setCustomColumnOpener}
          selectedItems={
            columnDefs?.map(column => {
              if (column?.isColumnGroup) {
                return {
                  [column?.field]: column?.children?.map(
                    childColumn => childColumn?.field,
                  ),
                };
              }
              return column?.field;
            }) || []
          }
          columnItems={createCustomerReportColDefs?.map((column, index) => {
            if (column?.isColumnGroup) {
              return {
                id: index,
                label: column?.headerName,
                value: column?.field,
                theme: {
                  backgroundColor: column?.backgroundColor,
                  color: column?.color,
                  icon: column?.icon ? { ...column?.icon } : null,
                },
                children: column?.children?.map((childColumn, childIndex) => {
                  return {
                    id: childIndex,
                    label: childColumn?.headerName,
                    value: childColumn?.field,
                    checked: false,
                  };
                }),
              };
            }
            return {
              id: index,
              label: column?.headerName,
              value: column?.field,
            };
          })}
          onApplyClick={({ ...handlerProps }) => {
            generateCustomizedColumn({
              customizedColumn: handlerProps?.appliedColumnList,
              setColumnDefs,
            });
            setCustomColumnOpener(false);
          }}
        />
      )}
      {/* Loading Modal */}
      {loadingOpener && <CommonLoading open={loadingOpener} />}
      {/* Message Modal */}
      {messageOpener && !isSuccess && (
        <CommonErrorModal
          open={messageOpener}
          setIsOpen={setMessageOpener}
          message={modalMessage}
          isSuccess={isSuccess}
        />
      )}
      {/* Error Modal */}
      <CommonErrorModal
        open={isErrorModalOpen}
        setIsOpen={setIsErrorModalOpen}
        message={errorMessage}
        onOkClickHandler={() => setIsErrorModalOpen(false)}
        onCloseClickHandler={() => setIsErrorModalOpen(false)}
        isSuccess={false}
      />
    </>
  );
};

export default CustomerReportMain;
