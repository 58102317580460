import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import CommonDataGrid from '../../../components/CommonDataGrid';
import { GRID_MOD_EDIT } from '../../../constants/Common/DataGrid/setting';
import getColumnDefs from '../../../utils/DailyReport/Common/Generator/getColumDefs';
import handleSave from '../../../utils/DailyReport/Common/Handler/handleSave';
import ReturnYardHeader from '../../../components/DailyReport/ReturnYardHeader';
import useGetYardList from '../../../hooks/DailyReport/Get/useGetYardList';
import useGetAPInput from '../../../hooks/DailyReport/Get/useGetAPInput';
import useGetDriverList from '../../../hooks/DailyReport/Get/useGetDriverList';
import { createGridSelectOption } from '../../../utils/Common/createFloatingFilterSelectOption';
import DriverModal from '../../../components/DailyReport/DriverModal';
import APModal from '../../../components/DailyReport/APModal';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import handleClick from '../../../utils/DailyReport/Common/Handler/handleClick';
import useGetReturnYard from '../../../hooks/DailyReport/Get/useGetReturnYard';
import usePatchReturnYard from '../../../hooks/DailyReport/Patch/usePatchReturnYard';
import handleEditingStart from '../../../utils/DailyReport/Common/Handler/handleEditingStart';
import handleEditingStop from '../../../utils/DailyReport/Common/Handler/handleEditingStop';
import getReturnYardRowStyle from '../../../utils/DailyReport/ReturnYard/Generator/getReturnYardRowStyle';
import TotalBottomBar from '../../../components/Common/CommonTotalBottomBar';
import useControlTotalInfo from '../../../hooks/Common/TotalBottomRow/useControlTotalInfo';
import createFilter from '../../../utils/TmsReport/Return/ReturnYard/Generator/createFilter';

const ReturnYard = ({ isEditable }) => {
  const gridRef = useRef(null);
  const page = 'return_yard';
  const divList = useSelector(state => state?.common?.allDiv);

  /** Options for Grid */
  const getPool = useSelector(state => state.common.pool);
  const [gridPool, setGridPool] = useState(
    createGridSelectOption(getPool?.map(item => item.pool)),
  );
  useEffect(() => {
    setGridPool(createGridSelectOption(getPool?.map(item => item.pool)));
  }, [getPool]);

  const [isLoadingOpen, setIsLoadingOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [messageModalType, setMessageModalType] = useState(false);

  /* States for Row Edit */
  const [woNo, setWONo] = useState('');
  const [beforeEditData, setBeforeEditData] = useState({});

  /* States for Driver/AP Modal */
  const [isDriverModalOpen, setIsDriverModalOpen] = useState(false);
  const [isAPModalOpen, setIsAPModalOpen] = useState(false);
  const [selectedDriverData, setSelectedDriverData] = useState(null);
  const [selectedScheduleSeq, setSelectedScheduleSeq] = useState(null);

  const [focusedCell, setFocusedCell] = useState({});
  const [clickFocusedCell, setClickFocusedCell] = useState(null);

  const restoreFocusedCell = ({ ...props }) => {
    if (focusedCell?.rowIndex !== null && focusedCell?.column !== null) {
      setTimeout(() => {
        gridRef?.current?.api?.ensureIndexVisible(focusedCell.rowIndex);
        gridRef?.current?.api?.ensureColumnVisible(focusedCell.column);
        gridRef?.current?.api?.setFocusedCell({
          rowIndex: focusedCell.rowIndex,
          colKey: focusedCell.column,
        });
      }, 0);
    }
  };

  const {
    apInputData,
    refetch: getAPRefetcher,
    isFetching: isFetchingGetAP,
    isLoading: isLoadingGetAP,
    isError: isErrorGetAP,
  } = useGetAPInput({
    page: 'rtn',
    woNo,
    scheduleSeq: selectedScheduleSeq,
    driver: selectedDriverData?.driver,
    setIsErrorModalOpen,
    setErrorMessage,
    setIsLoadingOpen,
  });

  const { driverList, isPending: isPendingDriverList } = useGetDriverList({
    setErrorMessage,
    setIsErrorModalOpen,
    woNo,
    page: 'rtn',
  });

  const mutateRow = usePatchReturnYard({
    setErrorMessage,
    setIsErrorModalOpen,
    gridRef,
    focusedCell,
    restoreFocusedCell,
  });

  const [yardList, setYardList] = useState([]);
  const { isLoading: isLoadingYardList } = useGetYardList({
    setYardList,
    setErrorMessage,
    setIsErrorModalOpen,
  });

  const handleRowEditingStart = ({ gridParam }) => {
    handleEditingStart({
      gridRef,
      gridParam,
      setClickFocusedCell,
      setBeforeEditData,
      page,
    });
  };

  const handleRowEditingStop = ({ gridParam }) => {
    handleEditingStop({ gridParam, page });
  };

  const handleRowValueChanged = gridParam => {
    handleSave({
      mutate: mutateRow.mutate,
      gridParam,
      beforeEditData,
      page,
      isEditable,
      setFocusedCell,
      setErrorMessage,
      setIsErrorModalOpen,
    });
  };

  const handleCellClicked = ({ gridParam }) => {
    handleClick({
      gridParam,
      clickFocusedCell,
      setClickFocusedCell,
      page,
      isEditable,
    });
  };

  /** Filter related status */
  const [filterOpen, setFilterOpen] = useState(false);
  const [filters, setFilters] = useState(null);
  useEffect(() => {
    setFilters(prev => [
      ...createFilter({ filters, setFilters, yardList, divList }),
    ]);
  }, [yardList, divList]);

  const filterStatus = {
    filters,
    setFilters,
    filterOpen,
    setFilterOpen,
  };

  const list = useGetReturnYard({
    setErrorMessage,
    setIsErrorModalOpen,
    filters,
  });

  const { totalRows, setChangeFlag } = useControlTotalInfo({
    gridRef,
    queryData: list?.data,
  });

  const columnDefsParams = {
    page,
    setIsDriverModalOpen,
    setWONo,
    setSelectedScheduleSeq,
    setIsMessageModalOpen: setIsErrorModalOpen,
    setMessageModalMessage: setErrorMessage,
    setMessageModalType,
    setIsLoadingOpen,
  };

  const [columnDefs, setColumnDefs] = useState(
    getColumnDefs({
      editParam: { pool: gridPool },
      isEditable,
      ...columnDefsParams,
    }),
  );
  useEffect(() => {
    setColumnDefs(
      getColumnDefs({
        editParam: { pool: gridPool },
        isEditable,
        ...columnDefsParams,
      }),
    );
  }, [gridPool, isEditable]);

  return (
    <>
      <div className="w-full h-full pl-[55px] pt-[18px] flex flex-col">
        {!_.isEmpty(yardList) && (
          <>
            <ReturnYardHeader
              gridRef={gridRef}
              yardList={yardList}
              divList={divList}
              {...filterStatus}
            />
            <div className="w-full h-full pr-[40px]">
              <CommonDataGrid
                gridRef={gridRef}
                data={list?.data || []}
                columnDefs={columnDefs}
                modType={isEditable ? GRID_MOD_EDIT : undefined}
                onCellClicked={handleCellClicked}
                onRowValueChanged={handleRowValueChanged}
                onRowEditingStart={handleRowEditingStart}
                onRowEditingStop={handleRowEditingStop}
                undoRedoCellEditing
                undoRedoCellEditingLimit={1}
                getRowStyle={getReturnYardRowStyle}
                onFilterChanged={() => {
                  setChangeFlag(prev => !prev);
                }}
                onSelectionChanged={() => {
                  setChangeFlag(prev => !prev);
                }}
              />
              <TotalBottomBar totalRows={totalRows} />
            </div>
          </>
        )}
      </div>
      <DriverModal
        setWONo={setWONo}
        isDriverAPModalOpen={isDriverModalOpen}
        setDriverAPModalOpen={setIsDriverModalOpen}
        setAPModalOpen={setIsAPModalOpen}
        setSelectedScheduleSeq={setSelectedScheduleSeq}
        setSelectedDriverData={setSelectedDriverData}
        getAPRefetcher={getAPRefetcher}
        driverList={driverList}
        isPendingDriverList={isPendingDriverList}
        selectedDriverData={selectedDriverData}
        isFetchingGetAP={isFetchingGetAP}
        isErrorGetAP={isErrorGetAP}
      />
      <APModal
        woNo={woNo}
        setWONo={setWONo}
        isAPModalOpen={isAPModalOpen}
        setAPModalOpen={setIsAPModalOpen}
        setSelectedScheduleSeq={setSelectedScheduleSeq}
        setSelectedDriverData={setSelectedDriverData}
        isFetchingGetAP={isFetchingGetAP}
        isErrorGetAP={isErrorGetAP}
        apInputData={apInputData}
        selectedScheduleSeq={selectedScheduleSeq}
        setMessageModalOpen={setIsErrorModalOpen}
        setMessageModalMessage={setErrorMessage}
        setLoadingOpen={setIsLoadingOpen}
        gridRef={gridRef}
        page="rtn"
      />
      <CommonErrorModal
        open={isErrorModalOpen}
        setIsOpen={setIsErrorModalOpen}
        message={errorMessage}
      />
    </>
  );
};

export default ReturnYard;
