import React, { useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import CommonButton from '../../../../CommonButton/CommonButton';
import CommonCheckbox from '../../../../Inputs/CommonCheckbox/CommonCheckbox';
import CommonFile from '../../../../Inputs/CommonFile/CommonFile';
import CommonRowHeader from '../../../../SecondInputs/CommonRowHeader.jsx/CommonRowHeader';
import StyledDisabledField from '../../../../../styles/BCDetail/Document/StyledDisabledField';
import generateDate from '../../../../../utils/BCDetail/Document/Generator/generateDate';
import handleRowSave from '../../../../../utils/BCDetail/Document/Handler/handleRowSave';
import handleRowDisable from '../../../../../utils/BCDetail/Document/Handler/handleRowDisable';
import StyledFieldWrapper from '../../../../../styles/BCDetail/Document/StyledFieldWrapper';
import { DOCUMENT_COLUMN_STYLES } from '../../../../../constants/BCDetail/Document/documentTableElements';
import handleValidationChange from '../../../../../utils/BCDetail/Document/Handler/handleValidationChange';

const DocumentRow = ({
  setDelType,
  row,
  preset,
  woNo,
  id,
  isEditable,
  selectedDocType,
  setSelectedDocType,
  windowWidth,
  loadingValidDocList,
  isLoadingPatchDocument,
  ...props
}) => {
  const { setValue, handleSubmit } = useFormContext();
  const buttonRef = useRef(null);

  const type = row.type;
  const isDisableRow = handleRowDisable({ woNo, type });
  const watchFile = useWatch({ name: `file.${id}.${type}` });

  const [validationIcon, setValidationIcon] = useState('');
  useEffect(() => {
    const newValidIcon = handleValidationChange({ loadingValidDocList, row });
    setValidationIcon(newValidIcon);
  }, [loadingValidDocList, row]);

  useEffect(() => {
    setValue(`${id}.type`, row.type);
    setValue(`${id}.file_url`, row.file_url);
    setValue(`${id}.accuracy`, !isNaN(row?.accuracy) ? row?.accuracy : null);
    setValue(`${id}.updated_by`, row.updated_by);
    setValue(`${id}.updated_date`, row.updated_date);
    setValue(`file.${id}.${type}`, undefined);
    setValue(
      `file.${id}.${type}_file_name`,
      row?.file_url?.split('/')?.pop() || '',
    );
  }, [row]);

  useEffect(() => {
    if (watchFile === '' || watchFile instanceof File) {
      setSelectedDocType(row?.type);
    }

    if (watchFile === '') {
      setValue(`${id}.file_url`, '');
    }
  }, [watchFile]);

  return (
    <div className="flex items-center gap-x-[2px]">
      <div
        className={`flex items-center gap-x-[2px] 
        ${row?.type === selectedDocType && 'shadow-[0_0_0_1px_#264B9F80]'}`}
        onClick={() => {
          if (!isDisableRow) {
            setSelectedDocType(row?.type !== selectedDocType ? row?.type : '');
          }
        }}
      >
        {/* DATE */}
        <StyledFieldWrapper
          {...DOCUMENT_COLUMN_STYLES?.DATE}
          isCursor={!isDisableRow}
        >
          <CommonRowHeader
            content={generateDate(row?.updated_date, 'MM/DD/YY HH:mm')}
            isMinus={!preset}
            onMinusClick={() => {
              setDelType(row.type);
            }}
            disabled={[true, false]}
            submit={[false, false]}
            testId={['', 'delete-row']}
            disabledTheme={isDisableRow}
            bgColor={
              row?.type === selectedDocType ? 'bg-[#D0D8EB]' : 'bg-[#ffffff]'
            }
          />
        </StyledFieldWrapper>

        {/* DOCUMENT */}
        <StyledFieldWrapper
          {...DOCUMENT_COLUMN_STYLES?.DOCUMENT}
          isCursor={!isDisableRow}
        >
          <StyledDisabledField
            className={`justify-start pl-[15px] 
            ${row?.type === selectedDocType && 'bg-[#D0D8EB]'}
          `}
            bgColor={!isDisableRow && '#F2F4F8'}
          >
            {row.type}
          </StyledDisabledField>
        </StyledFieldWrapper>

        {/* UPDATED BY */}
        <StyledFieldWrapper
          {...DOCUMENT_COLUMN_STYLES?.['UPDATED BY']}
          isCursor={!isDisableRow}
        >
          <StyledDisabledField
            className={`${row?.type === selectedDocType && 'bg-[#D0D8EB]'}`}
          >
            {row?.updated_by}
          </StyledDisabledField>
        </StyledFieldWrapper>

        {/* Verif */}
        <StyledFieldWrapper
          {...DOCUMENT_COLUMN_STYLES?.VERIF}
          isCursor={!isDisableRow}
        >
          <StyledDisabledField
            className={`${
              row?.type === selectedDocType && 'bg-[#D0D8EB]'
            } flex justify-center items-center`}
          >
            {validationIcon && (
              <img
                className="w-[20px] h-[20px]"
                src={validationIcon}
                alt="Validation Status"
              />
            )}
          </StyledDisabledField>
        </StyledFieldWrapper>

        {/* CK */}
        <StyledFieldWrapper
          {...DOCUMENT_COLUMN_STYLES?.CK}
          bgColor={`${row?.type === selectedDocType ? '#D0D8EB' : '#DDE2EC'}`}
          onClick={e => e.stopPropagation()}
        >
          <CommonCheckbox
            inputName={`${id}.checked`}
            labelVisible={false}
            options={[
              {
                defaultValue: row.checked,
                inputName: `${id}.checked`,
              },
            ]}
            onChangeHandler={({ e, onChange }) => {
              onChange(e?.target?.checked);
            }}
            disabled={!isEditable || isDisableRow}
          />
        </StyledFieldWrapper>
      </div>

      {/* BROWSE */}
      <StyledFieldWrapper {...DOCUMENT_COLUMN_STYLES?.BROWSE}>
        <CommonFile
          inputName={`file.${id}.${type}`}
          defaultValue={[row?.file_url?.split('/').pop(), '']}
          labelVisible={false}
          disabled={!isEditable || isDisableRow}
        />
      </StyledFieldWrapper>

      {/* SAVE */}
      <StyledFieldWrapper {...DOCUMENT_COLUMN_STYLES?.SAVE}>
        <CommonButton
          ref={buttonRef}
          category="save"
          type="button"
          className={`w-full h-full ${
            (!isEditable || isDisableRow) && 'border-[#DADADA] bg-[#DADADA]'
          }`}
          disabled={!isEditable || isDisableRow || isLoadingPatchDocument}
          onClick={() =>
            handleRowSave({
              handleSubmit,
              type: row.type,
              row,
              id,
              woNo,
              buttonRef,
              ...props,
            })
          }
        >
          {windowWidth > 960 && (
            <div className="pt-[1px] pr-[4px] w-full">SAVE</div>
          )}
        </CommonButton>
      </StyledFieldWrapper>
    </div>
  );
};

export default DocumentRow;
