import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import dailyReportKeys from '../keys';
import getReturnYard from '../../../services/TmsReport/Schedules/Get/getReturnYard';

const transformData = ({ filters }) => {
  return filters?.reduce?.((acc, { title: { value: titleValue }, items }) => {
    acc[titleValue] = items?.reduce((itemAcc, { value, isSelected }) => {
      itemAcc[value] = isSelected;
      return itemAcc;
    }, {});
    return acc;
  }, {});
};

const buildQueryParams = data => {
  const params = new URLSearchParams();

  Object.entries(data).forEach(([key, values]) => {
    Object.entries(values).forEach(([value, isSelected]) => {
      if (isSelected) {
        params.append(key, value);
      }
    });
  });

  return params;
};

const useGetReturnYard = ({
  setErrorMessage,
  setIsErrorModalOpen,
  filters,
}) => {
  // Convert filters data to dictionary form
  const params = transformData({ filters });

  const result = useQuery({
    queryKey: dailyReportKeys.returnYardList({
      yard: params?.['ngl-yard'],
      category: params?.category,
      div: params?.div,
      'sche-type': params?.['sche-type'],
      'status-color': params?.['status-color'],
    }),
    queryFn: () => getReturnYard({ params: buildQueryParams(params) }),
    refetchOnWindowFocus: false,
    enabled: !!params?.['ngl-yard'],
  });

  useEffect(() => {
    if (result?.isError) {
      setIsErrorModalOpen(true);
      setErrorMessage(result?.error?.message);
    }
  }, [result?.isError]);

  return result;
};

export default useGetReturnYard;
