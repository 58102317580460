import React from 'react';
import dayjs from 'dayjs';
import CommonStatusBox from '../../../SecondInputs/CommonStatusBox/CommonStatusBox';
import { TRACKING_ROW_ITEM_STYLE } from '../../../../styles/BCDetail/Tracking/TrackingHeaderItem';

const TrackingList = ({ trackingList }) => {
  return (
    <div className="ml-[70px]">
      {trackingList?.map((row, idx) => (
        <div key={String(idx + 1)} className="flex space-x-[2px]">
          <div className="text-[11px] bg-white font-[700] text-[#264B9F] w-[256px] h-[30px] text-start shadow drop-shadow-[0_0.5px_0_rgba(0,0,0,0.25)] mr-[2px] mb-[2px]">
            <div className="w-[7px] bg-[#264B9F] h-[30px] absolute" />
            <div>
              <p className="pl-[19px] py-[8px]">
                {row?.event_datetime && dayjs(row?.event_datetime)?.isValid()
                  ? dayjs(row?.event_datetime)?.format('MM/DD/YY HH:mm')
                  : '-'}
              </p>
            </div>
          </div>

          <div className={`w-[95px] ${TRACKING_ROW_ITEM_STYLE}`}>
            <p className="text-center">{row?.city}</p>
          </div>
          <div className={`w-[63px] ${TRACKING_ROW_ITEM_STYLE}`}>
            <p className="text-center">{row?.state}</p>
          </div>
          <div className={`w-[90px] ${TRACKING_ROW_ITEM_STYLE}`}>
            <p className="text-center">{row?.timezone}</p>
          </div>
          <div className={`w-[252px] ${TRACKING_ROW_ITEM_STYLE}`}>
            <p className="text-center">{row?.location}</p>
          </div>
          <div className={`w-[252px] ${TRACKING_ROW_ITEM_STYLE}`}>
            <p className="text-center">{row?.mobile}</p>
          </div>
          <div className={`w-[252px] ${TRACKING_ROW_ITEM_STYLE}`}>
            <p className="text-center">{row?.vehicle}</p>
          </div>
          <div className={`w-[252px] ${TRACKING_ROW_ITEM_STYLE}`}>
            <p className="text-center">{row?.driver}</p>
          </div>
          <div>
            <CommonStatusBox
              width="w-[252px]"
              height={30}
              status={row?.status}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default TrackingList;
