import React from 'react';
import { PATH_NO_UPLOAD_DOCUMENT_ICON } from '../../../../../constants/Common/path';

const NoUploadDocumentPreview = () => {
  return (
    <div className="bg-gray-300 flex flex-col justify-center items-center h-full">
      <div className="flex justify-center items-center mb-[33px]">
        <img
          src={PATH_NO_UPLOAD_DOCUMENT_ICON}
          width="75px"
          height="85px"
          alt="No Uploaded Document"
        />
      </div>
      <div className="flex flex-col justify-center items-center">
        <p className="mb-[10px] text-[#556DA1] font-[700] text-[18px]">
          File was not uploaded
        </p>
        <p className="text-[#7F90B6] font-[500] text-[16px]">
          Please upload document
        </p>
      </div>
    </div>
  );
};

export default NoUploadDocumentPreview;
