import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import CommonDataGrid from '../../../components/CommonDataGrid';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import useGetQBAPARList from '../../../hooks/Acct/QBAPAR/Get/useGetQBAPARList';
import QBAPARDetailTable from '../../../components/Acct/Common/QBAPARDetailTable';
import usePostMultipleQB from '../../../hooks/Acct/Common/Post/usePostMultipleQB';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import AccountQBHeaderBar from '../../../components/Acct/Common/AccountQBHeaderBar';
import createQBAPARColumnDefs from '../../../utils/Acct/QBAPAR/Generator/createQBAPARColumnDefs';
import useControlLoading from '../../../hooks/Acct/Common/Control/useControlLoading';
import AdvancedSearchModal from '../../../components/Acct/Common/AdvancedSearchModal';
import { ACCT_PAGE } from '../../../constants/Acct/Common/staticNames';
import moveBCDetailPage from '../../../utils/Acct/Common/Handler/moveBCDetailPage';
import usePatchDriverPay from '../../../hooks/Acct/QBAPAR/Patch/usePatchDriverPay';
import useControlTotalInfo from '../../../hooks/Common/TotalBottomRow/useControlTotalInfo';
import TotalBottomBar from '../../../components/Common/CommonTotalBottomBar';
import { QB_STATUS_OBJECT } from '../../../constants/Acct/Common/acctElement';
import useGetUserDivisionList from '../../../hooks/Acct/Common/Get/useGetUserDivisonList';

const QBAPAR = () => {
  dayjs.extend(isoWeek);

  const divList = useGetUserDivisionList();

  const gridRef = useRef(null);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [queryObject, setQueryObject] = useState({
    type: 'ALL',
    dateType: 'inv',
    status: QB_STATUS_OBJECT?.['Not Sent'],
    period: 'lastWeek',
    from_date: dayjs()
      ?.subtract(1, 'week')
      ?.startOf('isoWeek')
      ?.format('YYYY-MM-DD'),
    to_date: dayjs()
      ?.subtract(1, 'week')
      ?.endOf('isoWeek')
      ?.format('YYYY-MM-DD'),
  });

  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [messageContents, setMessageContent] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const errorModalOptions = {
    setIsSuccess,
    setErrorMessage: setMessageContent,
    setIsErrorModalOpen: setMessageModalOpen,
  };

  const [columnDefs, setColumnsDefs] = useState([]);

  useEffect(() => {
    if (divList?.length > 0) {
      setColumnsDefs(createQBAPARColumnDefs({ divList }));
    }
  }, [divList]);

  const qbAPARQuery = useGetQBAPARList({ queryObject });

  const { mutate: driverPayPatchMutator } = usePatchDriverPay({
    errorModalOptions,
  });

  const handlerPostQuickBooks = usePostMultipleQB({
    errorModalOptions,
  });

  const { isLoadingOpen } = useControlLoading({
    isLoadingList: [qbAPARQuery?.isLoadingQBAPAR],
  });

  const { totalRows, totalAmount, setChangeFlag } = useControlTotalInfo({
    gridRef,
    queryData: qbAPARQuery?.qbAPARList,
    amountField: 'total_amt',
  });

  return (
    <>
      <div className="pr-[40px] flex flex-col h-full">
        <AccountQBHeaderBar
          page={ACCT_PAGE?.qbAPAR}
          gridRef={gridRef}
          setOpenModal={setIsOpenModal}
          handlerPostQuickBooks={handlerPostQuickBooks}
          driverPayPatchMutator={driverPayPatchMutator}
          errorModalOptions={errorModalOptions}
        />
        <div className="w-full flex-1 flex flex-col">
          <CommonDataGrid
            data={qbAPARQuery?.qbAPARList}
            columnDefs={columnDefs}
            gridRef={gridRef}
            foldable={Boolean(true)}
            foldableContent={QBAPARDetailTable}
            embedFullWidthRows
            onCellClicked={props =>
              moveBCDetailPage({
                gridParam: props?.gridParam,
                columnID: 'wo_no',
              })
            }
            onFilterChanged={() => {
              setChangeFlag(prev => !prev);
            }}
            onSelectionChanged={() => {
              setChangeFlag(prev => !prev);
            }}
          />
          <TotalBottomBar totalRows={totalRows} totalAmount={totalAmount} />
        </div>
      </div>
      {isOpenModal && (
        <AdvancedSearchModal
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          queryObject={queryObject}
          setQueryObject={setQueryObject}
          page={ACCT_PAGE?.qbAPAR}
          errorModalOptions={errorModalOptions}
        />
      )}
      <CommonLoading open={isLoadingOpen} />
      {messageModalOpen && !isSuccess && (
        <CommonErrorModal
          open={messageModalOpen}
          message={messageContents}
          setIsOpen={setMessageModalOpen}
          isSuccess={isSuccess}
        />
      )}
    </>
  );
};

export default QBAPAR;
