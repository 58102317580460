import React from 'react';
import { PATH_NO_SELECTED_DOCUMENT_ICON } from '../../../../../constants/Common/path';

const NoSelectedDocumentPreview = () => {
  return (
    <div className="bg-gray-300 flex flex-col justify-center items-center h-full">
      <div className="flex justify-center items-center mb-[33px]">
        <img
          src={PATH_NO_SELECTED_DOCUMENT_ICON}
          width="100px"
          height="100px"
          alt="No Selected Document"
        />
      </div>
      <div className="flex flex-col justify-center items-center">
        <p className="mb-[10px] text-[#556DA1] font-[700] text-[18px]">
          No Document Selected
        </p>
        <p className="text-[#7F90B6] font-[500] text-[16px]">
          Please select an item to preview the content
        </p>
      </div>
    </div>
  );
};

export default NoSelectedDocumentPreview;
