import styled from 'styled-components';

const StyledDocumentAlignmentContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 960px) {
    align-items: center;
  }
`;
export default StyledDocumentAlignmentContentWrapper;
