import React from 'react';
import CommonButton from '../../../CommonButton/CommonButton';
import deleteReportSetting from '../../../../utils/CustomerReport/Handler/deleteReportSetting';
import RefreshButton from '../../../RefreshButton';
import customerReportKeys from '../../../../hooks/CustomerReport/keys';

const SettingUtilityBar = ({ ...props }) => {
  const {
    gridRef,
    deleteSettingMutate,
    confirmDeleteOptions,
    alertModalOptions,
  } = props;

  return (
    <div className="flex flex-row">
      <RefreshButton
        queryKeyList={[customerReportKeys?.setting({})?.filter(item => !!item)]}
      />
      <CommonButton
        category="delete"
        width="80px"
        height="24px"
        onClick={() => {
          deleteReportSetting({
            gridRef,
            deleteSettingMutate,
            confirmDeleteOptions,
            alertModalOptions,
          });
        }}
      >
        Delete
      </CommonButton>
    </div>
  );
};

export default SettingUtilityBar;
