import generateValidQueryObject from '../../../Common/Generator/generateValidQueryObject';
import masterKeys from '../../../../hooks/Master/keys';

const handleSearchSubmit = ({
  submitData,
  setQueryObject,
  setOpenModal,
  queryClient,
  pageType,
}) => {
  setQueryObject(generateValidQueryObject(submitData));
  setOpenModal(false);

  const queryKey =
    pageType === 'driver' ? masterKeys.driverList() : masterKeys.companyList();
  queryClient.invalidateQueries({
    queryKey,
  });
};

export default handleSearchSubmit;
