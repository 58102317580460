import React, { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import NoSelectedDocumentPreview from './NoSelectedDocumentPreview';
import { PREVIEW_STATUS } from '../../../../constants/BCDetail/Document';
import handleDocumentChange from '../../../../utils/BCDetail/Document/Handler/handleDocumentChange';
import NoUploadDocumentPreview from './NoUploadDocumentPreview';
import NoPDFDocumentPreview from './NoPDFDocumentPreview';
import AvailablePDFPreview from './AvailablePDFPreview';

const Preview = ({
  documentList,
  selectedDocType,
  documentController,
  fileVersions,
}) => {
  const [fileURL, setFileURL] = useState('');

  const watchFileList = useWatch({
    control: documentController.control,
    name: 'file',
  });

  const [previewStatus, setPreviewStatus] = useState(
    PREVIEW_STATUS?.NO_SELECTED_DOC,
  );

  useEffect(() => {
    const newPreviewStatus = handleDocumentChange({
      documentList,
      selectedDocType,
      watchFileList,
      setFileURL,
      fileVersions,
    });

    setPreviewStatus(newPreviewStatus);
  }, [documentList, selectedDocType, watchFileList]);

  return (
    <div className="max-w-[1000px] h-full flex-1 max-[960px]:pt-[46px]">
      {previewStatus === PREVIEW_STATUS?.NO_SELECTED_DOC && (
        <NoSelectedDocumentPreview />
      )}

      {previewStatus === PREVIEW_STATUS?.NO_UPLOAD_DOC && (
        <NoUploadDocumentPreview />
      )}

      {previewStatus === PREVIEW_STATUS?.NO_PDF_FILE && (
        <NoPDFDocumentPreview fileURL={fileURL} />
      )}

      {previewStatus === PREVIEW_STATUS?.AVAILABLE_PREVIEW && (
        <AvailablePDFPreview fileURL={fileURL} />
      )}
    </div>
  );
};

export default Preview;
