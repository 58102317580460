import React from 'react';
import CommonButton from '../../../CommonButton/CommonButton';
import exportToExcel from '../../../../utils/Common/Datagrid/Handler/exportToExcel';
import RefreshButton from '../../../RefreshButton';
import masterKeys from '../../../../hooks/Master/keys';

const REFRESH_KEY_NAMES = {
  COMPANY: 'companyList',
  'ACCOUNT CODE': 'acctCodeList',
  DRIVER: 'driverList',
  'SERVICE CODE': 'serviceCodeList',
};

const HeaderButtons = ({
  gridRef,
  setAdvancedSearchOpen,
  onAddClickHandler,
  isEditable,
  fileName,
}) => {
  return (
    <div className="flex my-[20px] w-full pr-[40px] justify-end gap-x-[5px]">
      <RefreshButton
        queryKeyList={[masterKeys?.[REFRESH_KEY_NAMES?.[fileName]]?.()]}
      />
      {!!setAdvancedSearchOpen && (
        <CommonButton
          category="greySearch"
          onClick={() => {
            setAdvancedSearchOpen(true);
          }}
          border="0"
          backgroundColor="transparent"
          color="#666666"
        >
          Search
        </CommonButton>
      )}
      <div className="flex items-center gap-x-[10px]">
        {isEditable && !!onAddClickHandler && (
          <CommonButton category="add" onClick={onAddClickHandler}>
            Add
          </CommonButton>
        )}
        <CommonButton
          type="button"
          onClick={() => exportToExcel({ gridRef, fileName })}
          category="excel"
        >
          EXCEL
        </CommonButton>
      </div>
    </div>
  );
};

export default HeaderButtons;
