import dayjs from 'dayjs';
import updateButtonDisabled from '../../../../Common/Handler/updateButtonDisabled';

const portSubmitData = ({ data, submitType, seq, productCode }) => {
  const dataNameTail =
    submitType === 'MOD' && seq !== null && seq !== undefined ? `_${seq}` : '';

  const productCodeSeq =
    productCode &&
    productCode[
      productCode.findIndex(
        item =>
          item?.product_code ===
          data?.[`product_code${dataNameTail}`]?.split('/')[0]?.trim(),
      )
    ]?.seq;
  return {
    product_code: productCodeSeq,
    company: data?.[`company${dataNameTail}`],
    inv_date:
      data?.[`inv_date${dataNameTail}`] &&
      dayjs(data?.[`inv_date${dataNameTail}`])?.isValid()
        ? dayjs(data?.[`inv_date${dataNameTail}`])?.format('YYYY-MM-DD')
        : null,
    rate:
      data?.[`rate${dataNameTail}`] !== null
        ? parseFloat(data?.[`rate${dataNameTail}`])
        : null,
    qty:
      data?.[`qty${dataNameTail}`] !== null
        ? parseFloat(data?.[`qty${dataNameTail}`])
        : null,
    // wave:
    //   (data?.[`wave${dataNameTail}`] !== null
    //     ? parseFloat(data?.[`wave${dataNameTail}`])
    //     : null) || 0,
    description: data?.[`desc${dataNameTail}`],
  };
};

const validateNull = ({
  data,
  setIsAlertOpen,
  setErrorMessage,
  setIsSuccess,
}) => {
  if (!data?.company) {
    setIsSuccess(false);
    setIsAlertOpen(true);
    setErrorMessage('Please select company');
    return false;
  }
  if (!data?.product_code) {
    setIsSuccess(false);
    setIsAlertOpen(true);
    setErrorMessage('Please select service code');
    return false;
  }
  if (isNaN(data?.rate)) {
    setIsSuccess(false);
    setIsAlertOpen(true);
    setErrorMessage('Please input rate');
    return false;
  }
  if (isNaN(data?.qty)) {
    setIsSuccess(false);
    setIsAlertOpen(true);
    setErrorMessage('Please input quantity');
    return false;
  }
  // if (!data?.description && data?.wave !== 0 && !isNaN(data?.wave)) {
  //   setIsSuccess(false);
  //   setIsAlertOpen(true);
  //   setErrorMessage('Please input description');
  //   return false;
  // }
  return true;
};

const onARSubmit = ({
  data,
  arMutator,
  submitType,
  ARProductCodeQuery,
  seq,
  setIsAlertOpen,
  setErrorMessage,
  setIsSuccess,
  buttonRef,
}) => {
  const woNo =
    document.URL?.split('/')?.[document?.URL?.split('/')?.length - 1];

  const targetData = submitType === 'ADD' ? data?.add : data?.mod;

  if (submitType === 'DEL') {
    arMutator?.({ seq, buttonRef });
    return;
  }

  const submitData = portSubmitData({
    data: targetData,
    submitType,
    seq,
    productCode: ARProductCodeQuery?.productCode,
  });

  const isDataValid = validateNull({
    data: submitData,
    setIsAlertOpen,
    setErrorMessage,
    setIsSuccess,
  });
  if (!isDataValid) {
    updateButtonDisabled({ ref: buttonRef, disabled: false });
    return;
  }

  // Mutate
  if (submitType === 'ADD') {
    arMutator?.({ woNo, data: submitData, buttonRef });
  } else if (submitType === 'MOD') {
    arMutator?.({ woNo, data: submitData, seq, buttonRef });
  }
};

export default onARSubmit;
