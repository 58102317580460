import React from 'react';
import CommonButton from '../../../../CommonButton/CommonButton';

const NoPDfDocumentPreview = ({ fileURL }) => {
  return (
    <div className=" bg-gray-300 flex flex-col justify-between items-center h-full py-[30px]">
      <div className="flex flex-col justify-between h-full flex-1">
        <div className="flex flex-col justify-center items-center mt-[370px]">
          <p className="mb-[10px] text-[#556DA1] font-[700] text-[18px]">
            Viewer is Only supported with PDF file
          </p>
          <p className="text-[#7F90B6] font-[500] text-[16px]">
            Please Download and Check the file
          </p>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <CommonButton
          category="import"
          onClick={() => window.open(fileURL, '_blank')}
        >
          Download File
        </CommonButton>
      </div>
    </div>
  );
};

export default NoPDfDocumentPreview;
