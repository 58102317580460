import acctKeys from '../../../../hooks/Acct/keys';
import checkDateDiff from '../../../Common/Handler/checkDateDiff';
import queryStringGenerator from '../Generator/queryStringGenerator';

const searchReconcileContent = ({
  handleSubmit,
  setQueryObject,
  setOpenModal,
  setErrorModalOptions,
  queryObject,
  queryClient,
  pageType,
}) => {
  const queryString = queryStringGenerator({ queryObject });

  const { setErrorModalOpen, setErrorMessage, setIsSuccess } =
    setErrorModalOptions;

  const queryKey =
    pageType === 'chassisReconcile'
      ? acctKeys.chassisReconcileList({ queryString })
      : acctKeys.perdiemReconcileList({ queryString });

  handleSubmit(submitData => {
    if (
      !checkDateDiff({
        fromDate: submitData?.from,
        toDate: submitData?.to,
      })
    ) {
      setIsSuccess(false);
      setErrorMessage(
        'The date range cannot exceed one year. Please try again.',
      );
      setErrorModalOpen(true);
      return;
    }
    setQueryObject(submitData);
    setOpenModal(false);
    queryClient.invalidateQueries({
      queryKey,
    });
  })();
};

export default searchReconcileContent;
