import checkDateDiff from '../../../Common/Handler/checkDateDiff';
import dispatchKeys from '../../../../hooks/Dispatch2/keys';

const handleAdvancedSearchSubmit = ({
  data,
  setQueryObject,
  advancedSearchController,
  setIsMessageModalOpen,
  setMessageModalMessage,
  setIsAdvancedSearchOpen,
  queryClient,
}) => {
  if (
    !checkDateDiff({
      fromDate: data?.dateFrom,
      toDate: data?.dateTo,
    })
  ) {
    setMessageModalMessage(
      'The date range cannot exceed one year. Please try again.',
    );
    setIsMessageModalOpen(true);
  } else {
    setQueryObject(data);
    setIsAdvancedSearchOpen(false);
    advancedSearchController.reset({ ...data });
    queryClient.invalidateQueries({ queryKey: dispatchKeys.list() });
  }
};

export default handleAdvancedSearchSubmit;
