import React, { useState } from 'react';
import { CATEGORY_322 } from '../../../../constants/EDI/options';
import useGet322List from '../../../../hooks/EDI/Get/useGet322List';
import create322ColumnDefs from '../../../../utils/EDI/Generator/create322ColumnDef';
import CommonDataGrid from '../../../CommonDataGrid';
import CommonLoading from '../../../CommonLoading/CommonLoading';

const DataGrid322 = ({
  currDiv,
  searchFrom322,
  searchTo322,
  selectedCategory,
}) => {
  const [columnDefs, setColumnDefs] = useState(create322ColumnDefs());

  const { isLoading322List, list322Data } = useGet322List({
    div: currDiv,
    from: searchFrom322,
    to: searchTo322,
    receiver: CATEGORY_322[selectedCategory],
  });

  return (
    <>
      <CommonLoading open={isLoading322List} />
      <div className="w-full h-[calc(100vh-260px)] pb-8 mt-[30px]">
        <CommonDataGrid
          data={list322Data?.data || []}
          columnDefs={columnDefs}
        />
      </div>
    </>
  );
};

export default DataGrid322;
