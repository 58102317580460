import styled from 'styled-components';

const StyledBCDetailCommonTopBarFieldItemLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #264b9f;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  color: white;
  font-weight: bold;

  width: ${props => `${props?.$styles?.labelWidth || 73}px`};
`;

export default StyledBCDetailCommonTopBarFieldItemLabel;
