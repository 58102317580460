import getGeoInfo from '../../../../services/Admin/Conf/Get/getGeoInfo';

const setTextFieldValue = (instance, value) => {
  instance?.setValue?.(value);
  instance?.onValueChange?.(value);
  instance?.setDataValue?.(value);
};

const handleAddressChange = async ({
  setMessageContent,
  setMessageModalOpen,
  setIsSuccess,
  ...props
}) => {
  const instances = props?.node?.rowPinned
    ? props?.api?.getCellRendererInstances()
    : props?.api
        ?.getCellEditorInstances()
        ?.map(proxy => proxy.componentInstance);

  const cityInstance = instances?.find(
    element => element?.column?.colId === 'city',
  );
  const zipInstance = instances?.find(
    element => element?.column?.colId === 'zip',
  );
  const stateInstance = instances?.find(
    element => element?.column?.colId === 'state',
  );
  const timezoneInstance = instances?.find(
    element => element?.column?.colId === 'timezone',
  );

  setTextFieldValue(cityInstance, props?.getChangedOption?.city);
  setTextFieldValue(zipInstance, props?.getChangedOption?.zip);
  setTextFieldValue(stateInstance, props?.getChangedOption?.state);
  await getGeoInfo({ zip: props?.getChangedOption?.zip })
    .then(result => {
      setTextFieldValue(timezoneInstance, result?.data?.timezone);
    })
    .catch(error => {
      setIsSuccess(false);
      setMessageContent(error?.message);
      setMessageModalOpen(true);
      setTextFieldValue(timezoneInstance, '');
    });
};

export default handleAddressChange;
