import React from 'react';
import WEEK_DAY_LIST from '../../../../constants/TmsReport/Schedules/weeklyDayList';
import WeeklyTableHeader from '../WeeklyTableHeader';
import WeeklyTableContent from '../WeeklyTableContent';
import StyledWeeklyTableWrapper from '../../../../styles/TmsReport/Schedules/StyledWeeklyTableWrapper';
import StyledWeeklyTableColumnWrapper from '../../../../styles/TmsReport/Schedules/StyledWeeklyTableColumnWrapper';
import { SCHEDULE_PAGE_TYPE } from '../../../../constants/TmsReport/Schedules';

const WeeklyTable = ({ pageType, weeklyData, filterQuery }) => {
  return (
    <StyledWeeklyTableWrapper>
      {WEEK_DAY_LIST?.map(day => (
        <StyledWeeklyTableColumnWrapper key={day}>
          <WeeklyTableHeader
            page={SCHEDULE_PAGE_TYPE?.[pageType]}
            weeklyData={weeklyData}
            day={day}
            filterQuery={filterQuery}
          />
          <WeeklyTableContent
            key={day}
            day={day}
            weeklyData={weeklyData}
            pageType={pageType}
          />
        </StyledWeeklyTableColumnWrapper>
      ))}
    </StyledWeeklyTableWrapper>
  );
};

export default WeeklyTable;
