import styled from 'styled-components';

const StyledDisabledField = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  box-shadow: 0 0.5px 1px rgba(0, 0, 0, 0.26);

  white-space: nowrap;

  text-align: center;
  font-size: 11px;
  font-weight: 500;
  color: #666666;
  padding-top: 9px;
  padding-bottom: 9px;

  background-color: ${({ bgColor }) => bgColor || '#dde2ec'};
`;

export default StyledDisabledField;
