import { VALIDATION_PRESET_DOC_LIST } from '../../../../constants/BCDetail/Document/presetDocElement';
import {
  PATH_LOADING_VALIDATION_DOCUMENT_ICON,
  PATH_SUCCESS_VALIDATION_DOCUMENT_ICON,
  PATH_WARNING_VALIDATION_DOCUMENT_ICON,
} from '../../../../constants/Common/path';

const handleValidationChange = ({ loadingValidDocList, row }) => {
  /** Include Validation Document List */
  if (!VALIDATION_PRESET_DOC_LIST?.includes(row?.type)) {
    return '';
  }

  /** Check Null File URL */
  if (!row?.file_url) {
    return '';
  }

  /** Check File Format */
  const isNotPDF = !row?.file_url?.toLowerCase()?.endsWith('.pdf');

  if (isNotPDF) {
    return PATH_WARNING_VALIDATION_DOCUMENT_ICON;
  }

  /** Loading Status */
  if (loadingValidDocList?.includes(row?.type)) {
    return PATH_LOADING_VALIDATION_DOCUMENT_ICON;
  }

  /* Check Null Accuracy */
  const isNullAccuracy = row?.accuracy === undefined || row?.accuracy === null;

  if (isNullAccuracy) {
    return '';
  }

  /** Check File Accuracy */
  const isErrorValidationFile = row?.accuracy < 0;

  if (isErrorValidationFile) {
    return PATH_WARNING_VALIDATION_DOCUMENT_ICON;
  }

  const isValidFile = row?.accuracy >= 80;

  return isValidFile
    ? PATH_SUCCESS_VALIDATION_DOCUMENT_ICON
    : PATH_WARNING_VALIDATION_DOCUMENT_ICON;
};

export default handleValidationChange;
