import { useMutation } from '@tanstack/react-query';
import patchDocumentList from '../../../services/BCDetail/Document/Patch/patchDocumentList';
import useControlToast from '../../Common/Toast/useControlToast';
import {
  SUCCESS_DELETE,
  SUCCESS_UPDATE,
} from '../../../constants/Common/successMessage';
import updateButtonDisabled from '../../../utils/Common/Handler/updateButtonDisabled';
import { VALIDATION_PRESET_DOC_LIST } from '../../../constants/BCDetail/Document/presetDocElement';

const usePatchDocumentList = ({
  documentController,
  delType,
  setDelType,
  setMessageModalOpen,
  setMessageContent,
  docListRefetch,
  fileVersions,
  setFileVersions,
  setLoadingValidDocList,
}) => {
  const { addToast } = useControlToast();

  const mutator = useMutation({
    mutationFn: patchDocumentList,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: (data, variables) => {
      /** Validation Logic */
      const changedDocList = variables?.changedDocList;

      const tempLoadingDocList = data
        ?.filter(doc => {
          return (
            changedDocList?.includes(doc?.type) &&
            VALIDATION_PRESET_DOC_LIST?.includes(doc?.type) &&
            !!doc?.file_url &&
            !!doc?.file_url?.toLowerCase()?.endsWith('.pdf')
          );
        })
        ?.map(doc => doc?.type);

      setLoadingValidDocList(prev => [...prev, ...tempLoadingDocList]);

      docListRefetch();

      /** Resolve Preview cache Issue */
      const newFileVersions = { ...fileVersions };

      changedDocList?.forEach(doc => {
        newFileVersions[doc] = newFileVersions?.[doc]
          ? Number(newFileVersions?.[doc]) + 1
          : 1;
      });

      if (delType) {
        documentController?.unregister(delType);
        setDelType(false);
        addToast({ message: `"${delType}" ${SUCCESS_DELETE}` });
      } else {
        addToast({ message: SUCCESS_UPDATE });
      }

      setFileVersions(newFileVersions);
      docListRefetch();
    },
    onError: error => {
      setMessageContent(error.message);
      setMessageModalOpen(true);
    },
  });
  return mutator;
};

export default usePatchDocumentList;
