import React from 'react';
import CommonButton from '../../../../../../../../CommonButton/CommonButton';
import viewDualMoveContentJobRowDelete from '../../../../../../../../../utils/BCDetail/DetailInfo/DualMove/Handler/viewDualMoveContentJobRowDelete';
import useDeleteDualJobDriver from '../../../../../../../../../hooks/BCDetail/DetailInfo/DualMove/useDeleteDualJobDriver';
import createPageType from '../../../../../../../../../utils/BCDetail/Common/Generator/createPageType';

const DualMoveModalContentJobRow = ({ ...props }) => {
  const { dualMoveRowData, job, isModifiable } = props;

  const { mutate } = useDeleteDualJobDriver({ ...props });

  const splitedJobScheduleDate = job?.sche_date?.split('-');

  return (
    <div
      className="h-[30px] text-[#666] text-[12px] font-medium flex flex-row gap-[2px] cursor-pointer"
      onClick={() => {
        const bcDetailTab = window.open(
          `/bc-detail/detail-info/${createPageType({
            type: job?.wo_no?.[1],
          })}/${job?.wo_no}`,
          'bc-detail-tab',
        );
      }}
    >
      <div className="flex justify-center items-center h-full w-[80px] text-[#264B9F] font-bold">
        {job?.type === 'RTN' ? '1st RTN' : '2nd P/O'}
      </div>
      <div className="flex justify-center items-center h-full w-[127px] bg-[#DDE2EC]">
        {`${splitedJobScheduleDate?.[1]}/${
          splitedJobScheduleDate?.[2]
        }/${splitedJobScheduleDate?.[0].slice(2)} ${job?.sche_time?.substring(
          0,
          5,
        )}`}
      </div>
      <div className="flex justify-center items-center h-full w-[48px] bg-[#DDE2EC]">
        {job?.timezone || ''}
      </div>
      <div className="flex justify-center items-center h-full w-[99px] bg-[#DDE2EC]">
        {job?.company || ''}
      </div>
      <div className="flex justify-center items-center h-full w-[126px] bg-[#DDE2EC]">
        {job?.wo_no || ''}
      </div>
      <div className="flex justify-center items-center h-full w-[126px] bg-[#DDE2EC]">
        {job?.cont_no || ''}
      </div>
      <div className="flex justify-center items-center h-full w-[68px] bg-[#DDE2EC]">
        {job?.cont_size || ''}
      </div>
      <div className="flex justify-center items-center text-center h-full w-[59px] bg-[#DDE2EC]">
        {job?.cont_type || ''}
      </div>
      <div className="flex justify-center items-center h-full w-[95px] bg-[#DDE2EC]">
        {job?.driver || ''}
      </div>
      <div className="flex justify-center items-center h-full w-[27px] pl-[3px] w-[24px] h-[24px]">
        {viewDualMoveContentJobRowDelete({
          dualMoveRowData,
          job,
          isModifiable,
        }) && (
          <CommonButton
            category="redDelete"
            customStyle={{
              width: '24px !important',
              height: '24px !important',
              padding: '3px',
            }}
            preventDupClick
            onClick={({ e, buttonRef }) => {
              e?.preventDefault();
              e?.stopPropagation();
              mutate?.({
                woNo: job?.wo_no,
                seq: job?.job_seq,
                isDual: true,
                buttonRef,
              });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default DualMoveModalContentJobRow;
