import tmsReportKeys from '../../../../hooks/TmsReport/keys';
import checkDateDiff from '../../../Common/Handler/checkDateDiff';
import generateQueryParams from '../Generator/generateQueryParams';

const searchContent = ({
  handleSubmit,
  errorModalOptions,
  setQueryObject,
  setOpenModal,
  queryObject,
  queryClient,
}) => {
  const { setIsSuccess, setErrorMessage, setIsErrorModalOpen } =
    errorModalOptions;

  const params = generateQueryParams({ queryObject });

  handleSubmit(submitData => {
    if (
      !checkDateDiff({
        fromDate: submitData?.['created-from'],
        toDate: submitData?.['created-to'],
      })
    ) {
      setIsSuccess(false);
      setErrorMessage(
        'The date range cannot exceed one year. Please try again.',
      );
      setIsErrorModalOpen(true);
      return;
    }
    setQueryObject(prev => ({
      ...prev,
      ...submitData,
    }));
    setOpenModal(false);
    queryClient.invalidateQueries({
      queryKey: tmsReportKeys.earlyWarningList({ queryObject: params }),
    });
  })();
};

export default searchContent;
