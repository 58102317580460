import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import CommonSelect from '../../../Inputs/CommonSelect/CommonSelect';
import CommonTextInput from '../../../Inputs/CommonTextInput/CommonTextInput';
import CommonDate from '../../../Inputs/CommonDate/CommonDate';
import CommonButton from '../../../CommonButton/CommonButton';
import { HYPEN_ICON } from '../../../../constants/Acct/Common/iconPath';
import {
  PERIOD_OPTIONS,
  WO_CATEGORY_OPTIONS,
  CATEGORY_OPTIONS,
} from '../../../../constants/TmsReport/EarlyWarning/inputOption';
import {
  changePeriod,
  changeSelect,
  changeText,
  changeRangeDate,
} from '../../../../utils/TmsReport/EarlyWarning/Handler/changedInputHandler';
import searchContent from '../../../../utils/TmsReport/EarlyWarning/Handler/searchContent';

const AdvancedSearchModalContent = ({
  queryObject,
  setOpenModal,
  setQueryObject,
  errorModalOptions,
}) => {
  const { setValue, watch, handleSubmit } = useFormContext();
  const queryClient = useQueryClient();

  const category = watch('cat');

  const [lastDateRange, setLastDateRange] = useState({});
  const [searchedCategoryOptions, setSearchedCategoryOptions] = useState([]);

  useEffect(() => {
    let key = '';
    if (!category) {
      key = 'ALL';
    } else {
      key = category === 'X' ? 'EXPORT' : 'OTHER';
    }

    setSearchedCategoryOptions(CATEGORY_OPTIONS?.[key]);
  }, [category]);

  return (
    <>
      <div className="bg-[#F9F9F9] w-[488px] h-[225px] px-[30px] py-[15px] flex flex-col justify-between">
        <div className="w-full flex flex-row">
          <div className="mr-[23px] w-[153px]">
            <CommonSelect
              label="WO CATEGORY"
              inputName="cat"
              defaultValue={queryObject?.cat || 'ALL'}
              option={WO_CATEGORY_OPTIONS}
              onChangeHandler={changeSelect}
            />
          </div>
        </div>
        <div className="w-full flex flex-row">
          <div className="mr-[23px] w-[153px]">
            <CommonSelect
              label="CATEGORY"
              inputName="searchedCategory"
              defaultValue={queryObject?.searchedCategory || ''}
              option={searchedCategoryOptions}
              onChangeHandler={changeSelect}
            />
          </div>
          <div className="w-[255px]">
            <CommonTextInput
              inputName="keyword"
              placeholder="Type here..."
              defaultValue={queryObject?.keyword || ''}
              onChangeHandler={changeText}
            />
          </div>
        </div>
        <div className="w-full flex flex-row mt-[10px]">
          <div className="mr-[20px] w-[153px]">
            <CommonSelect
              label="PERIOD"
              inputName="period"
              defaultValue={queryObject?.period || ''}
              option={PERIOD_OPTIONS}
              onChangeHandler={props =>
                changePeriod({ watch, setValue, setLastDateRange, ...props })
              }
            />
          </div>
          <div className="mr-[5px] w-[116px]">
            <CommonDate
              inputName="created-from"
              required
              defaultValue={queryObject?.['created-from']}
              onChangeHandler={props =>
                changeRangeDate({
                  watch,
                  setValue,
                  type: 'from',
                  lastDateRange,
                  ...props,
                })
              }
            />
          </div>
          <div className="mr-[5px] flex items-center">
            <img src={HYPEN_ICON} alt="hyphen-icon" width={13} />
          </div>
          <div className="w-[116px]">
            <CommonDate
              inputName="created-to"
              required
              defaultValue={queryObject?.['created-to']}
              onChangeHandler={props =>
                changeRangeDate({
                  watch,
                  setValue,
                  type: 'to',
                  lastDateRange,
                  ...props,
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-[30px]">
        <div className="mr-[18px]">
          <CommonButton category="cancel" onClick={() => setOpenModal?.(false)}>
            Cancel
          </CommonButton>
        </div>
        <CommonButton
          type="button"
          category="search"
          onClick={() =>
            searchContent({
              handleSubmit,
              errorModalOptions,
              setQueryObject,
              setOpenModal,
              queryObject,
              queryClient,
            })
          }
        >
          Search
        </CommonButton>
      </div>
    </>
  );
};
export default AdvancedSearchModalContent;
