import React, { useMemo } from 'react';
import { useIsFetching, useQueryClient } from '@tanstack/react-query';
import CommonButton from '../CommonButton/CommonButton';
import { PATH_REFRESH_CIRCLE_LOADING_ICON } from '../../constants/Common/path';

const RefreshButton = ({ queryKeyList }) => {
  const queryClient = useQueryClient();
  const isFetchingList = queryKeyList?.map(queryKey => {
    return useIsFetching({ queryKey });
  });

  const isLoading = useMemo(() => {
    return isFetchingList?.some(isFetching => !!isFetching);
  }, [isFetchingList]);

  return isLoading ? (
    <div className="flex flex-row gap-x-[10px] px-[10px] py-[5px] items-center">
      <div className="object-cover w-[15px] h-[13px]">
        <img alt="Loading" src={PATH_REFRESH_CIRCLE_LOADING_ICON} />
      </div>
      <div className="text-[12px] text-[#666666] font-[500]">Refresh</div>
    </div>
  ) : (
    <CommonButton
      category="refreshCircle"
      border="0"
      backgroundColor="transparent"
      color="#666666"
      onClick={() => {
        queryKeyList?.forEach(queryKey => {
          queryClient?.invalidateQueries({ queryKey });
        });
      }}
    >
      Refresh
    </CommonButton>
  );
};

export default RefreshButton;
