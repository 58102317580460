import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import getTerminalBooking from '../../../../services/Dispatch/Export/Booking/get/getTerminalBooking';
import dispatchKeys from '../../../Dispatch2/keys';
import { AVAILABLE_TERMINALS } from '../../../../constants/Dispatch/Export/Common/availableTerminals';

const useGetTerminalBooking = ({ terminalQueryObject, isEditMode }) => {
  const [terminalData, setTerminalData] = useState({});

  const { data, isError } = useQuery({
    queryFn: () =>
      getTerminalBooking({
        terminalCode: AVAILABLE_TERMINALS?.[terminalQueryObject?.terminal],
        params: terminalQueryObject,
      }),
    queryKey: dispatchKeys?.terminalBooking({ ...terminalQueryObject }),
    refetchOnWindowFocus: false,
    enabled:
      !isEditMode &&
      !!terminalQueryObject?.terminal &&
      !!terminalQueryObject?.refNo &&
      !!AVAILABLE_TERMINALS?.[terminalQueryObject?.terminal],
  });

  useEffect(() => {
    if (isError || !data?.data) {
      setTerminalData({});
    }

    if (data?.data) {
      setTerminalData(data?.data);
    }
  }, [data?.data, isError]);

  return { data: terminalData };
};

export default useGetTerminalBooking;
