import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import getCompanyList from '../../../services/Dispatch/Common/get/getCompanyList';
import checkIsDefaultYard from '../../../utils/Common/Handler/checkIsDefaultYard';
import handleCheckboxChange from '../../../utils/TmsReport/Common/handleCheckboxChange';
import dispatchKeys from '../../Dispatch2/keys';

const useGetYardList = ({ setYardQuery, setYardList, status }) => {
  const defaultDiv = localStorage.getItem('defaultDiv');
  const dispatchFilter = JSON.parse(localStorage.getItem('dispatchFilter'));
  const userID = localStorage.getItem('userId');

  const NGL_YARD_URL_PARAMS = { category: 8 };
  const result = useQuery({
    queryKey: dispatchKeys.companyNGLYard(),
    queryFn: () => getCompanyList({ urlParams: NGL_YARD_URL_PARAMS }),
    refetchOnWindowFocus: false,
  });

  /* Set yard query and list */
  useEffect(() => {
    if (
      !result?.isError &&
      result?.isSuccess &&
      Array.isArray(result?.data?.data) &&
      dispatchFilter
    ) {
      const yardQueryObject = dispatchFilter?.[userID]?.[status] || {};

      if (Object.keys(yardQueryObject).length === 0) {
        setYardQuery(
          Object.fromEntries(
            result.data?.data?.map(company => [
              company?.company,
              checkIsDefaultYard({ yard: company?.company, defaultDiv }),
            ]),
          ),
        );
      }

      setYardList(
        result.data?.data?.map(company => ({
          label: company?.company,
          value: company?.company,
          defaultValue:
            Object.keys(yardQueryObject).length !== 0
              ? yardQueryObject[company?.company]
              : checkIsDefaultYard({ yard: company?.company, defaultDiv }),
          onChange: ({ ...props }) =>
            handleCheckboxChange({ ...props, setQuery: setYardQuery }),
        })),
      );
    }
  }, [result?.isError, result?.isSuccess, status]);

  return result;
};

export default useGetYardList;
