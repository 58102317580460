import ediKeys from '../../../hooks/EDI/keys';

const KEY_NAMES = {
  322: 'get322List',
  204: 'get204List',
  MAIN: 'getMainList',
};

const getRefreshKey = ({ selectedEDI }) => {
  return ediKeys?.[KEY_NAMES[selectedEDI]]?.({})?.filter(item => !!item);
};

export default getRefreshKey;
