import styled from 'styled-components';

const StyledFieldWrapper = styled.div`
  height: 30px;

  ${({ isCursor }) => isCursor && 'cursor: pointer;'}

  ${({ width }) => width && `width: ${width}px;`}
  ${({ bgColor }) => bgColor && `background-color: ${bgColor};`}
  ${({ isShadow }) =>
    isShadow && `box-shadow: 0 0.5px 1px rgba(0, 0, 0, 0.26);`}

  @media (max-width: 960px) {
    ${({ shortWidth }) => shortWidth && `width: ${shortWidth}px;`}
    ${({ isHide }) => isHide && 'display: none;'}
  }
`;

export default StyledFieldWrapper;
