import React, { useEffect, useState } from 'react';
import { EQUIP_WO_STATUS_MAPPINGS } from '../../../../constants/BCDetail/Document';
import TobBarFieldItem from '../../Common/TobBarFieldItem';

const TopUtilityBar = ({ basicInfoData }) => {
  const woType = location.pathname.split('/')[3];

  const [equipInfo, setEquipInfo] = useState({ label: '', valueField: '' });

  useEffect(() => {
    const tempEquipInfo = EQUIP_WO_STATUS_MAPPINGS?.[woType];
    setEquipInfo(tempEquipInfo);
  }, [woType, basicInfoData]);

  return (
    <div className="flex w-full h-[30px] gap-x-[7px]">
      <TobBarFieldItem
        label="BILLTO"
        value={basicInfoData?.billto?.business_name}
        $styles={{ lineClampNum: 2 }}
      />
      <TobBarFieldItem
        label={equipInfo?.label}
        value={basicInfoData?.[equipInfo?.valueField]}
      />
      <TobBarFieldItem label="CHASSIS #" value={basicInfoData?.ch_no} />
    </div>
  );
};

export default TopUtilityBar;
