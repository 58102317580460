import React from 'react';
import StyledBCDetailCommonTopBarFieldItemWrapper from '../../../../styles/BCDetail/Common/TopBar/StyledBCDetailCommonTopBarFieldItemWrapper';
import StyledBCDetailCommonTopBarFieldItemLabel from '../../../../styles/BCDetail/Common/TopBar/StyledBCDetailCommonTopBarFieldItemLabel';
import StyledBCDetailCommonTopBarFieldItemValue from '../../../../styles/BCDetail/Common/TopBar/StyledBCDetailCommonTopBarFieldItemValue';
import StyledBCDetailCommonTopBarFieldItemValueSpan from '../../../../styles/BCDetail/Common/TopBar/StyledBCDetailCommonTopBarFieldItemValueSpan';

const TobBarFieldItem = ({ label, value, ...props }) => {
  return (
    <StyledBCDetailCommonTopBarFieldItemWrapper>
      <StyledBCDetailCommonTopBarFieldItemLabel {...props}>
        {label}
      </StyledBCDetailCommonTopBarFieldItemLabel>
      <StyledBCDetailCommonTopBarFieldItemValue {...props}>
        <StyledBCDetailCommonTopBarFieldItemValueSpan {...props}>
          {value || '-'}
        </StyledBCDetailCommonTopBarFieldItemValueSpan>
      </StyledBCDetailCommonTopBarFieldItemValue>
    </StyledBCDetailCommonTopBarFieldItemWrapper>
  );
};

export default TobBarFieldItem;
