import handleQueryError from '../../../../../utils/Common/Handler/handleQueryError';
import terminalAPI from '../../../terminalAPI';

const getTerminalBooking = async ({ terminalCode, params }) => {
  try {
    const response = await terminalAPI.get(`${terminalCode}/booking`, {
      params,
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getTerminalBooking;
