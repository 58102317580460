import React, { useEffect, useState } from 'react';
import { DISPATCH_PAGE_EXPORT_BOOKING } from '../../../constants/Dispatch/Common/category';
import EXCEL_FILE_NAMES from '../../../constants/Dispatch/Common/excelFileNames';
import { YARD_TAB } from '../../../constants/Dispatch/Common/filterButtonSettings';
import dispatchKeys from '../../../hooks/Dispatch2/keys';
import saveFilter from '../../../utils/Common/CommonFilter/saveFilter';
import exportToExcel from '../../../utils/Common/Datagrid/Handler/exportToExcel';
import updateButtonDisabled from '../../../utils/Common/Handler/updateButtonDisabled';
import createFilter from '../../../utils/Dispatch/Common/Generator/createFilter';
import CommonFilter from '../../Common/CommonFilter/index';
import CommonButton from '../../CommonButton/CommonButton';
import RefreshButton from '../../RefreshButton';

const HeaderButtons = ({
  division,
  status,
  setCustomizeCols,
  yardList,
  yardQueryObject,
  setYardQuery,
  subFilter,
  category,
  ...props
}) => {
  /** Filter related status */
  const [filterOpen, setFilterOpen] = useState(false);
  const [filters, setFilters] = useState(null);

  useEffect(() => {
    if (subFilter === YARD_TAB) {
      setFilters(() => [
        ...createFilter({ filters, setFilters, yardList, status }),
      ]);
    }
  }, [yardList, status, subFilter]);

  useEffect(() => {
    const newYardQuery = {};
    filters?.[0]?.items?.map(item => {
      newYardQuery[item?.value] = item?.isSelected;
      return item;
    });

    setYardQuery(newYardQuery);
  }, [filters]);

  return (
    <div className="flex mt-[20px] mb-[15px] pr-[40px] justify-end gap-x-[5px]">
      <RefreshButton
        queryKeyList={[
          dispatchKeys?.listDiv({ division }),
          dispatchKeys?.countDiv({ division }),
          dispatchKeys.bookingAll,
        ]}
      />
      {subFilter === YARD_TAB && !_.isEmpty(yardQueryObject) && (
        <>
          <CommonFilter
            open={filterOpen}
            setOpen={setFilterOpen}
            filters={filters}
            onSave={() =>
              saveFilter({
                storageName: 'dispatchFilter',
                filters,
                setOpen: setFilterOpen,
              })
            }
          />
          <div className="flex h-[14px] border-l-[0.4px] border-[#00000099] self-center" />
        </>
      )}
      <CommonButton
        category="greySearch"
        onClick={() => {
          props.setAdvancedSearchOpen(true);
        }}
        border="0"
        backgroundColor="transparent"
        color="#666666"
      >
        Search
      </CommonButton>
      {status !== DISPATCH_PAGE_EXPORT_BOOKING && (
        <>
          <div className="flex h-[14px] border-l-[0.4px] border-[#00000099] self-center" />
          <CommonButton
            category="customizeColumn"
            onClick={() => {
              setCustomizeCols(true);
            }}
            border="0"
            backgroundColor="transparent"
            color="#666666"
          >
            Customize Columns
          </CommonButton>
        </>
      )}
      <div className="flex gap-x-[10px]">
        {status === DISPATCH_PAGE_EXPORT_BOOKING && (
          <div>
            <CommonButton
              category="add"
              onClick={() => {
                props.setTabModalOpen(true);
                props.setTabIndexOpen(0);
              }}
            >
              Add
            </CommonButton>
          </div>
        )}
        {status === DISPATCH_PAGE_EXPORT_BOOKING && (
          <div>
            {props?.queryObject?.status === 'inactive' ? (
              <CommonButton
                category="inactive"
                borderColor="#B74747"
                preventDupClick
                onClick={({ buttonRef }) => {
                  const selectedBookingList = props?.gridRef?.current?.api
                    ?.getSelectedRows()
                    .map(row => row.seq);
                  if (selectedBookingList.length > 0) {
                    props?.handleBookingListIncomplete({
                      bookingList: selectedBookingList,
                      buttonRef,
                    });
                  } else {
                    updateButtonDisabled({ ref: buttonRef, disabled: false });
                  }
                }}
              >
                Incomplete
              </CommonButton>
            ) : (
              <CommonButton
                category="complete"
                preventDupClick
                onClick={({ buttonRef }) => {
                  const selectedBookingList = props?.gridRef?.current?.api
                    ?.getSelectedRows()
                    .map(row => row.seq);
                  if (selectedBookingList.length > 0) {
                    props?.handleBookingListComplete({
                      bookingList: selectedBookingList,
                      buttonRef,
                    });
                  } else {
                    updateButtonDisabled({ ref: buttonRef, disabled: false });
                  }
                }}
              >
                Complete
              </CommonButton>
            )}
          </div>
        )}
        <div>
          {status !== DISPATCH_PAGE_EXPORT_BOOKING && (
            <CommonButton
              category="excel"
              onClick={() => {
                exportToExcel({
                  gridRef: props?.gridRef,
                  fileName: EXCEL_FILE_NAMES?.[status],
                });
              }}
            >
              EXCEL
            </CommonButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderButtons;
