import {
  CELL_TYPE_AUTO_COMPLETE,
  CELL_TYPE_SWITCH,
  CELL_TYPE_TEXT,
} from '../../../../constants/Common/DataGrid/cellType';
import { REGEX_NUMBER_ONLY_POSITIVE_DECIMAL } from '../../../../constants/Common/regex';
import cellEditorGenerator from '../../../Common/Datagrid/Generator/cellEditorGenerator';
import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';
import { makePrettyPhoneNumber } from '../../../Common/Handler/phoneNumberHandler';
import handleActiveSwitchClick from '../Handler/handleActiveSwitchClick';
import handleAddressChange from '../Handler/handleAddressChange';

const createDIVColumnDefs = ({
  disabledRef,
  patchActivateMutator,
  title,
  active,
  getPlacePredictions,
  ...modalProps
}) => {
  return [
    {
      headerName: 'ADDRESS',
      field: 'address',
      width: 215,
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_AUTO_COMPLETE,
          onInputChangeHandler: ({ newInputValue }) =>
            getPlacePredictions({ input: newInputValue }),
          onChangeHandler: ({ ...props }) => {
            handleAddressChange({ ...props, ...modalProps });
          },
        }),
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_AUTO_COMPLETE,
          onInputChangeHandler: ({ newInputValue }) =>
            getPlacePredictions({ input: newInputValue }),
          onChangeHandler: ({ ...props }) => {
            handleAddressChange({ ...props, ...modalProps });
          },
        }),
    },
    {
      headerName: 'CITY',
      field: 'city',
      width: 120,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          maxLength: 10,
          disabled: true,
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          maxLength: 10,
          disabled: true,
        }),
    },
    {
      headerName: 'STATE',
      field: 'state',
      width: 100,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          disabled: true,
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          maxLength: 2,
          disabled: true,
        }),
    },
    {
      headerName: 'ZIP',
      field: 'zip',
      width: 100,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          maxLength: 5,
          regex: REGEX_NUMBER_ONLY_POSITIVE_DECIMAL,
          disabled: true,
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          maxLength: 5,
          regex: REGEX_NUMBER_ONLY_POSITIVE_DECIMAL,
          disabled: true,
        }),
    },
    {
      headerName: 'TIME ZONE',
      field: 'timezone',
      width: 180,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          disabled: true,
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          regex: gridParam?.data?.timezone,
          disabled: true,
        }),
    },
    {
      headerName: 'EMAIL',
      field: 'email',
      width: 180,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          maxLength: 100,
        }),
    },
    {
      headerName: 'PHONE',
      field: 'phone',
      width: 120,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          placeHolder: '+1-000 0123456789',
        }),
      valueGetter: gridParam => {
        const newPhone = gridParam?.data?.phone?.startsWith('+')
          ? gridParam?.data?.phone
          : makePrettyPhoneNumber({
              phone: gridParam?.data?.phone,
            });
        gridParam.data.phone = newPhone;
        return newPhone;
      },
    },
    {
      headerName: 'ABBR',
      field: 'div',
      width: 110,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          maxLength: 3,
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          maxLength: 3,
        }),
      editable: false,
    },
    {
      headerName: 'WO RULE',
      field: 'wo_rule',
      width: 110,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          maxLength: 2,
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          maxLength: 2,
        }),
      editable: false,
    },
    {
      headerName: '',
      field: 'active',
      width: 100,
      sortable: false,
      resizable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_SWITCH,
          onChangeHandler: ({ ...props }) => {
            handleActiveSwitchClick({
              disabledRef,
              title,
              gridParam,
              mutator: patchActivateMutator,
              active,
              ...props,
            });
          },
          value: gridParam?.node?.rowPinned ? true : gridParam?.data?.active,
        }),
      editable: false,
      cellStyle: {
        justifyContent: 'center',
      },
    },
  ];
};

export default createDIVColumnDefs;
