import updateButtonDisabled from '../../../Common/Handler/updateButtonDisabled';

const checkType = (documentList, type) => {
  const index = documentList.findIndex(
    list => list.type.toUpperCase() === type.toUpperCase(),
  );
  if (index !== -1) {
    return true;
  }
  return false;
};

const resetFields = (id, resetField) => {
  resetField(`${id}`, {
    defaultValue: {
      type: '',
      checked: false,
    },
  });

  resetField(`file.${id}`, {
    defaultValue: {
      newRow: null,
      newRow_file_name: '',
    },
  });

  document.getElementById(`file.${id}.newRow_file_name`).value = '';
  document.getElementById(`file.${id}.newRow`).value = null;
};

const handleRowAdd = async ({
  submitData,
  resetField,
  setMessageModalOpen,
  setMessageContent,
  woNo,
  id,
  documentList,
  documentMutator,
  buttonRef,
}) => {
  const newData = submitData[id];
  newData.file_url = '';
  newData.updated_date = '';
  newData.updated_by = '';
  newData.accuracy = null;

  const newFile = submitData.file[id].newRow;

  if (checkType(documentList, newData.type)) {
    setMessageContent("Can't use the same document name under the list");
    setMessageModalOpen(true);
    updateButtonDisabled({ ref: buttonRef, disabled: false });
    return false;
  }

  const newDocumentList = [...documentList]?.map(doc => ({
    ...doc,
    accuracy: !isNaN(doc?.accuracy) ? doc?.accuracy : null,
  }));

  const docData = {
    doc: JSON.stringify([...newDocumentList, newData]),
    [newData.type]: newFile,
  };

  resetFields(id, resetField);

  documentMutator({
    woNo,
    docData,
    buttonRef,
  });

  return true;
};

export default handleRowAdd;
