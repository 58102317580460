import React from 'react';

const AvailablePDFPreview = ({ fileURL }) => {
  return (
    <div className="w-full h-full">
      <iframe src={fileURL} width="100%" height="100%" title="PDF Preview" />
    </div>
  );
};

export default AvailablePDFPreview;
