import { COLUMN_WIDTHS_STYLE } from '../../../../styles/Dispatch/BookingModal';

const generateBookingContainerTable = ({
  isEditMode,
  isDisabled,
  size,
  type,
  setValue,
  getValues,
}) => {
  const IS_DISABLED_COUNT = true;

  const addingBookingTable = [
    [
      { data: 'CONTAINER', header: true, width: COLUMN_WIDTHS_STYLE?.CONT },
      {
        data: 'ISO CODE',
        header: true,
        width: COLUMN_WIDTHS_STYLE?.ISO_CODE,
      },
      { data: 'SIZE', header: true, width: COLUMN_WIDTHS_STYLE?.SIZE },
      { data: 'TYPE', header: true, width: COLUMN_WIDTHS_STYLE?.TYPE },
      { data: 'TOTAL', header: true, width: COLUMN_WIDTHS_STYLE?.TOTAL },
      { data: 'USED', header: true, width: COLUMN_WIDTHS_STYLE?.USED },
      { data: 'BALANCE', header: true, width: COLUMN_WIDTHS_STYLE?.BALANCE },
    ],
    [
      {
        data: `1ST CONT`,
        header: true,
        required: true,
        width: COLUMN_WIDTHS_STYLE?.CONT,
      },
      {
        data: '',
        InputName: 'iso_code_1',
        component: 'text',
        disabled: true,
        width: COLUMN_WIDTHS_STYLE?.ISO_CODE,
        alignText: 'center',
      },
      {
        data: '',
        InputName: 'cont_size_1',
        component: 'select',
        required: true,
        disabled: isDisabled,
        option: size || [],
        width: COLUMN_WIDTHS_STYLE?.SIZE,
        onChangeHandler: ({ e, onChange, selectProps, ...handlerProps }) => {
          onChange(e?.target?.value);
        },
      },
      {
        data: '',
        InputName: 'cont_type_1',
        component: 'select',
        required: true,
        disabled: isDisabled,
        option: type || [],
        width: COLUMN_WIDTHS_STYLE?.TYPE,
        onChangeHandler: ({ e, onChange, selectProps, ...handlerProps }) => {
          onChange(e?.target?.value);
        },
      },
      {
        data: 0,
        InputName: 'total_1',
        component: 'text',
        required: true,
        disabled: isDisabled,
        width: COLUMN_WIDTHS_STYLE?.TOTAL,
        handleBalance: () => {
          setValue(
            'balance_1',
            getValues('total_1') - (getValues('used_1') || 0),
          );
        },
      },
      {
        data: 0,
        InputName: 'used_1',
        component: 'text',
        required: true,
        disabled: IS_DISABLED_COUNT,
        width: COLUMN_WIDTHS_STYLE?.USED,
      },
      {
        data: 0,
        InputName: 'balance_1',
        disabled: IS_DISABLED_COUNT,
        component: 'text',
        required: true,
        width: COLUMN_WIDTHS_STYLE?.BALANCE,
      },
    ],
    [
      { data: `2ND CONT`, header: true, width: COLUMN_WIDTHS_STYLE?.CONT },
      {
        data: '',
        InputName: 'iso_code_2',
        component: 'text',
        disabled: true,
        width: COLUMN_WIDTHS_STYLE?.ISO_CODE,
      },
      {
        data: '',
        InputName: 'cont_size_2',
        component: 'select',
        disabled: isDisabled,
        option: size || [],
        width: COLUMN_WIDTHS_STYLE?.SIZE,
        onChangeHandler: ({ e, onChange, selectProps, ...handlerProps }) => {
          onChange(e?.target?.value);
        },
      },
      {
        data: '',
        InputName: 'cont_type_2',
        component: 'select',
        disabled: isDisabled,
        option: type || [],
        width: COLUMN_WIDTHS_STYLE?.TYPE,
        onChangeHandler: ({ e, onChange, selectProps, ...handlerProps }) => {
          onChange(e?.target?.value);
        },
      },
      {
        data: 0,
        InputName: 'total_2',
        component: 'text',
        disabled: isDisabled,
        width: COLUMN_WIDTHS_STYLE?.TOTAL,
        handleBalance: () => {
          setValue(
            'balance_2',
            getValues('total_2') - (getValues('used_2') || 0),
          );
        },
      },
      {
        data: 0,
        InputName: 'used_2',
        component: 'text',
        disabled: IS_DISABLED_COUNT,
        width: COLUMN_WIDTHS_STYLE?.USED,
      },
      {
        data: 0,
        InputName: 'balance_2',
        component: 'text',
        disabled: IS_DISABLED_COUNT,
        width: COLUMN_WIDTHS_STYLE?.BALANCE,
      },
    ],
    [
      { data: `3RD CONT`, header: true, width: COLUMN_WIDTHS_STYLE?.CONT },
      {
        data: '',
        InputName: 'iso_code_3',
        component: 'text',
        disabled: true,
        width: COLUMN_WIDTHS_STYLE?.ISO_CODE,
      },
      {
        data: '',
        InputName: 'cont_size_3',
        component: 'select',
        disabled: isDisabled,
        option: size || [],
        width: COLUMN_WIDTHS_STYLE?.SIZE,
        onChangeHandler: ({ e, onChange, selectProps, ...handlerProps }) => {
          onChange(e?.target?.value);
        },
      },
      {
        data: '',
        InputName: 'cont_type_3',
        component: 'select',
        disabled: isDisabled,
        option: type || [],
        width: COLUMN_WIDTHS_STYLE?.TYPE,
        onChangeHandler: ({ e, onChange, selectProps, ...handlerProps }) => {
          onChange(e?.target?.value);
        },
      },
      {
        data: 0,
        InputName: 'total_3',
        component: 'text',
        disabled: isDisabled,
        width: COLUMN_WIDTHS_STYLE?.TOTAL,
        handleBalance: () => {
          setValue(
            'balance_3',
            getValues('total_3') - (getValues('used_3') || 0),
          );
        },
      },
      {
        data: 0,
        InputName: 'used_3',
        component: 'text',
        disabled: IS_DISABLED_COUNT,
        width: COLUMN_WIDTHS_STYLE?.USED,
      },
      {
        data: 0,
        InputName: 'balance_3',
        component: 'text',
        disabled: IS_DISABLED_COUNT,
        width: COLUMN_WIDTHS_STYLE?.BALANCE,
      },
    ],
  ];

  if (!isEditMode) {
    return [...addingBookingTable];
  }

  const editBookingTable = addingBookingTable?.map(row => {
    /** Remove ISO Code Column */
    const excludeISOCodeRow = row?.filter((_, rowIndex) => rowIndex !== 1);

    /** Remove Width */
    const newRow = excludeISOCodeRow?.map(item => {
      const { width, ...newItem } = item;
      return { ...newItem };
    });
    return [...newRow];
  });

  return [...editBookingTable];
};

export default generateBookingContainerTable;
