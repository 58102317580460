import { useEffect, useState } from 'react';
import { debounce } from 'lodash';

const useControlWindowSize = () => {
  const getWindowSize = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
  };

  const [windowSizes, setWindowSizes] = useState({ ...getWindowSize() });

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowSizes(getWindowSize());
    }, 100);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSizes;
};

export default useControlWindowSize;
