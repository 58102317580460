export const createProductCodeSelectOption = list => {
  const result = [];

  list?.forEach((element, idx) => {
    result.push({
      key: idx,
      label: `${element?.product_code} / ${element?.product_code_name}`,
      value: `${element?.product_code} / ${element?.product_code_name}`,
      seq: element?.seq,
    });
  });

  return result;
};

export const createDriverSelectOption = list => {
  const result = [];

  list?.forEach((element, idx) => {
    result.push({
      key: idx,
      label: `${element?.id} / ${element?.name}`,
      value: element?.id,
    });
  });

  return result;
};

export const createCompanySelectOption = list => {
  const result = [];

  list?.forEach((element, idx) => {
    result.push({
      key: idx,
      label: `${element?.company} / ${element?.business_name}`,
      value: element?.company,
      credit: element?.credit_hold,
    });
  });

  return result;
};
