import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import CommonDataGrid from '../../../components/CommonDataGrid';
import { GRID_MOD_EDIT } from '../../../constants/Common/DataGrid/setting';
import getColumnDefs from '../../../utils/DailyReport/Common/Generator/getColumDefs';
import handleSave from '../../../utils/DailyReport/Common/Handler/handleSave';
import Header from '../../../components/DailyReport/Header';
import { createGridSelectOption } from '../../../utils/Common/createFloatingFilterSelectOption';
import APModal from '../../../components/DailyReport/APModal';
import DriverModal from '../../../components/DailyReport/DriverModal';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import useGetAPInput from '../../../hooks/DailyReport/Get/useGetAPInput';
import useGetDriverList from '../../../hooks/DailyReport/Get/useGetDriverList';
import useGetDailySchedule from '../../../hooks/DailyReport/Get/useGetDailySchedule';
import getDailyReportRowStyle from '../../../utils/DailyReport/DailyReport/Generator/getDailyReportRowStyle';
import usePatchDailySchedule from '../../../hooks/DailyReport/Patch/usePatchDailySchedule';
import handleClick from '../../../utils/DailyReport/Common/Handler/handleClick';
import { DAILY_REPORT_PAGE_TYPE } from '../../../constants/DailyReport/pageType';
import handleEditingStart from '../../../utils/DailyReport/Common/Handler/handleEditingStart';
import handleEditingStop from '../../../utils/DailyReport/Common/Handler/handleEditingStop';
import TotalBottomBar from '../../../components/Common/CommonTotalBottomBar';
import useControlTotalInfo from '../../../hooks/Common/TotalBottomRow/useControlTotalInfo';

const DailyReport = ({ page, isEditable }) => {
  const gridRef = useRef(null);
  const location = useLocation();

  /* Options for Grid */
  const getPool = useSelector(state => state.common.pool);
  const [gridPool, setGridPool] = useState(
    createGridSelectOption(getPool?.map(item => item.pool)),
  );
  useEffect(() => {
    setGridPool(createGridSelectOption(getPool?.map(item => item.pool)));
  }, [getPool]);

  const [isLoadingOpen, setIsLoadingOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [messageModalType, setMessageModalType] = useState(false);

  /* States for Row Edit */
  const [woNo, setWONo] = useState('');
  const [beforeEditData, setBeforeEditData] = useState({});

  /* States for Driver/AP Modal */
  const [isDriverModalOpen, setIsDriverModalOpen] = useState(false);
  const [isAPModalOpen, setIsAPModalOpen] = useState(false);
  const [selectedDriverData, setSelectedDriverData] = useState(null);
  const [selectedScheduleSeq, setSelectedScheduleSeq] = useState(null);

  const [focusedCell, setFocusedCell] = useState({});
  const [clickFocusedCell, setClickFocusedCell] = useState(null);

  const restoreFocusedCell = ({ ...props }) => {
    if (focusedCell?.rowIndex !== null && focusedCell?.column !== null) {
      setTimeout(() => {
        gridRef?.current?.api?.ensureIndexVisible(focusedCell.rowIndex);
        gridRef?.current?.api?.ensureColumnVisible(focusedCell.column);
        gridRef?.current?.api?.setFocusedCell({
          rowIndex: focusedCell.rowIndex,
          colKey: focusedCell.column,
        });
      }, 0);
    }
  };

  const {
    apInputData,
    refetch: getAPRefetcher,
    isFetching: isFetchingGetAP,
    isLoading: isLoadingGetAP,
    isError: isErrorGetAP,
  } = useGetAPInput({
    page: DAILY_REPORT_PAGE_TYPE?.[page],
    woNo,
    scheduleSeq: selectedScheduleSeq,
    driver: selectedDriverData?.driver,
    setIsErrorModalOpen,
    setErrorMessage,
    setIsLoadingOpen,
  });

  const { driverList, isPending: isPendingDriverList } = useGetDriverList({
    woNo,
    page: DAILY_REPORT_PAGE_TYPE?.[page],
  });

  const mutateRow = usePatchDailySchedule({
    setErrorMessage,
    setIsErrorModalOpen,
    gridRef,
    focusedCell,
    restoreFocusedCell,
  });

  const handleRowEditingStart = ({ gridParam }) => {
    handleEditingStart({
      gridRef,
      gridParam,
      setClickFocusedCell,
      setBeforeEditData,
      page,
    });
  };

  const handleRowEditingStop = ({ gridParam }) => {
    handleEditingStop({ gridParam, page });
  };

  const handleRowValueChanged = gridParam => {
    handleSave({
      mutate: mutateRow.mutate,
      gridParam,
      beforeEditData,
      page,
      isEditable,
      setFocusedCell,
      setErrorMessage,
      setIsErrorModalOpen,
    });
  };

  const handleCellClicked = ({ gridParam }) => {
    handleClick({
      gridParam,
      clickFocusedCell,
      setClickFocusedCell,
      page,
      isEditable,
    });
  };

  const [filterQuery, setFilterQuery] = useState({});
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const newFilterQuery = {};
    searchParams.forEach((value, key) => {
      if (key !== 'date') {
        if (!newFilterQuery[key]) {
          newFilterQuery[key] = {};
        }
        newFilterQuery[key][value] = true;
      }
    });

    setFilterQuery(newFilterQuery);
  }, [location]);

  const [dateQuery, setDateQuery] = useState({});

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const date = searchParams.get('date') || dayjs().format('YYYY-MM-DD');
    if (date) {
      setDateQuery?.(date);
    }
  }, [location]);

  const list = useGetDailySchedule({
    date: dateQuery,
    filterQuery,
    type: DAILY_REPORT_PAGE_TYPE?.[page],
    setErrorMessage,
    setIsErrorModalOpen,
  });

  const { totalRows, setChangeFlag } = useControlTotalInfo({
    gridRef,
    queryData: list?.data,
  });

  const columnDefsParams = {
    page,
    setIsDriverModalOpen,
    setWONo,
    setSelectedScheduleSeq,
    setIsMessageModalOpen: setIsErrorModalOpen,
    setMessageModalMessage: setErrorMessage,
    setMessageModalType,
    setIsLoadingOpen,
  };

  const [columnDefs, setColumnDefs] = useState(
    getColumnDefs({
      editParam: { pool: gridPool },
      isEditable,
      ...columnDefsParams,
    }),
  );
  useEffect(() => {
    setColumnDefs(
      getColumnDefs({
        editParam: { pool: gridPool },
        isEditable,
        ...columnDefsParams,
      }),
    );
  }, [gridPool, isEditable]);

  return (
    <>
      <div className="w-full h-full pl-[55px] pt-[18px] flex flex-col pb-8">
        <Header
          gridRef={gridRef}
          date={dateQuery}
          setDate={setDateQuery}
          page={page}
          filterQuery={filterQuery}
        />
        <div className="w-full h-full pr-[40px]">
          <CommonDataGrid
            gridRef={gridRef}
            data={list?.data || []}
            columnDefs={columnDefs}
            modType={isEditable ? GRID_MOD_EDIT : undefined}
            onCellClicked={handleCellClicked}
            onRowValueChanged={handleRowValueChanged}
            onRowEditingStart={handleRowEditingStart}
            onRowEditingStop={handleRowEditingStop}
            undoRedoCellEditing
            undoRedoCellEditingLimit={1}
            getRowStyle={getDailyReportRowStyle}
            onFilterChanged={() => {
              setChangeFlag(prev => !prev);
            }}
            onSelectionChanged={() => {
              setChangeFlag(prev => !prev);
            }}
          />
          <TotalBottomBar totalRows={totalRows} />
        </div>
      </div>
      <DriverModal
        setWONo={setWONo}
        isDriverAPModalOpen={isDriverModalOpen}
        setDriverAPModalOpen={setIsDriverModalOpen}
        setAPModalOpen={setIsAPModalOpen}
        setSelectedScheduleSeq={setSelectedScheduleSeq}
        setSelectedDriverData={setSelectedDriverData}
        getAPRefetcher={getAPRefetcher}
        driverList={driverList}
        isPendingDriverList={isPendingDriverList}
        selectedDriverData={selectedDriverData}
        isFetchingGetAP={isFetchingGetAP}
        isErrorGetAP={isErrorGetAP}
      />
      <APModal
        woNo={woNo}
        setWONo={setWONo}
        isAPModalOpen={isAPModalOpen}
        setAPModalOpen={setIsAPModalOpen}
        setSelectedScheduleSeq={setSelectedScheduleSeq}
        setSelectedDriverData={setSelectedDriverData}
        isFetchingGetAP={isFetchingGetAP}
        isErrorGetAP={isErrorGetAP}
        apInputData={apInputData}
        selectedScheduleSeq={selectedScheduleSeq}
        setMessageModalOpen={setIsErrorModalOpen}
        setMessageModalMessage={setErrorMessage}
        setLoadingOpen={setIsLoadingOpen}
        gridRef={gridRef}
        page={DAILY_REPORT_PAGE_TYPE?.[page]}
      />
      <CommonErrorModal
        open={isErrorModalOpen}
        setIsOpen={setIsErrorModalOpen}
        message={errorMessage}
      />
      <CommonLoading
        open={isLoadingOpen || list?.isLoading || isLoadingGetAP}
      />
    </>
  );
};

export default DailyReport;
