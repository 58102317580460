import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import CommonButton from '../../../components/CommonButton/CommonButton';
import CommonDataGrid from '../../../components/CommonDataGrid/index';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import { GRID_MOD_BOTH } from '../../../constants/Common/DataGrid/setting';
import useDeleteAPRateMutation from '../../../hooks/Master/APRate/useDeleteAPRateMutation';
import useGetAPRateList from '../../../hooks/Master/APRate/useGetAPRateList';
import usePostAPRateMutation from '../../../hooks/Master/APRate/usePostAPRateMutation';
import useUpdateAPRateMutation from '../../../hooks/Master/APRate/useUpdateAPRateMutation';
import useGetNglCneeList from '../../../hooks/Master/Common/useGetNglCneeList';
import StyledMasterCommonButtonContainer from '../../../styles/Master/Common/DataGrid/StyledMasterCommonButtonContainer';
import StyledMasterCommonDataGridContainer from '../../../styles/Master/Common/DataGrid/StyledMasterCommonDataGridContainer';
import exportToExcel from '../../../utils/Common/Datagrid/Handler/exportToExcel';
import createMasterAPRateColDefs from '../../../utils/Master/APRate/Generator/createMasterAPRateColDefs';
import createCompanyDropdown from '../../../utils/Master/Common/createCompanyDropdown';
import mutationHandler from '../../../utils/Master/Common/mutationHandler';
import setResultData from '../../../utils/Master/Common/setResultData';
import TotalBottomBar from '../../../components/Common/CommonTotalBottomBar';
import useControlTotalInfo from '../../../hooks/Common/TotalBottomRow/useControlTotalInfo';
import RefreshButton from '../../../components/RefreshButton';
import masterKeys from '../../../hooks/Master/keys';

const APRate = () => {
  const div = useSelector(state => state.nowDiv);
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isMessageOpen, setIsMessageOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [nglCneeDropdownList, setNglCneeDropdownList] = useState([]);

  const gridRef = useRef(null);

  const { loadingAPList, apRateList, isRefetching, errorAPRateList } =
    useGetAPRateList({ div });
  const { loadingNglCneeList, nglCneeList, errorNglCneeList } =
    useGetNglCneeList();

  const updateAPRateMutation = useUpdateAPRateMutation({
    setIsMessageOpen,
    setMessage,
    div,
    setIsSuccess,
    gridRef,
  });
  const postAPRateMutation = usePostAPRateMutation({
    setIsMessageOpen,
    setMessage,
    gridRef,
    div,
    setIsSuccess,
  });
  const deleteAPRateMutation = useDeleteAPRateMutation({
    setIsMessageOpen,
    setMessage,
    div,
    setIsSuccess,
  });

  const [columnDefs, setColumnDefs] = useState(createMasterAPRateColDefs());

  useEffect(() => {
    setColumnDefs(createMasterAPRateColDefs(nglCneeDropdownList));
  }, [nglCneeDropdownList]);

  useEffect(() => {
    if (errorAPRateList || errorNglCneeList) {
      setIsLoading(false);
      setMessage('Error occurred while fetching data.');
      setIsMessageOpen(true);
    }

    if (!loadingAPList && !loadingNglCneeList) {
      setNglCneeDropdownList(createCompanyDropdown(nglCneeList));
      setResultData(setList, setIsLoading, apRateList, nglCneeList);
    }
  }, [loadingAPList, loadingNglCneeList, isRefetching]);

  const { totalRows, setChangeFlag } = useControlTotalInfo({
    gridRef,
    queryData: list,
  });

  return (
    <>
      <CommonLoading open={isLoading} />
      {!isSuccess && (
        <CommonErrorModal
          open={isMessageOpen}
          setIsOpen={setIsMessageOpen}
          message={message}
          isSuccess={isSuccess}
        />
      )}
      {!isLoading && (
        <div className="flex flex-col h-full">
          <StyledMasterCommonButtonContainer>
            <RefreshButton
              queryKeyList={[
                masterKeys?.apRateList({ div }),
                masterKeys?.customerList(),
                masterKeys?.nglCneeList(),
              ]}
            />
            <CommonButton
              onClick={() => exportToExcel({ gridRef, fileName: 'AP RATE' })}
              type="button"
              category="excel"
            >
              EXCEL
            </CommonButton>
          </StyledMasterCommonButtonContainer>
          <StyledMasterCommonDataGridContainer>
            <CommonDataGrid
              addable
              modType={GRID_MOD_BOTH}
              gridRef={gridRef}
              data={list}
              columnDefs={columnDefs}
              onSaveClicked={props =>
                mutationHandler(updateAPRateMutation, props, false)
              }
              onAddClicked={props =>
                mutationHandler(postAPRateMutation, props, false)
              }
              onDeleteClicked={props =>
                mutationHandler(deleteAPRateMutation, props, true)
              }
              onFilterChanged={() => {
                setChangeFlag(prev => !prev);
              }}
              onSelectionChanged={() => {
                setChangeFlag(prev => !prev);
              }}
              onGridReady={() => {
                setChangeFlag(prev => !prev);
              }}
            />
            <TotalBottomBar totalRows={totalRows} />
          </StyledMasterCommonDataGridContainer>
        </div>
      )}
    </>
  );
};

export default APRate;
