import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DISPATCH_TYPE_BOOKING } from '../../../constants/Dispatch/Common/category';
import {
  ACTIVE_TAB,
  TOTAL_TAB,
} from '../../../constants/Dispatch/Common/filterButtonSettings';
import generateInitAdvancedForm from '../../../utils/Dispatch/Common/Generator/generateInitAdvancedForm';
import getFilterButtonData from '../../../utils/Dispatch/Common/Generator/getFilterButtonData';
import isPathValid from '../../../utils/Dispatch/Common/Generator/isPatchValid';

const useControlQueries = ({ woStatus, category, division, status, path }) => {
  const navigate = useNavigate();
  const dispatchFilter = JSON.parse(localStorage.getItem('dispatchFilter'));
  const userID = localStorage.getItem('userId');

  /* Queries  init */
  const [queryObject, setQueryObject] = useState(
    generateInitAdvancedForm({ woStatus, category }),
  );
  const [yardQuery, setYardQuery] = useState(
    dispatchFilter?.[userID]?.[status] || {},
  );

  useEffect(() => {
    setYardQuery(dispatchFilter?.[userID]?.[status] || {});
  }, [status]);

  const [subFilter, setSubFilter] = useState(
    woStatus === DISPATCH_TYPE_BOOKING ? ACTIVE_TAB : TOTAL_TAB,
  );

  const [allQueries, setAllQueries] = useState({
    division,
    status,
    category,
    queryObject,
    yardQueryObject: yardQuery,
  });

  /* Adjust queries */
  useEffect(() => {
    const initQueries = {
      category,
      status,
      queryObject: generateInitAdvancedForm({ woStatus, category }),
      yardQueryObject: yardQuery,
    };
    const Queries = {
      category,
      status,
      queryObject: allQueries?.queryObject,
      yardQueryObject: yardQuery,
    };
    setAllQueries(prev => {
      if (prev?.category !== category) {
        return { ...prev, ...initQueries };
      }
      return { ...prev, ...Queries };
    });
  }, [woStatus, category]);

  useEffect(() => {
    setAllQueries(prev => ({
      ...prev,
      division,
      queryObject: generateInitAdvancedForm({ woStatus, category }),
      yardQueryObject: yardQuery,
    }));
  }, [division]);

  useEffect(() => {
    setAllQueries(prev => ({ ...prev, queryObject }));
  }, [queryObject]);

  useEffect(() => {
    setAllQueries(prev => ({ ...prev, yardQueryObject: yardQuery }));
    if (Object.keys(yardQuery).length === 0) return;
    if (dispatchFilter?.[userID]) return;
    localStorage.setItem(
      'dispatchFilter',
      JSON.stringify({
        ...dispatchFilter,
        queryObject: allQueries?.queryObject,
        [userID]: {
          imp_ava: dispatchFilter?.[userID]?.imp_ava || yardQuery,
          imp_rtn: dispatchFilter?.[userID]?.imp_rtn || yardQuery,
          exp_unava: dispatchFilter?.[userID]?.exp_una || yardQuery,
          exp_ava: dispatchFilter?.[userID]?.exp_ava || yardQuery,
          exp_rtn: dispatchFilter?.[userID]?.exp_rtn || yardQuery,
        },
      }),
    );
  }, [yardQuery]);

  /* Control subFilter or reset path */
  useEffect(() => {
    if (isPathValid({ path, category })) {
      setSubFilter(
        path.split('/')[4] ||
          (category === DISPATCH_TYPE_BOOKING ? ACTIVE_TAB : TOTAL_TAB),
      );
    } else {
      navigate(getFilterButtonData({ category })?.to || '/dispatch');
    }
  }, [path]);

  /* Control Local Storage */
  useEffect(() => {
    localStorage.setItem(
      'dispatchFilter',
      JSON.stringify({
        ...dispatchFilter,
        queryObject: allQueries?.queryObject,
      }),
    );
  }, [allQueries?.queryObject]);

  return {
    allQueries,
    setAllQueries,
    queryObject,
    setQueryObject,
    subFilter,
    setSubFilter,
    yardQuery,
    setYardQuery,
  };
};

export default useControlQueries;
