import React from 'react';
import CommonButton from '../../CommonButton/CommonButton';
import exportToExcel from '../../../utils/Common/Datagrid/Handler/exportToExcel';
import CommonFilter from '../../Common/CommonFilter';
import saveFilter from '../../../utils/Common/CommonFilter/saveFilter';
import RefreshButton from '../../RefreshButton';
import dailyReportKeys from '../../../hooks/DailyReport/keys';

const ReturnYardHeader = ({ gridRef, yardList, divList, ...props }) => {
  const HEADER_NAME = 'RETURN YARD';

  const { filters, filterOpen, setFilterOpen } = props;
  // yardList, divList

  return (
    <div className="flex flex-row justify-between pr-[40px]">
      <div className="whitespace-nowrap font-[800] text-[25px] pl-[5px] py-[24px]">
        {HEADER_NAME}
      </div>
      <div className="flex flex-row place-items-end pb-[24px]">
        <RefreshButton queryKeyList={[dailyReportKeys.returnYardAll]} />
        <div className="flex h-[14px] border-l-[0.4px] border-[#00000099] self-end mb-[4px]" />
        <CommonFilter
          open={filterOpen}
          setOpen={setFilterOpen}
          filters={filters}
          onSave={() =>
            saveFilter({
              storageName: 'returnYardFilter',
              filters,
              setOpen: setFilterOpen,
            })
          }
        />
        <CommonButton
          category="excel"
          onClick={() => {
            exportToExcel({ gridRef, fileName: HEADER_NAME });
          }}
        >
          EXCEL
        </CommonButton>
      </div>
    </div>
  );
};

export default ReturnYardHeader;
