import dayjs from 'dayjs';
import React from 'react';
import openDailyReport from '../../../../utils/TmsReport/Common/openDailyReport';
import StyledWeeklyTableHeaderWrapper from '../../../../styles/TmsReport/Schedules/StyledWeeklyTableHeaderWrapper';
import StyledWeeklyTableHeader from '../../../../styles/TmsReport/Schedules/StyledWeeklyTableHeader';
import StyledWeeklyTableHeaderText from '../../../../styles/TmsReport/Schedules/StyledWeeklyTableHeaderText';

const WeeklyTableHeader = ({ page, weeklyData, day, filterQuery }) => {
  return (
    <StyledWeeklyTableHeaderWrapper
      onClick={() => {
        openDailyReport({
          page,
          date: dayjs(weeklyData?.[day]?.date).format('YYYY-MM-DD'),
          filterQuery,
        });
      }}
    >
      <StyledWeeklyTableHeader>
        <StyledWeeklyTableHeaderText $fontColor="#001E5F">
          {day}
        </StyledWeeklyTableHeaderText>
        <StyledWeeklyTableHeaderText>
          {`${dayjs(weeklyData?.[day].date).format('MM/DD/YY')}`}
        </StyledWeeklyTableHeaderText>
      </StyledWeeklyTableHeader>
    </StyledWeeklyTableHeaderWrapper>
  );
};

export default WeeklyTableHeader;
