import {
  PATH_BUTTON_ACCEPT_SELECTED_ICON,
  PATH_BUTTON_ACCEPT_UNSELECTED_ICON,
  PATH_BUTTON_ACTIVE_DISABLED_ICON,
  PATH_BUTTON_ACTIVE_SELECTED_ICON,
  PATH_BUTTON_ACTIVE_UNSELECTED_ICON,
  PATH_BUTTON_ADD_SELECTED_ICON,
  PATH_BUTTON_ADD_UNSELECTED_ICON,
  PATH_BUTTON_APPROVAL_SELECTED_ICON,
  PATH_BUTTON_APPROVAL_UNSELECTED_ICON,
  PATH_BUTTON_AREA_SCAN_ICON,
  PATH_BUTTON_ASSIGN_DISABLED_ICON,
  PATH_BUTTON_ASSIGN_SELECTED_ICON,
  PATH_BUTTON_ASSIGN_UNSELECTED_ICON,
  PATH_BUTTON_ATTACHED_DISABLED_ICON,
  PATH_BUTTON_ATTACHED_SELECTED_ICON,
  PATH_BUTTON_ATTACHED_UNSELECTED_ICON,
  PATH_BUTTON_BLUE_TRACKING_UNSELECTED_ICON,
  PATH_BUTTON_CANCEL_DISABLED_ICON,
  PATH_BUTTON_CANCEL_SELECTED_ICON,
  PATH_BUTTON_CANCEL_UNSELECTED_ICON,
  PATH_BUTTON_CATEGORY_SELECTED_ICON,
  PATH_BUTTON_CATEGORY_UNSELECTED_ICON,
  PATH_BUTTON_CLEAR_BLUE_SELECTED_ICON,
  PATH_BUTTON_CLEAR_BLUE_UNSELECTED_ICON,
  PATH_BUTTON_CLEAR_SELECTED_ICON,
  PATH_BUTTON_CLEAR_UNSELECTED_ICON,
  PATH_BUTTON_COLOR_SELECTED_ICON,
  PATH_BUTTON_COLOR_UNSELECTED_ICON,
  PATH_BUTTON_COMPLETE_SELECTED_ICON,
  PATH_BUTTON_COMPLETE_UNSELECTED_ICON,
  PATH_BUTTON_CREATE_BILL_NO_DISABLED_ICON,
  PATH_BUTTON_CREATE_BILL_NO_SELECTED_ICON,
  PATH_BUTTON_CREATE_BILL_NO_UNSELECTED_ICON,
  PATH_BUTTON_CREATE_INV_NO_DISABLED_ICON,
  PATH_BUTTON_CREATE_INV_NO_SELECTED_ICON,
  PATH_BUTTON_CREATE_INV_NO_UNSELECTED_ICON,
  PATH_BUTTON_CUSTOMIZE_COLUMN_DISABLED_ICON,
  PATH_BUTTON_CUSTOMIZE_COLUMN_SELECTED_ICON,
  PATH_BUTTON_CUSTOMIZE_COLUMN_UNSELECTED_ICON,
  PATH_BUTTON_DELETE_SELECTED_ICON,
  PATH_BUTTON_DELETE_UNSELECTED_ICON,
  PATH_BUTTON_EDIT_SELECTED_ICON,
  PATH_BUTTON_EDIT_UNSELECTED_ICON,
  PATH_BUTTON_EMAIL_SELECTED_ICON,
  PATH_BUTTON_EMAIL_UNSELECTED_ICON,
  PATH_BUTTON_EXCEL_SELECTED_ICON,
  PATH_BUTTON_EXCEL_UNSELECTED_ICON,
  PATH_BUTTON_EXPORT_SELECTED_ICON,
  PATH_BUTTON_EXPORT_UNSELECTED_ICON,
  PATH_BUTTON_FILTER_SCHEDULE_TYPE_UNSELECTED_ICON,
  PATH_BUTTON_FILTER_SELECTED_ICON,
  PATH_BUTTON_FILTER_UNSELECTED_ICON,
  PATH_BUTTON_GOTIT_SELECTED_ICON,
  PATH_BUTTON_GOTIT_UNSELECTED_ICON,
  PATH_BUTTON_GREY_DELETED_DISABLED_ICON,
  PATH_BUTTON_GREY_DELETED_SELECTED_ICON,
  PATH_BUTTON_GREY_DELETED_UNSELECTED_ICON,
  PATH_BUTTON_GREY_SAVED_LIST,
  PATH_BUTTON_GREY_SEARCH_SELECTED_ICON,
  PATH_BUTTON_GREY_SEARCH_UNSELECTED_ICON,
  PATH_BUTTON_IMPORT_SELECTED_ICON,
  PATH_BUTTON_IMPORT_UNSELECTED_ICON,
  PATH_BUTTON_INACTIVE_DISABLED_ICON,
  PATH_BUTTON_INACTIVE_SELECTED_ICON,
  PATH_BUTTON_INACTIVE_UNSELECTED_ICON,
  PATH_BUTTON_INCOMPLETE_SELECTED_ICON,
  PATH_BUTTON_INCOMPLETE_UNSELECTED_ICON,
  PATH_BUTTON_INVOICE_DISABLED_ICON,
  PATH_BUTTON_INVOICE_SELECTED_ICON,
  PATH_BUTTON_INVOICE_UNSELECTED_ICON,
  PATH_BUTTON_LEAVE_SELECTED_ICON,
  PATH_BUTTON_LEAVE_UNSELECTED_ICON,
  PATH_BUTTON_LIVE_SHARE_SELECTED_ICON,
  PATH_BUTTON_LIVE_SHARE_UNSELECTED_ICON,
  PATH_BUTTON_LOCK_PAY_UNSELECTED_ICON,
  PATH_BUTTON_NO_DISABLED_ICON,
  PATH_BUTTON_NO_SELECTED_ICON,
  PATH_BUTTON_NO_UNSELECTED_ICON,
  PATH_BUTTON_OVERWRITE_UNSELECTED_ICON,
  PATH_BUTTON_PDF_SELECTED_ICON,
  PATH_BUTTON_PDF_UNSELECTED_ICON,
  PATH_BUTTON_QUICKBOOKS_DISABLED_ICON,
  PATH_BUTTON_QUICKBOOKS_SELECTED_ICON,
  PATH_BUTTON_QUICKBOOKS_UNSELECTED_ICON,
  PATH_BUTTON_RED_DELETE_DISABLED_ICON,
  PATH_BUTTON_RED_DELETE_SELECTED_ICON,
  PATH_BUTTON_RED_DELETE_UNSELECTED_ICON,
  PATH_BUTTON_REFRESH_CIRCLE_SELECTED_ICON,
  PATH_BUTTON_REFRESH_CIRCLE_UNSELECTED_ICON,
  PATH_BUTTON_SAVE_SELECTED_ICON,
  PATH_BUTTON_SAVE_UNSELECTED_ICON,
  PATH_BUTTON_SCHEDULE_TYPE_SELECTED_ICON,
  PATH_BUTTON_SCHEDULE_TYPE_UNSELECTED_ICON,
  PATH_BUTTON_SEARCH_DISABLED_ICON,
  PATH_BUTTON_SEARCH_SELECTED_ICON,
  PATH_BUTTON_SEARCH_UNSELECTED_ICON,
  PATH_BUTTON_SEND_DISABLED_ICON,
  PATH_BUTTON_SEND_SELECTED_ICON,
  PATH_BUTTON_SEND_UNSELECTED_ICON,
  PATH_BUTTON_SKIP_UNSELECTED_ICON,
  PATH_BUTTON_SWITCH_TO_DROP_SELECTED_ICON,
  PATH_BUTTON_SWITCH_TO_DROP_UNSELECTED_ICON,
  PATH_BUTTON_SWITCH_TO_LIVE_SELECTED_ICON,
  PATH_BUTTON_SWITCH_TO_LIVE_UNSELECTED_ICON,
  PATH_BUTTON_SYNC_DISABLED_ICON,
  PATH_BUTTON_SYNC_SELECTED_ICON,
  PATH_BUTTON_SYNC_UNSELECTED_ICON,
  PATH_BUTTON_TRACKING_SELECTED_ICON,
  PATH_BUTTON_TRACKING_UNSELECTED_ICON,
  PATH_BUTTON_UPLOAD_SELECTED_ICON,
  PATH_BUTTON_UPLOAD_UNSELECTED_ICON,
  PATH_BUTTON_YARD_BLUE_UNSELECTED_ICON,
  PATH_BUTTON_YARD_SELECTED_ICON,
  PATH_BUTTON_YARD_UNSELECTED_ICON,
  PATH_BUTTON_YES_SELECTED_ICON,
  PATH_BUTTON_YES_UNSELECTED_ICON,
} from './path';

const BUTTON_ICON_PAIR = [
  {
    category: 'excel',
    iconPath: {
      unselected: PATH_BUTTON_EXCEL_UNSELECTED_ICON,
      selected: PATH_BUTTON_EXCEL_SELECTED_ICON,
      disabled: PATH_BUTTON_EXCEL_SELECTED_ICON,
    },
  },
  {
    category: 'pdf',
    iconPath: {
      unselected: PATH_BUTTON_PDF_UNSELECTED_ICON,
      selected: PATH_BUTTON_PDF_SELECTED_ICON,
      disabled: PATH_BUTTON_PDF_SELECTED_ICON,
    },
  },
  {
    category: 'export',
    iconPath: {
      unselected: PATH_BUTTON_EXPORT_UNSELECTED_ICON,
      selected: PATH_BUTTON_EXPORT_SELECTED_ICON,
      disabled: PATH_BUTTON_EXPORT_SELECTED_ICON,
    },
  },
  {
    category: 'import',
    iconPath: {
      unselected: PATH_BUTTON_IMPORT_UNSELECTED_ICON,
      selected: PATH_BUTTON_IMPORT_SELECTED_ICON,
      disabled: PATH_BUTTON_IMPORT_SELECTED_ICON,
    },
  },
  {
    category: 'upload',
    iconPath: {
      unselected: PATH_BUTTON_UPLOAD_UNSELECTED_ICON,
      selected: PATH_BUTTON_UPLOAD_SELECTED_ICON,
      disabled: PATH_BUTTON_UPLOAD_SELECTED_ICON,
    },
  },
  {
    category: 'sync',
    iconPath: {
      unselected: PATH_BUTTON_SYNC_UNSELECTED_ICON,
      selected: PATH_BUTTON_SYNC_SELECTED_ICON,
      disabled: PATH_BUTTON_SYNC_DISABLED_ICON,
    },
  },
  {
    category: 'delete',
    iconPath: {
      unselected: PATH_BUTTON_DELETE_UNSELECTED_ICON,
      selected: PATH_BUTTON_DELETE_SELECTED_ICON,
      disabled: PATH_BUTTON_DELETE_SELECTED_ICON,
    },
  },
  {
    category: 'approval',
    iconPath: {
      unselected: PATH_BUTTON_APPROVAL_UNSELECTED_ICON,
      selected: PATH_BUTTON_APPROVAL_SELECTED_ICON,
      disabled: PATH_BUTTON_APPROVAL_SELECTED_ICON,
    },
  },
  {
    category: 'complete',
    iconPath: {
      unselected: PATH_BUTTON_COMPLETE_UNSELECTED_ICON,
      selected: PATH_BUTTON_COMPLETE_SELECTED_ICON,
      disabled: PATH_BUTTON_COMPLETE_SELECTED_ICON,
    },
  },
  {
    category: 'incomplete',
    iconPath: {
      unselected: PATH_BUTTON_INCOMPLETE_UNSELECTED_ICON,
      selected: PATH_BUTTON_INCOMPLETE_SELECTED_ICON,
      disabled: PATH_BUTTON_INCOMPLETE_SELECTED_ICON,
    },
  },
  {
    category: 'edit',
    iconPath: {
      unselected: PATH_BUTTON_EDIT_UNSELECTED_ICON,
      selected: PATH_BUTTON_EDIT_SELECTED_ICON,
      disabled: PATH_BUTTON_EDIT_SELECTED_ICON,
    },
  },
  {
    category: 'clear',
    iconPath: {
      unselected: PATH_BUTTON_CLEAR_UNSELECTED_ICON,
      selected: PATH_BUTTON_CLEAR_SELECTED_ICON,
      disabled: PATH_BUTTON_CLEAR_SELECTED_ICON,
    },
  },
  {
    category: 'clearBlue',
    iconPath: {
      unselected: PATH_BUTTON_CLEAR_BLUE_UNSELECTED_ICON,
      selected: PATH_BUTTON_CLEAR_BLUE_SELECTED_ICON,
      disabled: PATH_BUTTON_CLEAR_BLUE_SELECTED_ICON,
    },
  },
  {
    category: 'leave',
    iconPath: {
      unselected: PATH_BUTTON_LEAVE_UNSELECTED_ICON,
      selected: PATH_BUTTON_LEAVE_SELECTED_ICON,
      disabled: PATH_BUTTON_LEAVE_SELECTED_ICON,
    },
  },
  {
    category: 'cancel',
    iconPath: {
      unselected: PATH_BUTTON_CANCEL_UNSELECTED_ICON,
      selected: PATH_BUTTON_CANCEL_SELECTED_ICON,
      disabled: PATH_BUTTON_CANCEL_DISABLED_ICON,
    },
  },
  {
    category: 'yes',
    iconPath: {
      unselected: PATH_BUTTON_YES_UNSELECTED_ICON,
      selected: PATH_BUTTON_YES_SELECTED_ICON,
      disabled: PATH_BUTTON_YES_SELECTED_ICON,
    },
  },
  {
    category: 'no',
    iconPath: {
      unselected: PATH_BUTTON_NO_UNSELECTED_ICON,
      selected: PATH_BUTTON_NO_SELECTED_ICON,
      disabled: PATH_BUTTON_NO_DISABLED_ICON,
    },
  },
  {
    category: 'gotIt',
    iconPath: {
      unselected: PATH_BUTTON_GOTIT_UNSELECTED_ICON,
      selected: PATH_BUTTON_GOTIT_SELECTED_ICON,
      disabled: PATH_BUTTON_GOTIT_SELECTED_ICON,
    },
  },
  {
    category: 'switchToDROP',
    iconPath: {
      unselected: PATH_BUTTON_SWITCH_TO_DROP_UNSELECTED_ICON,
      selected: PATH_BUTTON_SWITCH_TO_DROP_SELECTED_ICON,
      disabled: PATH_BUTTON_SWITCH_TO_DROP_SELECTED_ICON,
    },
  },
  {
    category: 'switchToLIVE',
    iconPath: {
      unselected: PATH_BUTTON_SWITCH_TO_LIVE_UNSELECTED_ICON,
      selected: PATH_BUTTON_SWITCH_TO_LIVE_SELECTED_ICON,
      disabled: PATH_BUTTON_SWITCH_TO_LIVE_SELECTED_ICON,
    },
  },
  {
    category: 'tracking',
    iconPath: {
      unselected: PATH_BUTTON_TRACKING_UNSELECTED_ICON,
      selected: PATH_BUTTON_TRACKING_SELECTED_ICON,
      disabled: PATH_BUTTON_TRACKING_SELECTED_ICON,
    },
  },
  {
    category: 'blueTracking',
    iconPath: {
      unselected: PATH_BUTTON_BLUE_TRACKING_UNSELECTED_ICON,
      selected: PATH_BUTTON_TRACKING_SELECTED_ICON,
      disabled: PATH_BUTTON_TRACKING_SELECTED_ICON,
    },
  },
  {
    category: 'add',
    iconPath: {
      unselected: PATH_BUTTON_ADD_UNSELECTED_ICON,
      selected: PATH_BUTTON_ADD_SELECTED_ICON,
      disabled: PATH_BUTTON_ADD_SELECTED_ICON,
    },
  },
  {
    category: 'save',
    iconPath: {
      unselected: PATH_BUTTON_SAVE_UNSELECTED_ICON,
      selected: PATH_BUTTON_SAVE_SELECTED_ICON,
      disabled: PATH_BUTTON_SAVE_SELECTED_ICON,
    },
  },
  {
    category: 'createBillNo',
    iconPath: {
      unselected: PATH_BUTTON_CREATE_BILL_NO_UNSELECTED_ICON,
      selected: PATH_BUTTON_CREATE_BILL_NO_SELECTED_ICON,
      disabled: PATH_BUTTON_CREATE_BILL_NO_DISABLED_ICON,
    },
  },
  {
    category: 'createInvNo',
    iconPath: {
      unselected: PATH_BUTTON_CREATE_INV_NO_UNSELECTED_ICON,
      selected: PATH_BUTTON_CREATE_INV_NO_SELECTED_ICON,
      disabled: PATH_BUTTON_CREATE_INV_NO_DISABLED_ICON,
    },
  },
  {
    category: 'quickBooks',
    iconPath: {
      unselected: PATH_BUTTON_QUICKBOOKS_UNSELECTED_ICON,
      selected: PATH_BUTTON_QUICKBOOKS_SELECTED_ICON,
      disabled: PATH_BUTTON_QUICKBOOKS_DISABLED_ICON,
    },
  },
  {
    category: 'redDelete',
    iconPath: {
      unselected: PATH_BUTTON_RED_DELETE_UNSELECTED_ICON,
      selected: PATH_BUTTON_RED_DELETE_SELECTED_ICON,
      disabled: PATH_BUTTON_RED_DELETE_DISABLED_ICON,
    },
  },
  {
    category: 'search',
    iconPath: {
      unselected: PATH_BUTTON_SEARCH_UNSELECTED_ICON,
      selected: PATH_BUTTON_SEARCH_SELECTED_ICON,
      disabled: PATH_BUTTON_SEARCH_DISABLED_ICON,
    },
  },
  {
    category: 'greySearch',
    iconPath: {
      unselected: PATH_BUTTON_GREY_SEARCH_UNSELECTED_ICON,
      selected: PATH_BUTTON_GREY_SEARCH_SELECTED_ICON,
    },
  },
  {
    category: 'active',
    iconPath: {
      unselected: PATH_BUTTON_ACTIVE_UNSELECTED_ICON,
      selected: PATH_BUTTON_ACTIVE_SELECTED_ICON,
      disabled: PATH_BUTTON_ACTIVE_DISABLED_ICON,
    },
  },
  {
    category: 'inactive',
    iconPath: {
      unselected: PATH_BUTTON_INACTIVE_UNSELECTED_ICON,
      selected: PATH_BUTTON_INACTIVE_SELECTED_ICON,
      disabled: PATH_BUTTON_INACTIVE_DISABLED_ICON,
    },
  },
  {
    category: 'greyDelete',
    iconPath: {
      unselected: PATH_BUTTON_GREY_DELETED_UNSELECTED_ICON,
      selected: PATH_BUTTON_GREY_DELETED_SELECTED_ICON,
      disabled: PATH_BUTTON_GREY_DELETED_DISABLED_ICON,
    },
  },
  {
    category: 'assign',
    iconPath: {
      unselected: PATH_BUTTON_ASSIGN_UNSELECTED_ICON,
      selected: PATH_BUTTON_ASSIGN_SELECTED_ICON,
      disabled: PATH_BUTTON_ASSIGN_SELECTED_ICON,
    },
  },
  {
    category: 'customizeColumn',
    iconPath: {
      unselected: PATH_BUTTON_CUSTOMIZE_COLUMN_UNSELECTED_ICON,
      selected: PATH_BUTTON_CUSTOMIZE_COLUMN_SELECTED_ICON,
      disabled: PATH_BUTTON_CUSTOMIZE_COLUMN_DISABLED_ICON,
    },
  },
  {
    category: 'attached',
    iconPath: {
      unselected: PATH_BUTTON_ATTACHED_UNSELECTED_ICON,
      selected: PATH_BUTTON_ATTACHED_SELECTED_ICON,
      disabled: PATH_BUTTON_ATTACHED_DISABLED_ICON,
    },
  },
  {
    category: 'send',
    iconPath: {
      unselected: PATH_BUTTON_SEND_UNSELECTED_ICON,
      selected: PATH_BUTTON_SEND_SELECTED_ICON,
      disabled: PATH_BUTTON_SEND_DISABLED_ICON,
    },
  },
  {
    category: 'assign',
    iconPath: {
      unselected: PATH_BUTTON_ASSIGN_UNSELECTED_ICON,
      selected: PATH_BUTTON_ASSIGN_SELECTED_ICON,
      disabled: PATH_BUTTON_ASSIGN_DISABLED_ICON,
    },
  },
  {
    category: 'skip',
    iconPath: {
      unselected: PATH_BUTTON_SKIP_UNSELECTED_ICON,
    },
  },
  {
    category: 'greySavedList',
    iconPath: {
      unselected: PATH_BUTTON_GREY_SAVED_LIST,
    },
  },
  {
    category: 'email',
    iconPath: {
      unselected: PATH_BUTTON_EMAIL_UNSELECTED_ICON,
      selected: PATH_BUTTON_EMAIL_SELECTED_ICON,
    },
  },
  {
    category: 'invoice',
    iconPath: {
      unselected: PATH_BUTTON_INVOICE_UNSELECTED_ICON,
      selected: PATH_BUTTON_INVOICE_SELECTED_ICON,
      disabled: PATH_BUTTON_INVOICE_DISABLED_ICON,
    },
  },
  {
    category: 'overWrite',
    iconPath: {
      unselected: PATH_BUTTON_OVERWRITE_UNSELECTED_ICON,
      selected: PATH_BUTTON_OVERWRITE_UNSELECTED_ICON,
      disabled: PATH_BUTTON_OVERWRITE_UNSELECTED_ICON,
    },
  },
  {
    category: 'areaScan',
    iconPath: {
      unselected: PATH_BUTTON_AREA_SCAN_ICON,
      selected: PATH_BUTTON_AREA_SCAN_ICON,
      disabled: PATH_BUTTON_AREA_SCAN_ICON,
    },
  },
  {
    category: 'accept',
    iconPath: {
      unselected: PATH_BUTTON_ACCEPT_UNSELECTED_ICON,
      selected: PATH_BUTTON_ACCEPT_SELECTED_ICON,
      disabled: PATH_BUTTON_ACCEPT_SELECTED_ICON,
    },
  },
  {
    category: 'lockPay',
    iconPath: {
      unselected: PATH_BUTTON_LOCK_PAY_UNSELECTED_ICON,
      selected: PATH_BUTTON_LOCK_PAY_UNSELECTED_ICON,
      disabled: PATH_BUTTON_LOCK_PAY_UNSELECTED_ICON,
    },
  },
  {
    category: 'yard',
    iconPath: {
      unselected: PATH_BUTTON_YARD_UNSELECTED_ICON,
      selected: PATH_BUTTON_YARD_SELECTED_ICON,
      disabled: PATH_BUTTON_YARD_SELECTED_ICON,
    },
  },
  {
    category: 'blueYard',
    iconPath: {
      unselected: PATH_BUTTON_YARD_BLUE_UNSELECTED_ICON,
      selected: PATH_BUTTON_YARD_SELECTED_ICON,
      disabled: PATH_BUTTON_YARD_SELECTED_ICON,
    },
  },
  {
    category: 'category',
    iconPath: {
      unselected: PATH_BUTTON_CATEGORY_UNSELECTED_ICON,
      selected: PATH_BUTTON_CATEGORY_SELECTED_ICON,
      disabled: PATH_BUTTON_CATEGORY_SELECTED_ICON,
    },
  },
  {
    category: 'scheduleType',
    iconPath: {
      unselected: PATH_BUTTON_SCHEDULE_TYPE_UNSELECTED_ICON,
      selected: PATH_BUTTON_SCHEDULE_TYPE_SELECTED_ICON,
      disabled: PATH_BUTTON_SCHEDULE_TYPE_SELECTED_ICON,
    },
  },
  {
    category: 'status',
    iconPath: {
      unselected: PATH_BUTTON_COLOR_UNSELECTED_ICON,
      selected: PATH_BUTTON_COLOR_SELECTED_ICON,
      disabled: PATH_BUTTON_COLOR_SELECTED_ICON,
    },
  },
  {
    category: 'liveShare',
    iconPath: {
      unselected: PATH_BUTTON_LIVE_SHARE_UNSELECTED_ICON,
      selected: PATH_BUTTON_LIVE_SHARE_SELECTED_ICON,
      disabled: PATH_BUTTON_LIVE_SHARE_SELECTED_ICON,
    },
  },
  {
    category: 'filter',
    iconPath: {
      unselected: PATH_BUTTON_FILTER_UNSELECTED_ICON,
      selected: PATH_BUTTON_FILTER_SELECTED_ICON,
      disabled: PATH_BUTTON_FILTER_SELECTED_ICON,
    },
  },
  {
    category: 'filterScheduleType',
    iconPath: {
      unselected: PATH_BUTTON_FILTER_SCHEDULE_TYPE_UNSELECTED_ICON,
      selected: PATH_BUTTON_FILTER_SCHEDULE_TYPE_UNSELECTED_ICON,
      disabled: PATH_BUTTON_FILTER_SCHEDULE_TYPE_UNSELECTED_ICON,
    },
  },
  {
    category: 'refreshCircle',
    iconPath: {
      unselected: PATH_BUTTON_REFRESH_CIRCLE_UNSELECTED_ICON,
      selected: PATH_BUTTON_REFRESH_CIRCLE_SELECTED_ICON,
      disabled: PATH_BUTTON_REFRESH_CIRCLE_SELECTED_ICON,
    },
  },
];

export default BUTTON_ICON_PAIR;
