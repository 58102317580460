import styled from 'styled-components';

const StyledBCDetailCommonTopBarFieldItemValueSpan = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: ${props => props?.$styles?.lineClampNum || 1};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default StyledBCDetailCommonTopBarFieldItemValueSpan;
